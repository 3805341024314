import React from 'react';
import { Config } from '../../config';
import { getFirstAndLast } from '../../helper';
import { useSelector } from 'react-redux';
import Loader from '../Loader';

export const WalletActivity = (props) => {
    const { transactions, state } = props;
    const auth = useSelector((x) => x.auth);

    const senderHtml = (transaction)=>{
        return (
            <a href={`${Config.url}txid/${transaction.txId}?chain=${Config.name}` } target='_blank' className='item items-start sent'>
                    <div className='left'>
                        <div className='icon w-10 h-10'>
                            <img src='/assets/icons/icon-sent-arrow.svg' alt="" />
                        </div>
                        <div>
                            <p className='text-upr'>You sent {transaction.amount} BTC</p>
                            <p className='text-lwr'>to { transaction.sender_address.substr(0, 5) }...{ transaction.sender_address.substr(transaction.sender_address.length - 5) }</p>
                        </div>
                    </div>
                    <div className='right'>
                        <p className='text-lwr'>{ (transaction.feeRate * 0.68).toFixed(6) }</p>
                    </div>
                </a >
        )
    }
    const recieverHtml = (transaction, index) => {
        return (
            <a key={index} href={`${Config.url}txid/${transaction.txId}?chain=${Config.name}` } target='_blank' className='item items-start recieved'>
                <div className='left'>
                    <div className='icon w-10 h-10'>
                        { transaction.sender_address === auth.authData.stxAddress.address ? (
                            <img src='/assets/icons/icon-sent-arrow.svg' alt="" />
                        ) : (
                            <img src='/assets/icons/icon-recieved-arrow.svg' alt="" />
                        )}
                    </div>
                    <div>
                        <p className='text-upr'>{getFirstAndLast(transaction.txId)}</p>
                        <p className='text-lwr'>to { getFirstAndLast(transaction?.sender_address) }</p>
                        <p className='text-lwr' style={{ textTransform: 'capitalize', marginTop: 4, fontSize: 12 }}>{ (transaction.status || '').replace(/[_]/g, ' ') }</p>
                    </div>
                </div>
                <div className='right'>
                    <p className='text-lwr'>{ transaction.feeRate }</p>
                </div>
            </a >
        )
    }
    return (
        <div className='sidebar-tab-content activity-content' style={{ maxHeight: state.isMobile ? 350 : 'auto' }}>
            { state.transactionLoader && (
                <div className="loader-inline ml-3 flex mt-10 justify-center items-center text-center">
                     <Loader width={45} />
                </div>
            ) }
            {(!state.transactionLoader && (transactions || [])?.length) !== 0 ? <>
                {transactions.map((value, index) => {
                    return (recieverHtml(value, index))
                })}
            </> : ''}
        </div>
    )
}