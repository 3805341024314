import { useState } from "react";
import LiquiditySlider from "./LiquiditySlider";
import ClaimReward from "../ClaimReward";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const MyLiquidity = (props) => {
    const auth = useSelector((x) => x.auth);
    const { settings, setSettings } = props;
    const [state, setState] = useState({ removeLiquidity: true });

    return (
        <div className="my-liquidity">
            <div className="swap-container relative">
                <div className="swapable mb-2.5 md:mb-4">
                    <div className="flex items-center justify-between">
                        <div>
                            <h4 className="amount">$243.89</h4>
                            <p className="balance">44789000 RBTC</p>
                        </div>
                        <div>
                            <div className="selected-coin">
                                <img className="icon" src="/assets/icons/icon-bitcoin-yellow.svg" alt="" />
                                <h4 className="currency">BTC</h4>
                            </div>
                            <p className="fee">50%</p>
                        </div>
                    </div>
                </div>
                <div className="swapable">
                    <div className="flex items-center justify-between">
                        <div>
                            <h4 className="amount">11.235</h4>
                            <p className="balance">$2443.23</p>
                        </div>
                        <div>
                            <div className="selected-coin">
                                <img className="icon" src="/assets/icons/icon-bitcoin-yellow.svg" alt="" />
                                <h4 className="currency">AMPL</h4>
                            </div>
                            <p className="fee">50%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="collapsible-pool-details mt-4 md:mt-6" data-accordion="collapse">
                <button onClick={() => setState({ removeLiquidity: !state.removeLiquidity })} className="collapse-header"
                    type="button" data-accordion-target="#collapsible-details" ariaaria-expanded="true" 
                    aria-controls="collapsible-details"
                >
                    <span>Remove Liquidity</span>
                    <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.removeLiquidity && 'rotate-180')}
                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>
                <div id="collapsible-details" className={state.removeLiquidity && 'hidden'}>
                    <LiquiditySlider />
                    <div className="will-remove-card">
                        <h6 className="header">You will remove</h6>
                        <div className="flex items-center justify-between px-3 py-2.5">
                            <p className="key text-white">BTC</p> 
                            <p className="value text-dark">14.372 Velar</p>
                        </div>
                        <hr />
                        <div className="flex items-center justify-between px-3 py-2.5">
                            <p className="key text-white">AMPL</p> 
                            <p className="value text-dark">14.372 Velar</p>
                        </div>
                    </div>
                    <button className="btn-danger h-11 text-sm mt-4 md:mt-6">Remove Liquidity</button>
                </div>
            </div>
            <ClaimReward />
        </div>
    )
}
