import { Chart as ChartJs, CategoryScale, BarElement, Tooltip, LinearScale, PointElement, LineElement } from 'chart.js';
import React from "react";
import { Line, Bar, Chart } from "react-chartjs-2";
ChartJs.register(CategoryScale, LinearScale, BarElement,  Tooltip, PointElement, LineElement);


export const VBarChart = (props) => {
  const labels = props.labels || [];
  const records = props.records || [];
  const dollar = props.dollar === true  ? '$' : '';

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        callbacks: {
          usePointStyle: true,
          beforeBody: function (context) {
            return dollar + context[0].raw;
          },
          label : function (c) {
            return '';
          },
          labelColor: function(context) {
              return {
                  borderColor: 'rgb(0, 0, 255)',
                  backgroundColor: '#aaa',
                  borderWidth: 0,
                  borderDash: [2, 2],
                  borderRadius: 0,
              };
          },
          labelTextColor: function(context) {
              return '#aaa';
          }
        }
      }
    },
    elements: {
      line: {
        fill: false
      }
    },
    scales: {
      x: {
        border: {
          display: false
        },
        ticks: {
          display: false
        },
      },
      y: {
          display: false,
          gridLines: {
              display: false
          },
          ticks: {
              display: true
          },
      }
    }
  };

  const data = {
    labels: labels,
    datasets: [
      {
        lineTension: 0,
        borderColor: "#F8931F",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#F8931F",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        borderWidth: 2,
        pointHoverBackgroundColor: "rgba(131,138,133,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        backgroundColor: ['#f89d33'],
        data: records
      }
    ]
  };

  return (
    <div className='h-[350px]'>
        <Chart type='barwithline' data={data} options={options} />
    </div>
    );
}