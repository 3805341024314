import React from 'react'
import { Link } from 'react-router-dom'

export default function MyFarmGridView(props) {

    const { liquidities } = props;

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
            {liquidities.map((item, index) => (
                <Link key={index} to={`/farm/` + item.symbol} className="pool-grid-box">
                    <div className="conversion">
                        <div className="icons">
                            <img
                                className="z-6"
                                src={item.token0ImageUrl}
                                alt="coin two" />
                            <img
                                className="z-7"
                                src={item.token1ImageUrl}
                                alt="coin one" />
                        </div>
                        <div className="coins">{ item.name }</div>
                        {/* <div className="fee">0.3%</div> */}
                    </div>
                    <div className="details">
                        <div className="item">
                            <p className="header-text">User Staked</p>
                            <p className="body-text">{ item.farm.user }</p>
                        </div>
                        <div className="item">
                            <p className="header-text">Rewards</p>
                            <p className="body-text">-</p>
                        </div>
                        <div className="item">
                            <p className="header-text">APR</p>
                            <div className='flex items-center'>
                                --
                                {/* <img className='mr-1' src="/assets/icons/fire.svg" alt="fire" />
                                <p className="body-text">10.95%</p> */}
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}
