class StorageService {
    constructor () {
        this.storage = localStorage;
    }

    setItem (key, value, stringfy = true) {
        if (stringfy) {
            this.storage.setItem(key, JSON.stringify(value))
        } else {
            this.storage.setItem(key, value);
        }
    }

    getItem (key, parse = true) {
        try {
            if (parse) {
                return JSON.parse(this.storage.getItem(key));
            } else {
                return this.storage.getItem(key);
            }
        } catch (error) {
            return false;
        }
    }

    removeItem(key) {
       return this.storage.removeItem(key);
    }
}

export default new StorageService()