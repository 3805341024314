import { Link, useParams } from "react-router-dom"
import AddLiquidity from "../Liquidity/AddLiquidity"
import { MyLiquidity } from "../Liquidity/MyLiquidity"
import { useEffect, useRef, useState } from "react";
import StakeView from "./StakeView";
import MyInvestmentView from "./MyInvestmentView";
import FarmProcess from "../FarmProcess";
import Breadcrumbs from "../Breadcrumbs";
import PoolService from "../../services/PoolService";
import CoreContractService from "../../services/CoreContractService";
import { useDispatch, useSelector } from "react-redux";
import PoolContractService from "../../services/PoolContractService";
import { getAccountTokens, setTokenLoader } from "../../store/slices/authSlice";
import Loader from "../Loader";
import FarmChart from "./FarmChart";
import FarmSummary from "./FarmSummary";
import { FarmCoreContractService as FarmCoreContract } from '../../services/FarmCoreContractService';
import { Config } from "../../config";
import { getPureAddress } from "../../helper";

export const FarmStake = (props) => {
    const [ settings, setSettings ] = useState({ pool: {}, poolInfo: { name: '' } })
    const [ epoch, setEpoch ] = useState({ loading: false, epoch: '', startEnd: [0, 0] });
    const params = useParams();
    const dispatch = useDispatch();

    const getCurrentEpoch = async () => {
        if (settings.poolInfo?.contracts?.farm['farming-core']) {
            setEpoch({ ...epoch, loading: false });
    
            const FarmCoreContractService = new FarmCoreContract(settings.poolInfo.contracts.farm['farming-core']);
            const currentEpoch = await FarmCoreContractService.getCurrentEpoch();
            const startEnd = await Promise.all([
                FarmCoreContractService.getCalcEpochStart(currentEpoch),
                FarmCoreContractService.getCalcEpochEnd(currentEpoch),
            ])
            setEpoch({ loading: false, epoch: currentEpoch, startEnd: startEnd });
        }
    }

    useEffect(() => {
        getCurrentEpoch();
    }, [settings.poolInfo?.name])

    useEffect(() => {
        init ();
    }, [])
    
    const init = async () => {
        dispatch(setTokenLoader(true));
        dispatch(getAccountTokens());
        const poolInfo = await PoolService.getPoolBySymbol(params.id);
        const poolContractData = await CoreContractService.getPool();
        const poolData = poolContractData?.value?.data || {};
        settings.pool = { ...poolData };
        settings.poolInfo = poolInfo.message;
        setSettings({ ...settings });
    }

    if (!settings.poolInfo.id) {
        return (
            <div className="farm-details mt-15">
                <Loader />
            </div>
        )
    }

    return (
        <div className="farm-details single-farm">
                <div className="pool-column flex justify-between gap-x-0 md:gap-x-6 gap-y-6 md:gap-y-0">
                <div className="farm-info farm-header">
                    <Link to={'/farm'} className={(true ? 'hidden lg:flex ' : '') + 'color-velar gap-2 flex items-center text-sm mb-5 hand'}>
                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2496 5.50068C13.2496 5.69959 13.1706 5.89035 13.0299 6.03101C12.8893 6.17166 12.6985 6.25068 12.4996 6.25068H3.31211L6.53211 9.47005C6.67301 9.61095 6.75217 9.80204 6.75217 10.0013C6.75217 10.2006 6.67301 10.3917 6.53211 10.5326C6.39122 10.6734 6.20012 10.7526 6.00086 10.7526C5.80161 10.7526 5.61051 10.6734 5.46961 10.5326L0.969615 6.03255C0.899695 5.96287 0.844217 5.88008 0.806363 5.78891C0.768509 5.69775 0.749023 5.60001 0.749023 5.5013C0.749023 5.40259 0.768509 5.30485 0.806363 5.21369C0.844217 5.12252 0.899695 5.03973 0.969615 4.97005L5.46961 0.470051C5.53938 0.400286 5.6222 0.344946 5.71335 0.307189C5.80451 0.269433 5.9022 0.25 6.00086 0.25C6.09953 0.25 6.19722 0.269433 6.28838 0.307189C6.37953 0.344946 6.46235 0.400286 6.53211 0.470051C6.60188 0.539816 6.65722 0.622638 6.69498 0.71379C6.73273 0.804942 6.75217 0.902639 6.75217 1.0013C6.75217 1.09996 6.73273 1.19766 6.69498 1.28881C6.65722 1.37996 6.60188 1.46279 6.53211 1.53255L3.31211 4.75068H12.4996C12.6985 4.75068 12.8893 4.82969 13.0299 4.97035C13.1706 5.111 13.2496 5.30176 13.2496 5.50068Z" fill="#F5F7FA" fillOpacity="0.36"/>
                        </svg>
                        <span>
                        Back to all Farms
                        </span>
                    </Link>
                    {/* <Breadcrumbs crumbs={[{ name: 'Farm', link: '/farm' }, { name: settings.poolInfo.lpTokenSymbol, link: '/farm-stake' }]} /> */}
                    {settings.poolInfo.id && (
                        <div className="farm-top-header inline-flex items-center mb-9 md:mb-6">
                            <div className="icons">
                                <img
                                    className="z-6"
                                    src={settings.poolInfo.token0ImageUrl}
                                    alt="coin two" />
                                <img
                                    style={{ borderRadius: '100%', border: '1px solid #222' }}
                                    className="z-7"
                                    src={settings.poolInfo.token1ImageUrl}
                                    alt="coin one" />
                            </div>
                            <div className="relative">
                                <div className="coins">
                                    { settings.poolInfo?.name }
                                    { settings?.poolInfo?.lpTokenContractAddress && (
                                    <a href={`https://explorer.hiro.so/address/${getPureAddress(settings?.poolInfo?.lpTokenContractAddress)}.${settings?.poolInfo?.contracts?.farm?.['farming-core']}?chain=${Config.name}`} target='_blank' className="ml-1">
                                        <img className='w-5' src="/assets/icons/link.svg" alt="link" />
                                    </a>
                                    )}
                                </div>
                            </div>
                            <div className="action-btns ml-3">
                                {/* <Link to="#" className="header-btn">
                                    Rewards: <span> 10.26% </span>
                                </Link> */}
                                <Link to="#" className="header-btn">
                                    Fees: <span> 0.3% </span>
                                </Link>
                                <Link to="#" className="header-btn">
                                    APR: <span> 3% </span>
                                </Link>
                            </div>
                        </div>
                    )}

                    { settings.poolInfo.id && (
                        <>
                            <FarmChart settings={settings} />
                            {/* <hr className="my-6 md:my-2" /> */}
                            <div className='flex justify-between items-center mb-2 mt-2'>
                                <h3 className='text-white text-lg'>Farm details</h3>
                                {/* <h5 className='text-white text-sm'>Show Less</h5> */}
                            </div>
                            <FarmSummary epoch={epoch} poolId={settings.poolInfo.id} />
                        </>
                    )}
                </div>
                <div className="farm-proccess-right">
                    {settings.poolInfo.id ? (
                        <FarmProcess epoch={epoch} settings={settings} />
                        ) : (
                        <div className="mt-10">
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

