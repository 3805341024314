import { isMobile } from '@stacks/connect';
import http, { blockWatcher, poolHttp } from '../utils/axiosProvider'
import AuthService from './AuthService';
import CoreContractService from './CoreContractService';
import PoolContractService from './PoolContractService';
import { FarmCoreContractService } from './FarmCoreContractService';
class PoolService {

    async getPools(config = { context: 'global' }) {
        const isFromFarm = config.context === 'farm';
        const pools = (await poolHttp.get('/pool')).data;
        
        const store = window.velarStore;
        const auth = store.getState().auth;
        const response = [];
        const allowedPools = [];
        const poolWithSymbols = { };

        for await (let pool of pools.message) {
            try {
                allowedPools.push([pool.token0Symbol.toLowerCase(), pool.token1Symbol.toLowerCase()]);
                allowedPools.push([pool.token1Symbol.toLowerCase(), pool.token0Symbol.toLowerCase()]);
                poolWithSymbols[[pool.token0Symbol.toLowerCase(), pool.token1Symbol.toLowerCase()].join('-')] = pool.id;
                poolWithSymbols[[pool.token1Symbol.toLowerCase(), pool.token0Symbol.toLowerCase()].join('-')] = pool.id;
               
                let poolData = { my: { balance: 0, amt0: 0, amt1: 0 } };
                let totalSupply = 0;
                let totalFarmStaked = '-';
                let userFarmStaked = '-';
                if (isFromFarm) {
                    console.log(pool.contracts.farm['farming-core'] );
                    userFarmStaked = await (new FarmCoreContractService(pool.contracts.farm['farming-core'])).getUserSahre();
                    totalFarmStaked = await (new FarmCoreContractService(pool.contracts.farm['farming-core'])).getTotalStake();

                    console.log(userFarmStaked);
                }
    
                if (auth.isLoggedIn) {
                    totalSupply = await PoolContractService.getTotalSupply(pool.lpTokenContractAddress);
                    const authTokens = (await AuthService.getAccountInfoByAddress(auth.authData.stxAddress.address)).tokens;
                    const lpTokenValue = authTokens.find((x) => x.contract === pool.lpTokenContractAddress)?.balance || 0;
    
                    let result = await CoreContractService.calcBurn(
                        Number(lpTokenValue),
                        pool.reserve0,
                        pool.reserve1,
                        totalSupply
                    );
                    poolData['my'].balance = Number(lpTokenValue);
                    poolData['my'].amt0 = Number(result.amt0);
                    poolData['my'].amt1 = Number(result.amt1);
                }
          
    
                response.push({
                     ...pool, 
                     farm: { total: totalFarmStaked || 0, user: userFarmStaked || 0 }, 
                     lpToken: true, 
                     name: pool.lpTokenSymbol, 
                     totalSupply: totalSupply || 0, 
                     blockchain: poolData || {} 
                })
            } catch (error) {
                console.log(error);
            }
        }

        return Promise.resolve({ poolWithSymbols, allowedPools, message: response });
    }

    async getPoolsV1(config = { context: 'global' }) {
        const isFromFarm = config.context === 'farm';
        const pools = (await poolHttp.get('/pool')).data;

        // const pools = {
        //     "statusCode": 200,
        //     "message": [
        //       {
        //         "_id": "648e0ad1395c1c9758bb65c6",
        //         "symbol": "wSTX-VELAR",
        //         "token0Symbol": "wSTX",
        //         "token0ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx",
        //         "token0AssetName": "stx",
        //         "token1Symbol": "VELAR",
        //         "token1ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.velar",
        //         "token1AssetName": "velar",
        //         "lpTokenContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx-velar",
        //         "lpTokenSymbol": "wSTX-VELAR",
        //         "id": "1",
        //         "lpTokenDecimal": "u0",
        //         "token0ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg",
        //         "token1ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/velar.jpg",
        //         "token0Decimail": "u6",
        //         "token1Decimail": "u6",
        //         "token0DecimalNum": 1000000,
        //         "token1DecimalNum": 1000000,
        //         "lpTokenDecimalNum": 1,
        //         "lpTokenAssetName": "lp-token",
        //         "__v": 0,
        //         "createdAt": "2023-06-17T19:34:41.701Z",
        //         "updatedAt": "2023-06-17T19:34:41.701Z"
        //       }
        //     ]
        // };

        const response = [];
        const store = window.velarStore;
        const auth = store.getState().auth;

        const allowedPools = [];
        const poolWithSymbols = { };
        // return Promise.resolve({  message: [] });
        for await (let pool of pools.message) {
            allowedPools.push([pool.token0Symbol.toLowerCase(), pool.token1Symbol.toLowerCase()]);
            allowedPools.push([pool.token1Symbol.toLowerCase(), pool.token0Symbol.toLowerCase()]);
            poolWithSymbols[[pool.token0Symbol.toLowerCase(), pool.token1Symbol.toLowerCase()].join('-')] = pool.id;
            poolWithSymbols[[pool.token1Symbol.toLowerCase(), pool.token0Symbol.toLowerCase()].join('-')] = pool.id;

            try {
                const stats = (await this.getPoolStats(pool.id, 24)).data || { apr: 0, volume: 0 };
                pool.stats = stats;
                const blockPool = await CoreContractService.getPool(pool.id);
                const totalSupply = await PoolContractService.getTotalSupply(pool.lpTokenContractAddress);
                let totalFarmStaked = '-';
                let userFarmStaked = '-';
                if (isFromFarm) {
                    userFarmStaked = await FarmCoreContractService.getUserSahre();
                    totalFarmStaked = await FarmCoreContractService.getTotalStake();
                }
                const poolData = blockPool?.value?.data;
                if (poolData) {
                    poolData['block-height'].value = Number(poolData['block-height'].value);
                    poolData['burn-block-height'].value = Number(poolData['burn-block-height'].value);
                    poolData['protocol-fee'].data.den.value = Number(poolData['protocol-fee'].data.den.value);
                    poolData['protocol-fee'].data.num.value = Number(poolData['protocol-fee'].data.num.value);
    
                    poolData['swap-fee'].data.den.value = Number(poolData['swap-fee'].data.den.value);
                    poolData['swap-fee'].data.num.value = Number(poolData['swap-fee'].data.num.value);
    
                    poolData['share-fee'].data.den.value = Number(poolData['share-fee'].data.den.value);
                    poolData['share-fee'].data.num.value = Number(poolData['share-fee'].data.num.value);
    
                    poolData['reserve0'].value = Number(poolData['reserve0'].value);
                    poolData['reserve1'].value = Number(poolData['reserve1'].value);
    
                    poolData['my'] = {
                        amt0: 0,
                        amt1: 1,
                    }
    
                    if (auth.isLoggedIn) {
                        const authTokens = (await AuthService.getAccountInfoByAddress(auth.authData.stxAddress.address)).tokens;
                        const lpTokenValue = authTokens.find((x) => x.contract === pool.lpTokenContractAddress)?.balance || 0;
    
                        let result = await CoreContractService.calcBurn(
                            Number(lpTokenValue),
                            poolData['reserve0'].value,
                            poolData['reserve1'].value,
                            totalSupply
                        );
                        poolData['my'].balance = Number(lpTokenValue);
                        poolData['my'].amt0 = Number(result.amt0);
                        poolData['my'].amt1 = Number(result.amt1);
                    }
    
                    response.push({ ...pool, farm: { total: totalFarmStaked || 0, user: userFarmStaked || 0 }, lpToken: true, name: pool.lpTokenSymbol, totalSupply: totalSupply || 0, blockchain: poolData || {} })
                }
            } catch (error) {
                console.log(error);
            }
        }
        return Promise.resolve({ poolWithSymbols, allowedPools, message: response });
    }

    async getPoolBySymbol(symbol) {
        const data = (await poolHttp.get('/pool/' + symbol)).data;
        // const data = {
        //     "statusCode": 200,
        //     "message": {
        //     "_id": "648e0ad1395c1c9758bb65c6",
        //     "symbol": "wSTX-VELAR",
        //     "token0Symbol": "wSTX",
        //     "token0ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx",
        //     "token0AssetName": "stx",
        //     "token1Symbol": "VELAR",
        //     "token1ContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.velar",
        //     "token1AssetName": "velar",
        //     "lpTokenContractAddress": "ST20X3DC5R091J8B6YPQT638J8NR1W83KN6JQ4P6F.wstx-velar",
        //     "lpTokenSymbol": "wSTX-VELAR",
        //     "id": "1",
        //     "lpTokenDecimal": "u0",
        //     "token0ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg",
        //     "token1ImageUrl": "https://raw.githubusercontent.com/velar-be/asset-hosting/main/velar.jpg",
        //     "token0Decimail": "u6",
        //     "token1Decimail": "u6",
        //     "token0DecimalNum": 1000000,
        //     "token1DecimalNum": 1000000,
        //     "lpTokenDecimalNum": 1,
        //     "lpTokenAssetName": "lp-token",
        //     "__v": 0,
        //     "createdAt": "2023-06-17T19:34:41.701Z",
        //     "updatedAt": "2023-06-17T19:34:41.701Z"
        //     }
        // };
        data.message.name = data.message.lpTokenSymbol;
        return Promise.resolve(data);
    }

    async getGraphValues(poolId, type = 'volume', interval = 'hour') {
       const result = await blockWatcher.get(`/stats/${poolId}?type=${type}&interval=${interval}`);
       return result.data;
    }

    async getPoolStats(poolId, interval = 24) {
        const result = await blockWatcher.get(`/stats/${poolId}/${interval}`);
        return result.data;
     }

    async saveTransaction (data) {
        const payload = {
            "publicAddress": data.publicAddress,
            "token0Symbol": data.token0Symbol,
            "token0ContractAddress": data.token0ContractAddress,
            "token0Quantity": String(data.token0Quantity),
            "token1Symbol": data.token1Symbol,
            "token1ContractAddress": data.token1ContractAddress,
            "token1Quantity": String(data.token1Quantity),
            "lpTokenQuantity": String(data.lpTokenQuantity),
            "lpTokenSymbol": data.lpTokenSymbol,
            "transactionHash": data.transactionHash,
            "status": 'success',
            "slippage": String(data.slippage),
            "poolId": String(data.poolId),
            "txResponse": data.txResponse,
            "walletInUse": data.walletInUse || 'hiro',
            "txType": data.txType,
            "deviceType": isMobile() ? 'mobile' : 'web',
        };

        await poolHttp.post('/pool/transactions', payload);
    }
}

export default (new PoolService());