import { BASE_ADDRESS, Config } from "../config";
import {
  buildPostConditions,
  getContractAddressAndName,
  getPureAddress,
  getTokenDetailsByAddress,
} from "../helper";
import http from "../utils/axiosProvider";
import {
  uintCV,
  contractPrincipalCV,
  cvToHex,
  cvToJSON,
  hexToCV,
  makeStandardSTXPostCondition,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
  makeContractSTXPostCondition,
  makeContractFungiblePostCondition,
  createFungiblePostCondition,
  createSTXPostCondition,
  PostConditionMode,
} from "@stacks/transactions";
import { ContractService } from "./ContractService";
import { store } from "../store";
import BigNum from "bn.js";
import { AppConstants } from "../constants";
import { getTokenBasedExcatValue } from "../helper";

export class RouterContractService {
  constructor() {
    const { address, name } = getContractAddressAndName(Config.ContractAddresses.Router);
    const contractService = new ContractService(
      address,
      name
    );
    this.contractService = contractService;
  }

  async swapExactTokensForTokens(
    id,
    token0,
    token1,
    inToken,
    outToken,
    amountIn,
    amountOut,
    transactionPayload
  ) {
    const store = window.velarStore;
    const auth = store.getState().auth;
    const account = auth.authData.stxAddress.address;
    transactionPayload.publicAddress = account;
    amountIn = Number(amountIn).toFixed(0);
    amountOut = Number(amountOut).toFixed(0);

    const token0Info = getContractAddressAndName(token0);
    const token1Info = getContractAddressAndName(token1);
    const inTokenInfo = getContractAddressAndName(inToken);
    const outTokenInfo = getContractAddressAndName(outToken);

    const amount0 = uintCV(amountIn);
    const amount1 = uintCV(amountOut);

    const postConditionMeta = [];
    if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(inTokenInfo.name.toLowerCase())) {
      const token1Detail = getTokenDetailsByAddress(token1);
      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.StandardSTX,
        code: FungibleConditionCode.Equal,
        amount: amount0.value,
      });
      postConditionMeta.push({
        account: token1Info.address,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.GreaterEqual,
        amount: amount1.value,
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
      });

      postConditionMeta.push({
        account: token0Info.address,
        type: AppConstants.PostConditionType.ContractSTX,
        code: FungibleConditionCode.LessEqual,
        amount: Number((amountIn * 0.01).toFixed(0))
      });

    } else if (
      AppConstants.NATIVE_TOKEN_SYMBOLS.includes(outTokenInfo.name.toLowerCase())
    ) {
      const token0Detail = getTokenDetailsByAddress(token0);
      const token1Detail = getTokenDetailsByAddress(token1);

      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.StandardSTXFungible,
        code: FungibleConditionCode.Equal,
        amount: amount0.value, //getTokenBasedExcatValue(amount0.value, token1Info.name),
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
      });
      postConditionMeta.push({
        account: token1Info.address,
        type: AppConstants.PostConditionType.ContractSTX,
        code: FungibleConditionCode.GreaterEqual,
        amount: amount1.value, //getTokenBasedExcatValue(amount1.value, token0Info.name),
        // assetInfo: {
        //   address: token0Info.address,
        //   contractName: token0Info.name,
        //   name: token0Detail.assetName,
        // },
      });

      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.LessEqual,
        amount: amount0.value, //getTokenBasedExcatValue(amount0.value, token1Info.name),
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
        amount: Number((amountIn * 0.01).toFixed(0))
      });
    }

    const stakingContract = getContractAddressAndName(Config.ContractAddresses.StakingDistributor);
    const postconditions = buildPostConditions(postConditionMeta);
    transactionPayload.txType = "swap-exact-tokens-for-tokens";
    const result = await this.contractService.callPublicFunction(
      "swap-exact-tokens-for-tokens",
      [
        uintCV(id),
        contractPrincipalCV(token0Info.address, token0Info.name),
        contractPrincipalCV(token1Info.address, token1Info.name),
        contractPrincipalCV(inTokenInfo.address, inTokenInfo.name),
        contractPrincipalCV(outTokenInfo.address, outTokenInfo.name),
        contractPrincipalCV(stakingContract.address,  stakingContract.name),
        uintCV(amountIn),
        uintCV(amountOut),
      ],
      postconditions ,
      transactionPayload,
    );
    return result;
  }

  async swapTokensForExactTokens(
    id,
    token0,
    token1,
    inToken,
    outToken,
    amountInMax,
    amountOutMax,
    transactionPayload
  ) {
    const token0Info = getContractAddressAndName(token0);
    const token1Info = getContractAddressAndName(token1);
    const inTokenInfo = getContractAddressAndName(inToken);
    const outTokenInfo = getContractAddressAndName(outToken);
    const store = window.velarStore;
    const auth = store.getState().auth;
    const account = auth.authData.stxAddress.address;
    transactionPayload.publicAddress = account;
    amountInMax = Number(amountInMax).toFixed(0);
    amountOutMax = Number(amountOutMax).toFixed(0);

    const amount0 = uintCV(amountInMax);
    const amount1 = uintCV(amountOutMax);

    const postConditionMeta = [];
    if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(inTokenInfo.name.toLowerCase())) {
      const token1Detail = getTokenDetailsByAddress(token1);
      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.StandardSTX,
        code: FungibleConditionCode.LessEqual,
        amount: amount0.value,
      });
      postConditionMeta.push({
        account: token1Info.address,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.Equal,
        amount: amount1.value,
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
      });

      postConditionMeta.push({
        account: token0Info.address,
        type: AppConstants.PostConditionType.ContractSTX,
        code: FungibleConditionCode.LessEqual,
        amount: uintCV(Number(Number(amountInMax) * 0.01).toFixed(0)).value
      });

    } else if (
      AppConstants.NATIVE_TOKEN_SYMBOLS.includes(outTokenInfo.name.toLowerCase())
    ) {
      const token0Detail = getTokenDetailsByAddress(token0);
      const token1Detail = getTokenDetailsByAddress(token1);

      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.StandardSTXFungible,
        code: FungibleConditionCode.LessEqual,
        amount: amount0.value,
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
      });
      postConditionMeta.push({
        account: token0Info.address,
        type: AppConstants.PostConditionType.ContractSTX,
        code: FungibleConditionCode.Equal,
        amount: amount1.value,
        // assetInfo: {
        //   address: token1Info.address,
        //   contractName: token1Info.name,
        //   name: token1Detail.assetName,
        // },
      });

      postConditionMeta.push({
        account: account,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.LessEqual,
        amount: uintCV(Number((Number(amount0.value) * 0.01).toFixed(0))).value,
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: token1Detail.assetName,
        },
      });
    }

    const postconditions = buildPostConditions(postConditionMeta);
    const stakingContract = getContractAddressAndName(Config.ContractAddresses.StakingDistributor);
    transactionPayload.txType = "swap-tokens-for-exact-tokens";
    const result = await this.contractService.callPublicFunction(
      "swap-tokens-for-exact-tokens",
      [
        uintCV(id),
        contractPrincipalCV(token0Info.address, token0Info.name),
        contractPrincipalCV(token1Info.address, token1Info.name),
        contractPrincipalCV(inTokenInfo.address, inTokenInfo.name),
        contractPrincipalCV(outTokenInfo.address, outTokenInfo.name),
        contractPrincipalCV(stakingContract.address,  stakingContract.name),
        uintCV(amountInMax),
        uintCV(amountOutMax),
      ],
      postconditions,
      transactionPayload
    );
    return result;
  }

  async addLiquidity(id, token0, token1, lpToken, amt0Desired, amt1Desired, transactionPayload, slippage, poolInfo) {
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const store = window.velarStore;
      const account = auth.authData.stxAddress.address;
      transactionPayload.publicAddress = account;
      
      const token0Info = getContractAddressAndName(token0);
      const token1Info = getContractAddressAndName(token1);
      const lpTokenInfo = getContractAddressAndName(lpToken);

      const amt0 = Number(amt0Desired) - Number(amt0Desired) * (Number(slippage) / 100);
      const amt1 = Number(amt1Desired) - Number(amt1Desired) * (Number(slippage) / 100);

      const amount0 = uintCV(amt0.toFixed(0));
      const amount1 = uintCV(amt1.toFixed(0));

      transactionPayload.token0Quantity = Number(amount0.value);
      transactionPayload.token1Quantity = Number(amount1.value);

      const postConditionMeta = [];
      if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(token0Info.name.toLowerCase())) {
        const token1Detail = getTokenDetailsByAddress(token1);

        postConditionMeta.push({
          account: account,
          type: AppConstants.PostConditionType.StandardSTX,
          code: FungibleConditionCode.GreaterEqual,
          amount: amount0.value,
        });
        postConditionMeta.push({
          account: account,
          type: AppConstants.PostConditionType.StandardSTXFungible,
          code: FungibleConditionCode.GreaterEqual,
          amount: amount1.value,
          assetInfo: {
            address: token1Info.address,
            contractName: token1Info.name,
            name: poolInfo.token1AssetName,
          },
        });
      } else if (
        AppConstants.NATIVE_TOKEN_SYMBOLS.includes(token1Info.name.toLowerCase())
      ) {
        const token0Detail = getTokenDetailsByAddress(token0);
        postConditionMeta.push({
          account: account,
          type: AppConstants.PostConditionType.StandardSTXFungible,
          code: FungibleConditionCode.GreaterEqual,
          amount: amount1.value,
          assetInfo: {
            address: token0Info.address,
            contractName: token0Info.name,
            name: poolInfo.token0AssetName,
          },
        });
        postConditionMeta.push({
          account: account,
          type: AppConstants.PostConditionType.StandardSTX,
          code: FungibleConditionCode.GreaterEqual,
          amount: amount0.value,
        });
      } else {
        // will add more tokens other than stx
      }

      // const comparator0 = FungibleConditionCode.GreaterEqual;
      // const comparator1 = FungibleConditionCode.GreaterEqual;
      // const fungibleAssetInfo0 = createAssetInfo(token1Info.address, token1Info.name, "velar");
      // const amountInPostCondition0 = createSTXPostCondition(account, comparator0, amount0.value);
      // const amountInPostCondition1 = makeStandardFungiblePostCondition(account, comparator1, amount1.value, fungibleAssetInfo0);

      const postconditions = buildPostConditions(postConditionMeta);
      transactionPayload.txType = "add-liquidity";
      const result = await this.contractService.callPublicFunction(
        "add-liquidity",
        [
          uintCV(id),
          contractPrincipalCV(token0Info.address, token0Info.name),
          contractPrincipalCV(token1Info.address, token1Info.name),
          contractPrincipalCV(lpTokenInfo.address, lpTokenInfo.name),
          uintCV(Number(amt0Desired).toFixed(0)),
          uintCV(Number(amt1Desired).toFixed(0)),
          uintCV(amt0.toFixed(0)),
          uintCV(amt1.toFixed(0)),
        ],
        postconditions,
        transactionPayload
      );

      return result;
    }
    return false;
  }

  async removeLiquidity(
    id,
    token0,
    token1,
    lpToken,
    liquidityAmount,
    amt0Min,
    amt1Min,
    transactionPayload,
    poolInfo
  ) {
    const auth = store.getState().auth;
    if (auth.isLoggedIn) {
      const store = window.velarStore;
      const account = auth.authData.stxAddress.address;
      const token0Info = getContractAddressAndName(token0);
      const token1Info = getContractAddressAndName(token1);
      const lpTokenInfo = getContractAddressAndName(lpToken);
      liquidityAmount = uintCV(Number(liquidityAmount));
      transactionPayload.publicAddress = account;
      const postConditionMeta = [
        {
          account: account,
          type: AppConstants.PostConditionType.StandardSTXFungible,
          code: FungibleConditionCode.Equal,
          amount: liquidityAmount.value,
          assetInfo: {
            address: lpTokenInfo.address,
            contractName: lpTokenInfo.name,
            name: poolInfo.lpTokenAssetName,
          },
        }
      ]

      postConditionMeta.push({
        account: token0Info.address,
        type: AppConstants.PostConditionType.ContractSTX,
        code: FungibleConditionCode.GreaterEqual,
        amount: Number(amt0Min)
      })

      postConditionMeta.push({
        account: token1Info.address,
        type: AppConstants.PostConditionType.ContractFungible,
        code: FungibleConditionCode.GreaterEqual,
        amount: Number(amt1Min),
        assetInfo: {
          address: token1Info.address,
          contractName: token1Info.name,
          name: poolInfo.token1AssetName,
        }
      })
      transactionPayload.token0Quantity = Number(amt0Min);
      transactionPayload.token1Quantity = Number(amt1Min);

      const postconditions = buildPostConditions(postConditionMeta);
      transactionPayload.txType = "remove-liquidity";
      const result = await this.contractService.callPublicFunction(
        "remove-liquidity",
        [
          uintCV(id),
          contractPrincipalCV(token0Info.address, token0Info.name),
          contractPrincipalCV(token1Info.address, token1Info.name),
          contractPrincipalCV(lpTokenInfo.address, lpTokenInfo.name),
          liquidityAmount,
          uintCV(Number(amt0Min)),
          uintCV(Number(amt1Min)),
        ],
        postconditions,
        transactionPayload,
      );

      return result;
    }
  }
}

export default new RouterContractService();
