import React from 'react';
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { ifNanGiveZero } from "../helper";
import ExchangeService from "../services/ExchangeService";
import ActionSheet from "actionsheet-react";
import { initAccordions, initFlowbite } from 'flowbite'

export const ConfirmSwapModal = (props) => {
    const ref = useRef(null);
    const [ style, setStyle ] = useState({ collapsibleState: true });
    const [collapsibleState, setcollapsibleState] = useState({ collapsibleState: false });
    const { onClose, onCofirm, printMinMaxValues, swaps, slippage, minMaxOutput, swapPrice1, swapPrice2 } = props;
    const actionSheetRef = useRef();

    const myClose = (e) => {
        if (actionSheetRef.current) {
            actionSheetRef.current.close();
        }
        if (ref.current) {
            ref.current.classList.remove('opacity-100');
        }
        setTimeout(() => {
            onClose && onClose(e)
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth < 1024) {
                if (actionSheetRef.current) {
                    actionSheetRef.current.open();
                    setTimeout(() => {
                        initAccordions();
                    }, 1000)
                }
            }
            if (ref.current) {
                // ref.current.classList.remove('opacity-0');
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    const [ swap1, swap2 ] = swaps;

    const ModalContent = () => {
        return (
            <div className="modal-content flex justify-center flex-col m-auto z-[901]">
            <div className="modal-header">
                <h5 className="modal-title">Confirm Swap</h5>
                <button onClick={(e) => myClose(e)} type="button" className="btn-close" data-modal-hide="confirmSwapModal">
                    <svg aria-hidden="true" className="w-5 md:w-6 h-5 md:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="swap-container relative">
                <div className="swapable mb-2.5 md:mb-4">
                    <div className="flex flex-col justify-between mb-1.5">
                        <div className="flex flex-row justify-between">
                            <p className="balance">Buying</p>
                            <h4 className="amount">{ swap1.value }</h4>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className="selected-coin mt-2">
                                <img className="icon" src={swap1.imageUrl} alt="" />
                                <h4 className="currency">{ swap1.name }</h4>
                            </div>
                            <p className="balance mt-2">${ ExchangeService.getTokenDollarPrice(swap1.value, swap1.symbol) }</p>
                        </div>
                    </div>
                </div>

                <div className="btn-swap">
                    <img className="icon" src="/assets/icons/arrow-up.svg" alt="" />
                </div>

                <div className="swapable">
                    <div className="flex flex-col justify-between mb-1.5">
                        <div className="flex flex-row justify-between text-left">
                            <p className="balance">Selling</p>
                            <h4 className="amount">{ swap2.value }</h4>
                        </div>
                        <div className="flex flex-row justify-between text-right">
                            <div className="selected-coin mt-2">
                                <img className="icon" src={swap2.imageUrl} alt="" />
                                <h4 className="currency">{ swap2.name }</h4>
                            </div>
                            <p className="balance mt-2">${ ExchangeService.getTokenDollarPrice(swap2.value, swap2.symbol) }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <div className="collapsible-swap-details md:mt-2 mb-5 md:mb-4" data-accordion="collapse">
                    <button onClick={() => setcollapsibleState({ collapsibleState: !collapsibleState.collapsibleState })}
                        type="button" className="w-full"
                        data-accordion-target="#collapsible-details" ariaaria-expanded="true" aria-controls="collapsible-details"
                    >
                        { !swapPrice1.value  ? (
                                <p className="md-text">{ 1 } <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(swapPrice1.calc_val / swapPrice2.calc_val)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                            ) : (
                                <p className="md-text">{ 1 } <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(swapPrice1.value / swapPrice2.value)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                            ) }
                        {/* <p className="md-text">{1} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span> <span className="lowercase">≈</span> {ifNanGiveZero(swapPrice2.value / swapPrice1.value).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span></p> */}
                        {/* <p className="lg-text">{ ifNanGiveZero(swapPrice1.value / swapPrice2?.value).toFixed(2) } { swapPrice1.name } <span className="lowercase">per</span> { swapPrice2.name }</p> */}
                        {/* <span>0.002456 BTC per AMPL</span> */}
                        <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!collapsibleState.collapsibleState && 'rotate-180')}
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div id="collapsible-details" className={collapsibleState.collapsibleState ? 'hidden' : ''}>
                        <div className="info">
                            <div className="flex items-center justify-between mb-4">
                                <p className="key text-dark">{ minMaxOutput.title }</p>
                                { printMinMaxValues('value text-dark') }
                            </div>
                            <div className="flex items-center justify-between mb-4">
                                <p className="key text-dark">Slippage Tolerance</p>
                                <p className="value text-warning">{ slippage } %</p>
                            </div>
                            <div className="flex items-center justify-between mb-1">
                                <p className="key text-dark">Liquidity Provider Fee</p>
                                <h6 className="value text-dark">{(swapPrice1.value * 0.003).toFixed(6)} {swapPrice1.symbol}</h6>
                            </div>  
                        </div>
                        <hr className="my-1" />
                        <div className="flex items-center justify-between p-2">
                            <p className="key text-dark">Route</p>
                            <p className="value text-dark">{ swapPrice1.symbol} &#62; { swapPrice2.symbol }</p>
                        </div>
                    </div>
                </div>
                <button onClick={() => {
                    onCofirm && onCofirm()
                }} className="btn-warning btn-orange btn-new">Confirm</button>
            </div>
        </div>
        )
    }

    if (window.innerWidth < 1024) {
        return (
            <React.Fragment>
                <ActionSheet 
                    onClose={() => {
                        // setLayoutState({ showSideBar: false, showWalletSidebar: false })
                    }}
                    ref={actionSheetRef}
                    sheetStyle={{
                        backgroundColor: '#0D0805',
                        paddingBottom: 20,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                    }}
                    bgStyle={{
                        backgroundColor: "rgba(1, 1, 1, 0.8)"
                    }}>
                    <div id='confirmSwapModal' className='wallet-info actionsheet'>
                        <div className='actionsheet-bar'></div>
                        { ModalContent() }
                    </div>
                </ActionSheet>
            </React.Fragment>
        )
    }

    return createPortal(
        <div ref={ref} id="confirmSwapModal" tabIndex="-1" aria-hidden="true" className="transition-all duration-300 opacity-0 fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            { ModalContent() }
        </div>
    , document.body)
}