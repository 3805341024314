import { Config } from '../config';
import { AppConstants } from '../constants';
import http from '../utils/axiosProvider';
import { StacksDevnet, StacksTestnet, StacksMainnet } from '@stacks/network'

class VelarService { 
    getNetwork () {
        if (Config.name === AppConstants.Networks.MAINNET) {
            return new StacksMainnet();
        } else if (Config.name === AppConstants.Networks.TESTNET) {
            return new StacksTestnet();
        } else if (Config.name === AppConstants.Networks.DEVNET) {
            return new StacksDevnet();
        }
        return null;
    }

    getAppDetails () {
        return {
            name: 'Velar',
            icon: window.location.origin + '/assets/imgs/velar-logo.png',
        }
    }
}

export default (new VelarService());