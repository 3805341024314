import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PoolService from '../../services/PoolService';

const InitialState = {
    poolsLoader: false,
    graphLoader: false,
    statsLoader: false,
    farmLoader: false,
    stats: {
        // poolId key
        1: {
            data: {
                price: { value: 0, priceChange24h: 0 },
                fees: { value: 0, priceChange24h: 0 },
                transaction: { value: 0, priceChange24h: 0 },
                tvl: { value: 0, priceChange24h: 0 },
                volume: { value: 0, priceChange24h: 0 }
            }, 
            timestamp: new Date().getTime() 
        }
    },
    graph: { 
        // poolId key
        1: {
            data: [], 
            timestamp: new Date().getTime() 
        }
    },
    farms: { allowedPools: [], poolWithSymbols: {}, statusCode: '', message: [] },
    pools: { allowedPools: [], poolWithSymbols: {}, statusCode: '', message: [] },
};

export const getPools = createAsyncThunk(
    'pools/list',
    async (params, store) => {
        const context = params?.context ? params : { context: 'global' };
        const pools = await PoolService.getPools(context);
        return pools;
    }
)

export const getFarms = createAsyncThunk(
    'farms/list',
    async (params, store) => {
        const context = params?.context ? params : { context: 'global' };
        const farms = await PoolService.getPools(context);
        return farms;
    }
)

export const getPoolGraph = createAsyncThunk(
    'pools/graph',
    async (params, store) => {
        const graph = await PoolService.getGraphValues(params.poolId, params.type, params.interval);
        graph.id = params.poolId;
        return graph;
    }
)

export const getPoolStats = createAsyncThunk(
    'pools/stats',
    async (params, store) => {
        const stats = await PoolService.getPoolStats(params.poolId, params.interval);
        stats.id = params.poolId;
        return stats;
    }
)


export const poolSlice = createSlice({
    name: 'pool',
    initialState: InitialState,
    reducers: {
        setGraphPoolLoader (state, action) {
            state.graphLoader = action.payload;
        },
        setGraphEmpty (state, action) {
            state.graph = { data: [], timestamp: new Date().getTime() };
        },
        setStatsLoader (state, action) {
            state.statsLoader = action.payload;
        },
        setPoolLoader (state, action) {
            state.poolsLoader = action.payload;
        },
        setFarmLoader (state, action) {
            state.farmLoader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPoolStats.fulfilled, (state, action) => {
            state.statsLoader = false;
            state.stats[action.payload.id] = action.payload;
        })
        builder.addCase(getPoolStats.rejected, (state, action) => {
            state.statsLoader = false;
            state.stats = InitialState.stats;
        })
        builder.addCase(getPoolGraph.fulfilled, (state, action) => {
            state.graphLoader = false;
            state.graph[action.payload.id] = action.payload;
        })

        builder.addCase(getPoolGraph.rejected, (state, action) => {
            state.graphLoader = false;
            state.graph = InitialState.graph;
        })
        builder.addCase(getPools.fulfilled, (state, action) => {
            state.poolsLoader = false;
            state.pools = action.payload;
        })
        builder.addCase(getPools.rejected, (state, action) => {
            state.poolsLoader = false;
            state.pools = InitialState.pools;
        })

        builder.addCase(getFarms.fulfilled, (state, action) => {
            state.farmLoader = false;
            state.farms = action.payload;
        })
        builder.addCase(getFarms.rejected, (state, action) => {
            state.farmLoader = false;
            state.farms = InitialState.farms;
        })
    },
})

export const { setStatsLoader, setFarmLoader, setPoolLoader, setGraphPoolLoader, setGraphEmpty } = poolSlice.actions
export default poolSlice.reducer
