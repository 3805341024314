import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, standardPrincipalCV, cvToHex, cvToJSON, hexToCV } from '@stacks/transactions';
import { ContractService } from './ContractService';

export class CoreContractService { 
    constructor () {
        const { address, name } = getContractAddressAndName(Config.ContractAddresses.Core)
        const contractService = new ContractService(address, name);
        this.contractService = contractService;
    }

    async getPool (id = 1) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('get-pool', { 
            sender: address,
            arguments: [
            cvToHex(uintCV(id)),
        ]});
        const parsedResult = hexToCV(result.data.result);
        return parsedResult;
    }

    async calcBurn (lpQuantity, reserve0, reserve1, totalSupply) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        lpQuantity = Number(lpQuantity);
        reserve0 = Number(reserve0);
        reserve1 = Number(reserve1);
        totalSupply = Number(totalSupply);

        return {
            amt0: ((lpQuantity * reserve0) / totalSupply),
            amt1: ((lpQuantity * reserve1) / totalSupply)
        };

        return 
        if (auth.isLoggedIn) {
            const result = await this.contractService.callReadOnly('calc-burn', { 
                sender: auth.authData.stxAddress.address,
                arguments: [
                    cvToHex(uintCV(Number(lpQuantity).toFixed(0))),
                    cvToHex(uintCV(Number(reserve0).toFixed(0))),
                    cvToHex(uintCV(Number(reserve1).toFixed(0))),
                    cvToHex(uintCV(Number(totalSupply).toFixed(0))),
                ]
            });
            if (result.data.okay === false) {
                return { amt0: 0, amt1: 0 }
            } else {
                const res = hexToCV(result.data.result);
                const r = { amt0: res.data.amt0.value, amt1: res.data.amt1.value };
                console.log(test, r);
                return r;
            }

        }
        return false;
    }

    async calcMint (amt0, amt1, reserve0, reserve1, totalSupply) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        if (auth.isLoggedIn) {
            const result = await this.contractService.callReadOnly('calc-mint', { 
                sender: auth.authData.stxAddress.address,
                arguments: [
                    cvToHex(uintCV(Number(amt0))),
                    cvToHex(uintCV(Number(amt1))),
                    cvToHex(uintCV(Number(reserve0))),
                    cvToHex(uintCV(Number(reserve1))),
                    cvToHex(uintCV(Number(totalSupply))),
                ]
            });
            const res = hexToCV(result.data.result);
            return Number(res.value);

        }
        return false;
    }
}

export default (new CoreContractService());