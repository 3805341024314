import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import ModalLoader from "../components/ModalLoader";
import { ModalState } from "./LoaderModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AppConstants } from "../constants";
import Loader from "../components/CirclLoader";

export const SuccessfulModal = (props) => {
    const { state, swap1, swap2, forType, message } = props;
    const [settings, setSettings] = useState(ModalState.IN_PROGRESS);
    const { onClose } = props;
    const auth = useSelector((x) => x.auth);

    const [ timeOutRef, setTimeOutRef ] = useState(null);
    const [ timeOutRef2, setTimeOutRef2 ] = useState(null);

    useEffect(() => {
        let timeOutRef1 = setTimeout(() => {
            setSettings(ModalState.DONE);   
            let timeOutInnerRef2 = setTimeout(() => {
                onClose && onClose();
            }, 5000);
            setTimeOutRef2(timeOutInnerRef2);
        }, 0);
        setTimeOutRef(timeOutRef1);
    }, []);


    const isAsignaWallet = auth.authData?.wallet === AppConstants.Wallets.Asigna;

    const onCloseClicked = (e) => {
        clearTimeout(timeOutRef);
        clearTimeout(timeOutRef2);

        onClose && onClose(e)
    }

    return createPortal(
        <div id="successfulModal" tabIndex="-1" aria-hidden="true" className="swapActionModal fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content flex justify-center flex-col m-auto z-[901]">
                <div className="modal-header flex direction-row justify-between">
                    <h3 className="modal-title text-white">Swap</h3>
                    <button onClick={(e) => onCloseClicked(e)} type="button" className="text-white z-10 btn-close" data-modal-hide="confirmSwapModal">
                        <svg aria-hidden="true" className="w-5 md:w-6 h-5 md:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="progress-bar mb-5 flex justify-between items-center">
                    <div className="flex gap-2 justify-between disabled items-center">
                        <img src="/assets/icons/step-icon.svg" alt="" />
                        <span>Confirm</span>
                    </div>
                    <div>
                        <img src="/assets/icons/strip.svg" alt="" />
                    </div>
                    <div className={ (settings === ModalState.IN_PROGRESS ? '' : 'disabled ') +  "flex gap-2 justify-between items-center"}>
                        { settings === ModalState.IN_PROGRESS ? (
                            <Loader customHeight={13} borderWidth={1} width={15} />
                        ) : (
                            <img src="/assets/icons/step-icon.svg" alt="" />
                        ) }
                        <span>Begin</span>
                    </div>
                    <div>
                        <img src="/assets/icons/strip.svg" alt="" />
                    </div>
                    <div className={ (settings === ModalState.IN_PROGRESS ? 'disabled ' : '') +   "flex gap-2 justify-between items-center" }>
                    { settings !== ModalState.IN_PROGRESS ? (
                        <img src="/assets/icons/step-icon.svg" alt="" />
                    ) : (
                        <img src="/assets/icons/step-dis.svg" alt="" />
                    ) }
                        <span>Submited</span>
                    </div>
                </div>
                { isAsignaWallet ? (
                    <>
                        <div className="text-center mb-10">                            
                            <img className="w-36 mx-auto" src="/assets/icons/icon-circular-success-modal.svg" alt="" />
                        </div>
                        <p className="heading">Complete your transaction on Asigna Wallet</p>
                        <a href={'https://stx.asigna.io/'} target="_balnk" className="btn-warning btn-orange flex items-center justify-center mt-6">
                            Asigna Wallet
                            <img className="icon ml-3 w-3" src="/assets/icons/icon-sent-arrow.svg" alt="" />
                        </a>
                    </>
                ) : (
                    <>
                        { settings !== ModalState.IN_PROGRESS ? (
                            <>
                                <div className="text-center mb-5">
                                    <img className="w-24 mx-auto" src="/assets/icons/check-circle-done.svg" alt="" />
                                </div>
                                <div className="text-center absolute mb-5">
                                    <img className="mx-auto" src="/assets/imgs/success-anim.gif" alt="" />
                                </div>
                                <h4 className="heading">Transaction Submited</h4>
                                <p className="heading" style={{ margin: '0 auto', width: '75%', textAlign: 'center', fontSize: 16, marginTop: 10, color: '#F5F7FA99' }}>Transactions may take some time. You can close this window</p>
                                { forType !== 'faucet' && (
                                    <div className="p-2 z-20">
                                        <a href={state.transactionUrl} target="_balnk" className="btn-warning btn-orange flex items-center justify-center mt-6">
                                            View on block explorer
                                            <img className="icon ml-3 w-3" src="/assets/icons/icon-sent-arrow.svg" alt="" />
                                        </a>
                                    </div>
                                )}
                                {/* <h4 className="heading">Waiting for your <span>transaction</span></h4> */}
                                {/* <p className="heading" style={{ fontSize: 18, marginTop: 10 }}>to be confirm on blockchain</p> */}
                            </>
                        ) : (
                            <>
                                <div className="text-center mb-24">
                                    <Loader customHeight={13} borderWidth={2} width={80} />
                                    {/* <img className="w-36 mx-auto" src="/assets/icons/icon-circular-success-modal.svg" alt="" /> */}
                                </div>
                                { forType == 'stake' && (
                                    <h4 className="heading">{message}</h4>
                                )}
                                { forType == 'farm-stake' && (
                                    <h4 className="heading">{message}</h4>
                                )}
                                { forType == 'swap' && (
                                    <h4 className="heading">Swap in Progress!</h4>
                                    // <h4 className="heading">You swapped <span>{ swap1.value } { swap1.name }</span> for <span>{ swap2.value } { swap2.name }</span></h4>
                                )}
                                { forType == 'pool' && (
                                    <h4 className="heading">{message}<span>{ swap1.value } { swap1.name }</span> + <span>{ swap2.value } { swap2.name }</span></h4>
                                )}
                                { forType === 'faucet' && (
                                    <h4 className="heading">{message}</h4>                            
                                ) }
                                { forType === 'rewards' && (
                                    <h4 className="heading">{message}</h4>                            
                                ) }
                                <p className="heading" style={{ margin: '0 auto', width: '75%', textAlign: 'center', fontSize: 16, marginTop: 10, color: '#F5F7FA99' }}>Transactions may take some time. You can close this window</p>
                                { forType !== 'faucet' && (
                                    <div className="p-2">
                                        <a href={state.transactionUrl} target="_balnk" className="btn-warning btn-orange flex items-center justify-center mt-6">
                                            View on block explorer
                                            <img className="icon ml-3 w-3" src="/assets/icons/icon-sent-arrow.svg" alt="" />
                                        </a>
                                    </div>
                                )}
                            </>
                        ) }
                    </>
                ) }
            </div>
        </div>
    , document.body)
}