import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, contractPrincipalCV, cvToHex, cvToJSON, hexToCV,  } from '@stacks/transactions';
import { ContractService } from './ContractService';

export class PoolContractService {
    constructor() {}

    async getTotalSupply(lpTokenContractAddress = '') {
        // lpTokenContractAddress = 'SP53DQFH1F3WJMQCGT6X5501XXZKFAEVHD3DPGVH.wstx-xusd'
        if (!lpTokenContractAddress) { return 0; }
        
        const { address, name } = getContractAddressAndName(lpTokenContractAddress);
        const contractService = new ContractService(address, name);
        this.contractService = contractService;
        const store = window.velarStore;
        const auth = store.getState().auth;
        const account = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('get-total-supply', {
            sender: account,
            arguments: []
        });
        return Number(hexToCV(result.data.result).value.value);
    }
}

export default (new PoolContractService());