import React, { useContext } from 'react';
import { Wallet } from './wallet/Wallet';
import { LayoutContext } from '../contexts/LayoutContext';
import { createPortal } from "react-dom";

export const RightSideBar = (props) => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const isMobile = window.innerWidth <= 576;
    let className = 'right-sidebar';
    if (layoutState.showSideBar) {
        className += ' show';
    }
    if (isMobile) {
        className += ' mobile'
    } else {
        className += ' desktop'
    }
    return createPortal(
        <React.Fragment>
            { (layoutState.showMobileNavModal || layoutState.showSideBar) && <div onClick={() => setLayoutState({ ...layoutState, showMobileNavModal: false, showSideBar: false })} className="full-wrapper"></div> }
            <div className={className}>
                <Wallet { ...props } />
                <button onClick={() => setLayoutState({ ...layoutState, showSideBar: false })} className='close-right-sidebar'>
                    <img src='/assets/icons/right-double-arrows.svg' alt="" />
                </button>
            </div>
        </React.Fragment>
    , document.body)
}