import React from "react";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getTokenBasedExcatValue } from '../helper';
import { AppConstants } from "../constants";
import Loader from "../components/Loader";
import ActionSheet from "actionsheet-react";
import { initAccordions } from "flowbite";

export const TokenSelectionModal = (props) => {
    const ref = useRef(null);
    const [style, setStyle] = useState({ state: true });
    const [state, setState] = useState({ query: '' })
    const swap = useSelector((x) => x.swap);
    const auth = useSelector((x) => x.auth);
    const actionSheetRef = useRef();
    const poolStore = useSelector((x) => x.pool);
    const otherToken = props.otherToken

    const { onClose } = props;
    const myClose = (e) => {
        if (actionSheetRef.current) {
            actionSheetRef.current.close();
        }
        if (ref.current) {
            ref.current.classList.remove('opacity-100');
        }
        setTimeout(() => {
            onClose && onClose(e)
        }, 300)
    }

    const allTokens = [...auth.coins, ...(auth.info?.tokens || [])];
    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth < 1024) {
                if (actionSheetRef.current) {
                    actionSheetRef.current.open();
                    setTimeout(() => {
                        initAccordions();
                    }, 1000)
                }
            }
            if (ref.current) {
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    const isSelectable = (token) => {
        const combinations = [
            [token.symbol.toLowerCase(), otherToken.toLowerCase()].join('-'),
            [otherToken.toLowerCase(), token.symbol.toLowerCase()].join('-'),
        ]
        if (combinations[0] !== combinations[1] && otherToken.toLowerCase() !== 'select token') {
            const isAllowed = poolStore.pools.allowedPools.find((x) => {
                const found = combinations.find((combo) => combo === x.join('-'));
                return found;
            });
            if (!isAllowed) {
                return false;
            }

            return true;
        }

        return true;
    }

    const ModalContent = () => {
        return (
            <div className="modal-content flex justify-center flex-col m-auto z-[901]">
                <div className="modal-header">
                    <h5 className="modal-title">Select a Token</h5>
                    <button onClick={(e) => myClose(e)} type="button" className="btn-close" data-modal-hide="tokenSelectionModal">
                        <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-3 md:px-4 mb-5 md:mb-4">
                    <from>
                        <input value={state.query} onChange={(e) => setState({ ...state, query: e.target.value })} className="search-token" type="text" placeholder="Search name" />
                    </from>
                </div>
                <div className="popular-tokens">
                    {/* <h6 className="heading">Popular tokens:</h6> */}
                    <div className="tokens">
                        {swap.tokens.message.map((token, index) => {
                            const selectable = !isSelectable(token);
                            return (
                                <button disabled={selectable} className="item" key={index} onClick={(e) => props.onSelect && props.onSelect(token)}>
                                    <img className="icon" src={token.imageUrl} alt="" />
                                    <span style={{ color: !selectable ? '#eee' : '#555' }}>{token.name}</span>
                                </button>
                            )
                        })}
                    </div>
                </div>
                <div className="searched-coins overflow-y-auto">
                    {poolStore.poolsLoader && (
                        <div className="p-5">
                            <Loader />
                        </div>
                    )}
                    {swap.tokens.message.filter((x) => x.name?.toLowerCase().indexOf(state.query) > -1).map((token, index) => {
                        const tokenInfo = allTokens.find((x) => x.contract === token.contractAddress) || allTokens.find((x) => x.symbol?.toLowerCase() === token?.symbol?.toLowerCase()) || allTokens.find((x) => x?.symbol?.toLowerCase() === token?.vsymbol?.toLowerCase());
                        const selectable = !isSelectable(token);
                        return (
                            <div className="item" key={index} onClick={(e) => props.onSelect && props.onSelect(token)}>
                                <div className="coin-name">
                                    <img className="icon" src={token.imageUrl} alt="" />
                                    <div>
                                        <h6 style={{ color: !selectable ? '#eee' : '#555' }} className="lg-text">{token.name}</h6>
                                        <p className="sm-text">{token.symbol}</p>
                                    </div>
                                </div>
                                {!AppConstants.NATIVE_TOKEN_SYMBOLS.includes(token.symbol?.toLowerCase()) ? (
                                    <h6 className="coin-value">{getTokenBasedExcatValue(tokenInfo?.balance, token.symbol) || 0}</h6>
                                ) : (
                                    <h6 className="coin-value">{tokenInfo?.balance || 0}</h6>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    if (window.innerWidth < 1024) {
        return (
            <React.Fragment>
                <ActionSheet
                    onClose={() => {
                        actionSheetRef.current.close();
                        // setLayoutState({ showSideBar: false, showWalletSidebar: false })
                    }}
                    ref={actionSheetRef}
                    sheetStyle={{
                        backgroundColor: '#0D0805',
                        paddingBottom: 20,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                    }}
                    bgStyle={{
                        backgroundColor: "rgba(1, 1, 1, 0.8)"
                    }}>
                    <div id='tokenSelectionModal' className='wallet-info actionsheet'>
                        <div className='actionsheet-bar'></div>
                        { ModalContent() }
                    </div>
                </ActionSheet>
            </React.Fragment>
        )
    }

    return createPortal(
        <div ref={ref} id="tokenSelectionModal" tabIndex="-1" aria-hidden="true" className="transition-all duration-300 opacity-0 fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            { ModalContent() }
        </div>
    , document.body)
}