import { Link } from 'react-router-dom';
import { useState } from "react";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { toggleBodyScroll } from "../helper";

export default function ModalLoader(props) {
    const { completed } = props;
    return (
        <div className='modal-loader'>
            { !completed ? (
                <div className="lds-ring" style={{ '--d' : '1.2' }}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            ) : (
                <div className='lds-done'>
                    <img src='/assets/imgs/tick.png' />
                </div> 
            ) }
        </div>
    )
}



// <div className="lds-ring">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:1.2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>

// <div className="lds-ring" style="--d:2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:3">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>