import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Breadcrumbs from '../Breadcrumbs';
import Chart from '../Charts/Chart';
import Summary from '../Summary';
import { Config } from '../../config';
import { useSelector } from 'react-redux';

export default function PoolHeader(props) {
    const { settings, setSettings } = props;
    const pool = useSelector((x) => x.pool);
    const params = useParams();

    const poolId = Number(settings.poolInfo?.id)     
    const stats = pool.stats[poolId] || { data: {} };

    return (
        <>
            {/* <Breadcrumbs crumbs={[{ name: 'Pool', link: '/pool' }, { name: (params.id || '') + ' - Liquidity Pool', link: `/pool/${params.id}` }]} /> */}
            <Link to={'/pool'} className={(props.showGraph ? 'hidden lg:flex ' : '') + 'color-velar gap-2 flex items-center text-sm mb-5 hand'}>
                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.2496 5.50068C13.2496 5.69959 13.1706 5.89035 13.0299 6.03101C12.8893 6.17166 12.6985 6.25068 12.4996 6.25068H3.31211L6.53211 9.47005C6.67301 9.61095 6.75217 9.80204 6.75217 10.0013C6.75217 10.2006 6.67301 10.3917 6.53211 10.5326C6.39122 10.6734 6.20012 10.7526 6.00086 10.7526C5.80161 10.7526 5.61051 10.6734 5.46961 10.5326L0.969615 6.03255C0.899695 5.96287 0.844217 5.88008 0.806363 5.78891C0.768509 5.69775 0.749023 5.60001 0.749023 5.5013C0.749023 5.40259 0.768509 5.30485 0.806363 5.21369C0.844217 5.12252 0.899695 5.03973 0.969615 4.97005L5.46961 0.470051C5.53938 0.400286 5.6222 0.344946 5.71335 0.307189C5.80451 0.269433 5.9022 0.25 6.00086 0.25C6.09953 0.25 6.19722 0.269433 6.28838 0.307189C6.37953 0.344946 6.46235 0.400286 6.53211 0.470051C6.60188 0.539816 6.65722 0.622638 6.69498 0.71379C6.73273 0.804942 6.75217 0.902639 6.75217 1.0013C6.75217 1.09996 6.73273 1.19766 6.69498 1.28881C6.65722 1.37996 6.60188 1.46279 6.53211 1.53255L3.31211 4.75068H12.4996C12.6985 4.75068 12.8893 4.82969 13.0299 4.97035C13.1706 5.111 13.2496 5.30176 13.2496 5.50068Z" fill="#F5F7FA" fillOpacity="0.36"/>
                </svg>
                <span>
                Back to all Pools
                </span>
            </Link>
            <div className={(props.showGraph ? 'hidden lg:flex ' : '') + "pool-details-header"}>
                <div className="pool-info">
                    <div className="icons">
                        <img
                            className="z-6"
                            src={settings.token0.imageUrl}
                            alt="coin two" />
                        <img
                            className="z-7"
                            src={settings.token1.imageUrl}
                            alt="coin one" />
                    </div>
                    <div className="relative w-full md:w-auto flex md:flex justify-between">
                        <div className="coins">
                            { settings.poolInfo.name }
                            { settings?.poolInfo?.lpTokenContractAddress && (
                                <a href={`https://explorer.hiro.so/address/${settings?.poolInfo?.lpTokenContractAddress}?chain=${Config.name}`} target='_blank' className="ml-1">
                                    <img className='w-5' src="/assets/icons/link.svg" alt="link" />
                                </a>
                            ) }
                        </div>
                        {/* <p className="fee">
                            Fees: <span className="">0.3%</span>
                        </p> */}
                    </div>
                </div>
                <div className="action-btns">
                    {/* <Link to="#" className="header-btn">
                        Rewards: <span> 10.26% </span>
                    </Link> */}
                    <Link to="#" className="header-btn">
                        Fees: <span> 0.3% </span>
                    </Link>
                    <Link to="#" className="header-btn">
                        APR: <span> { Number(stats.data.apr?.value).toFixed(6) }% </span>
                    </Link>
                </div>
            </div>
            { props.showGraph && (
                <>
                    <Chart settings={settings} />
                    {/* <hr className="my-6 md:my-2" /> */}
                    <div className='flex justify-between items-center mb-2 mt-2'>
                        <h3 className='text-white text-lg'>Pool Stats</h3>
                        {/* <h5 className='text-white text-sm'>Show Less</h5> */}
                    </div>
                    <Summary poolId={poolId} />
                </>
            )}
        </>
    )
}
