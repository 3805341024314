import { Route, Router, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from "./pages/Layout";
import { Home } from "./pages/Home";
import { Pool } from "./pages/Pool";
import { Stake } from "./pages/Stake";
import { IDO } from "./pages/IDO";
import { Farm } from "./pages/Farm";
import { Faucet } from "./pages/Faucet";
import { Notifications } from "./pages/Notifications";
import 'react-rangeslider/lib/index.css';
import { Detail } from "./components/IDO/Detail";
import { FarmStake } from "./components/Farm/FarmStake";
import { getAccountTokens, setCoinLoader, setTokenLoader, updateAuth } from "./store/slices/authSlice";
import { LiquidityPool } from "./pages/LiquidityPool";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { getTokens, setSwapTokenLoader } from "./store/slices/swapSlice";
import { getPools, setPoolLoader } from "./store/slices/poolSlice";
import { AggrementModal } from "./modals/AggrementModal";
import { AppConstants } from "./constants";
import { addScript } from "./helper";
import { Config } from "./config";
import { NotificationsV2 } from "./pages/NotificationsV2";
import { getCurrentBlock, getTransactions } from "./store/slices/chainSlice";
import { setAppFocus } from "./store/slices/appSlice";
import { Points } from "./pages/Points";

function App() {
  const [state, setState] = useState({ aggrement: false, focus: false });
  const app = useSelector((x) => x.app);
  const dispatch = useDispatch();
  const location = useLocation();

  if (localStorage.getItem('wallet-state')) {
    const authData = JSON.parse(localStorage.getItem('wallet-state'));
    dispatch(updateAuth({ isLoggedIn: true, authData: authData }));
  }

  useEffect(() => {
    if (Config.name === AppConstants.Networks.MAINNET) {
      const onPageLoad = () => {
        setTimeout(() => {
          const authData = JSON.parse(localStorage.getItem('wallet-state'));
          if (authData) {
            const walletProvider = authData.wallet === AppConstants.Wallets.Hiro ? AppConstants.Hiro.ID : AppConstants.XVerse.ID;
            const script = document.getElementById(walletProvider);
            if (script) {
              addScript(script.src).then({});
            }
          }
        }, 1000);
      };
  
      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad);
        return () => window.removeEventListener('load', onPageLoad);
      }
    }

    window.addEventListener("focus", () => {
      const xdispatch = window.velarStore.dispatch;
      xdispatch(setAppFocus(true));
    })

    window.addEventListener("blur", () => {
      const xdispatch = window.velarStore.dispatch;
      xdispatch(setAppFocus(false));
    })

  }, [])


  const runInternval = () => {
    let interval = setInterval(() => {
      dispatch(getCurrentBlock());
      dispatch(setTokenLoader(true));
      dispatch(setCoinLoader(true))
      dispatch(getAccountTokens());
    }, 60000);
    return interval;
  }

  useEffect(() => {
    if (app.appFocus) {
      init();
    }
  }, [app.appFocus])

  useEffect(() => {
      let interval = runInternval();
      return () => {
        clearInterval(interval);
      }
  }, [])

  const init = async () => {
      dispatch(getTransactions());
      dispatch(getCurrentBlock());
      dispatch(setTokenLoader(true));
      dispatch(setSwapTokenLoader(true));
      dispatch(getAccountTokens());
      dispatch(getTokens());
      dispatch(setPoolLoader(true))
      dispatch(getPools());
  }

  useEffect(() => {
    // if (Config.name === AppConstants.Networks.MAINNET) {
    //   const aggrement = localStorage.getItem('velar-aggrement');
    //   setState({ ...state, aggrement: Boolean(aggrement) });
    // }
    init();
  }, [location.pathname])
  
  return (
    <div className="velar-app relative">
      <Layout>
        {/* {(!state.aggrement && Config.name == AppConstants.Networks.MAINNET) && (
          <AggrementModal onClose={(e) => {
            setState({ aggrement: true })
          }} />
        )} */}
        <Routes>
            <Route path="/" element={ <Home /> } />
            {(Config.name == AppConstants.Networks.MAINNET) && (
              <Route path="/points" element={ <Points /> } />
            )}
            <Route path="/faucet" element={ <Faucet /> } />
            <Route path="/swap" element={ <Home /> } />
            <Route path="/pool/:id" element={ <Pool /> } />
            {/* <Route element={<PrivateRoutes />} /> */}
            <Route path="/pool" element={ <LiquidityPool /> } exact />
            <Route path="/stake" element={ <Stake /> } />
            {/* <Route path="/ido" element={ <IDO /> } /> */}
            {/* <Route path="/ido/details" element={ <Detail /> } /> */}
            <Route path="/farm/:id" element={ <FarmStake /> } />
            <Route path="/farm" element={ <Farm /> } />
            <Route path="/notifications" element={ <NotificationsV2 /> } />
        </Routes>
      </Layout>
      <ReactTooltip id="tooltip" place="bottom" />
      {(Config.name == AppConstants.Networks.MAINNET) && (
        <a href="https://beta.velar.co" target="_blank" className="beta-banner">
          <span>Velar Beta now at beta.velar.co </span>
          <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.61307 1C11.0153 2.03704 12.2748 3.24852 13.3603 4.60379C13.4534 4.72005 13.5 4.86002 13.5 5M9.61307 9C11.0153 7.96296 12.2748 6.75148 13.3603 5.39621C13.4534 5.27995 13.5 5.13998 13.5 5M13.5 5H1.5" stroke="#F89728" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      )}
    </div>
  );
}

export default App;
