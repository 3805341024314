import { useEffect, useState } from "react";
import { filterDecimals, isCleanNumberInput, removeLeadingZeros } from "../helper";

export const Input = (props) => {
    const allowDecimal = props.allowDecimal === false ? false : true;
    const [ value, setValue ] = useState(props.value);
    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, "");
    const removeExtraDot = num => {
        let nums = num.split('.');
        num = nums[0];
        if (nums.length > 1) {
            num += '.' + nums[1];
        }
        return filterDecimals(num.toString().replace(/\.+/g, "."));
    };

    const onChnage = (e) => {
        if (Number(e.target.value) >= 10_000_000_000) {
            return;
        }

        if (!isCleanNumberInput(e)) {
            return;
        }

        e.target.value = removeNonNumeric(removeExtraDot(e.target.value)); 
        e.target.value = removeLeadingZeros(e.target.value);
        setValue(e.target.value);

        
        props.onChange && props.onChange({ target: { value: e.target.value } });
    }

    // useEffect(() => {
    //     props.onChange && props.onChange({ target: { value: value } });
    // }, [value]);

    return (
        <input 
            inputMode="decimal"
            pattern="^[0-9]*[.]?[0-9]*$"
            spellCheck="false"
            autoComplete="off"
            autoCorrect="off"
            { ...props }
            type="text"
            onChange={onChnage}
        />
    )
}