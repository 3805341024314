import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, contractPrincipalCV, tupleCV, cvToHex, cvToJSON, hexToCV } from '@stacks/transactions';
import { ContractService } from './ContractService';
import { store } from '../store';

export class CreateContractService {
    constructor() {
        const contractService = new ContractService(getPureAddress(Config.ContractAddresses.Core), 'core');
        this.contractService = contractService;
    }

    async mint(token0, token1, lpToken, swap, protocol) {
        const token0Info = getContractAddressAndName(token0);
        const token1Info = getContractAddressAndName(token1);
        const lpTokenInfo = getContractAddressAndName(lpToken);

        const result = await this.contractService.callPublicFunction(
            'mint', [
                uintCV(1),
                contractPrincipalCV(token0Info.address, token0Info.name),
                contractPrincipalCV(token1Info.address, token1Info.name),
                contractPrincipalCV(lpTokenInfo.address, lpTokenInfo.name),
                uintCV(1000),
                uintCV(1000)
                // tupleCV({ den: uintCV(1), num: uintCV(1) }),
                // tupleCV({ den: uintCV(1), num: uintCV(1) }),
            ]
        )
        return result;
    }
}

export default (new CreateContractService());