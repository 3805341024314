import { Link } from "react-router-dom";
import { TokenSelectionModal } from "../modals/TokenSelectionModal";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { ConfirmSwapModal } from "../modals/ConfirmSwapModal";
import { SuccessfulModal } from "../modals/SuccessfulModal";
import { WaitingModal } from "../modals/WaitingModal";
import { WarningModal } from "../modals/WarningModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { filterDecimals, getTokenBasedDecimalValue, getTokenBasedExcatValue, ifNanGiveZero, isCleanNumberInput, toggleBodyScroll, velarToast } from "../helper";
import { LoaderModal } from "../modals/LoaderModal";
import Breadcrumbs from "./Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import CoreContractService from "../services/CoreContractService";
import LibraryContractService from "../services/LibraryContractService";
import debounce from 'lodash/debounce';
import Loader from "./Loader";
import RouterContractService from "../services/RouterContractService";
import { toast } from "react-toastify";
import { Config } from "../config";
import { getAccountTokens, setTokenLoader } from "../store/slices/authSlice";
import { useConnect } from "../hooks/useConnect";
import { LayoutContext } from "../contexts/LayoutContext";
import { SWAP_INITIAL_STATE } from "../states/initialStates";
import { AppConstants } from "../constants";
import { ConnectContext } from "../contexts/ConnectContext";
import ExchangeService from "../services/ExchangeService";
import { Input } from "./Input";

const [ZERO, ONE] = [0, 1];

export const SwapComponent = (props) => {
    const { isLoggedIn, authData } = useContext(ConnectContext);
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);
    const swap = useSelector((x) => x.swap);
    const app = useSelector((x) => x.app);
    const [pool, setPool] = useState({});
    const connect = useConnect();
    const [priceDropdown, setPriceDropdown] = useState(true);
    const balance = connect.useBalance();
    const [priceLoader, setPriceLoader] = useState(false);
    const [state, setState] = useState(Object.assign({}, JSON.parse(JSON.stringify(SWAP_INITIAL_STATE))))
    const [calcVal, setCalcVal] = useState({ loader: false, val: [1, 1] });
    const [swapDirection, setSwapDirection] = useState(false);
    const [swapCurrentIndex, setSwapCurrentIndex] = useState(-1);
    const [currentPoolId, setCurrentPoolId] = useState(1);
    const [stamp, setStamp] = useState(null);
    const poolStore = useSelector((x) => x.pool);

    useEffect(() => {
        init();
        if (auth?.isLoggedIn === false) {
            state.swaps[0].balance = 0;
            state.swaps[1].balance = 0;
            setState({ ...state });
        }
    }, [auth?.isLoggedIn]);

    useEffect(() => {
        let interval = setInterval(() => {
            if (!state.isDirty) {
                calculatePriceComparision('interval');
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [auth, state, calcVal, app.appFocus])

    useEffect(() => {
        calculatePriceComparision('symbol');
    }, [state.swaps, state.swaps[0].symbol])

    const calculatePriceComparision = async (from) => {
        try {
            if (!calcVal.loader && state.swaps[0].symbol) {
                setCalcVal({ ...calcVal, loader: true })
                const poolData = await getPoolData();
                const reserve0 = poolData['reserve0'].value;
                const reserve1 = poolData['reserve1'].value;
                const swapFee = poolData['swap-fee'];
                const protocolFee = poolData['protocol-fee'];
                if (!swapDirection) {
                    const amount = getTokenBasedDecimalValue(1, state.swaps[0].symbol);
                    const amountOut = await LibraryContractService.getAmountOut(amount, reserve0, reserve1, swapFee, protocolFee);
                    setCalcVal({ val: [1, getTokenBasedExcatValue(amountOut, state.swaps[1].symbol)], loader: false });
                    return poolData;
                } else {
                    const amount = getTokenBasedDecimalValue(1, state.swaps[1].symbol);
                    const amountOut = await LibraryContractService.getAmountOut(amount, reserve0, reserve1, swapFee, protocolFee);
                    setCalcVal({ val: [1, getTokenBasedExcatValue(amountOut, state.swaps[0].symbol)], loader: false });
                    return poolData;
                }
            }
            return pool;
        } catch (e) {
            console.log('error in calc price');
            setCalcVal({ ...calcVal, loader: false })
            return pool;
        }
    }

    const init = async () => {
        const result = await calculatePriceComparision('init');
        return result;
    }

    const getPoolData = async () => {
        const pool = await CoreContractService.getPool(currentPoolId);
        const poolData = pool?.value?.data || {};
        setPool(poolData);
        return poolData;
    }

    const updateTokenBalances = (xstate) => {
        const swaps = xstate.swaps;
        const token0 = auth.info?.tokens?.find((x) => x.contract === swaps[0].contractAddress)?.balance || 0;
        if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(swaps[0].symbol.toLowerCase())) {
            swaps[0].balance = balance;
        } else {
            swaps[0].balance = getTokenBasedExcatValue(token0, swaps[0].symbol.toLowerCase());
        }
        const token1 = auth.info?.tokens?.find((x) => x.contract === swaps[1].contractAddress)?.balance || 0;
        if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(swaps[1].symbol.toLowerCase())) {
            swaps[1].balance = balance;
        } else {
            swaps[1].balance = getTokenBasedExcatValue(token1, swaps[1].symbol.toLowerCase());
        }
        setState({ ...xstate, swaps });
    }


    const setUpBalance = async () => {
        let swaps = state.swaps;
        if (swap?.tokens?.message?.length > 1) {
            swaps = [
                { ...swaps[0], value: '', balance: 0.0, ...swap.tokens.message[0] },
                { ...swaps[1], value: '', balance: 0.0, ...swap.tokens.message[1] },
            ]
            state.swaps = swaps;
            setState({
                ...state,
            })
        } else if (swap.tokens?.message?.length > 0) {
            swaps = [
                { ...swaps[0], value: '', balance: 0.0, ...swap.tokens.message[0] },
                { ...swaps[1], value: '', balance: 0.0, ...swap.tokens.message[0] },
            ]
            state.swaps = swaps;
            setState({
                ...state,
            })
        }
        updateTokenBalances(state);
    }

    useEffect(() => {
        setUpBalance();
    }, [state.reloadBalance, swap.tokens.message?.length, auth.info?.tokens?.length, balance]);


    useEffect(() => {
        if (swapCurrentIndex > -1) {
            updateSwapData(swapCurrentIndex);
        } else {
            calculatePriceComparision('swapCurrentIndex');
        }
    }, [swapCurrentIndex, stamp, currentPoolId]);

    const isBalanceLoading = () => {
        return auth.info.loader || auth.coinLoader;
    }

    const updateSwapData = async (index) => {
        try {
            const _pool = await init();
            const swapFee = _pool['swap-fee'];
            const protocolFee = _pool['protocol-fee'];
            const reserve0 = _pool['reserve0'].value;
            const reserve1 = _pool['reserve1'].value;
            state.swaps[ONE].autoFilled = false;
            state.swaps[ZERO].autoFilled = false;
            let amountOut = 0;

            setPriceLoader(true);
            const amount = state.swaps[index].value === 0 ? 0 : getTokenBasedDecimalValue(state.swaps[index].value, state.swaps[index].symbol);
            const [r0, r1] = AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.swaps[ZERO].symbol.toLowerCase()) ? [reserve0, reserve1] : [reserve1, reserve0];


            if (index === ZERO) {
                if (!parseFloat(state.swaps[index].value)) {
                    state.swaps[ONE].value = 0
                }
                if (amount !== 0) {
                    amountOut = await LibraryContractService.getAmountOut(amount, r0, r1, swapFee, protocolFee);
                } else {
                    amountOut = 0;
                }
                state.swaps[ONE].value = getTokenBasedExcatValue(amountOut, state.swaps[ONE].symbol);
                if (amount !== 0) {
                    if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.swaps[ZERO].symbol.toLowerCase())) {
                        setCalcVal({ val: [1, (state.swaps[ONE].value / state.swaps[ZERO].value)], loader: false });
                    } else {
                        setCalcVal({ val: [1, (state.swaps[ZERO].value / state.swaps[ONE].value)], loader: false });
                    }
                } else {
                    await calculatePriceComparision('updateSwapData');
                }

                state.minMaxOutput.title = "Minimum Output";
                state.minMaxOutput.operator = "-";
                state.minMaxOutput.value = Number(state.swaps[ONE].value) - Number(state.swaps[ONE].value) * (state.slippage / 100);
                state.minMaxOutput.symbol = state.swaps[ONE].symbol;
                state.minMaxOutput.exact_value = Number(state.swaps[ONE].value);

                state.swaps[ONE].autoFilled = true;
                state.swaps[ONE].loader = false;
                state.swaps[ZERO].autoFilled = false;

            } else if (index === ONE) {
                if (amount !== 0) {
                    amountOut = await LibraryContractService.getAmountIn(amount, r0, r1, swapFee);
                } else {
                    amountOut = 0;
                }
                state.swaps[ZERO].value = getTokenBasedExcatValue(amountOut, state.swaps[ZERO].symbol);

                if (amount !== 0) {
                    if (AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.swaps[ZERO].symbol.toLowerCase())) {
                        setCalcVal({ val: [1, (state.swaps[ONE].value / state.swaps[ZERO].value)], loader: false });
                    } else {
                        setCalcVal({ val: [1, (state.swaps[ZERO].value / state.swaps[ONE].value)], loader: false });
                    }
                } else {
                    await calculatePriceComparision('updateSwapData else')
                }

                state.minMaxOutput.title = "Maximum Input";
                state.minMaxOutput.value = Number(state.swaps[ZERO].value) + Number(state.swaps[ZERO].value) * (state.slippage / 100);
                state.minMaxOutput.exact_value = Number(state.swaps[ZERO].value);
                state.minMaxOutput.symbol = state.swaps[ZERO].symbol;
                state.minMaxOutput.operator = "+";

                state.swaps[ZERO].autoFilled = true;
                state.swaps[ZERO].loader = false;

                state.swaps[ONE].autoFilled = false;
            }
            state.amountError = false;
            setPriceLoader(false);
        } catch (error) {
            console.log(error);
            state.swaps[ONE].loader = false;
            state.swaps[ZERO].loader = false;

            state.swaps[ONE].autoFilled = false;
            state.swaps[ZERO].autoFilled = false;
            state.amountError = true;
            state.swaps[index === ZERO ? ONE : ZERO].autoFilled = true;
            setPriceLoader(false);
        }

        setState({ ...state, isDirty: true });
    };

    const handleGetAmountOf = useCallback(debounce((index) => {
        setSwapCurrentIndex(index);
        setStamp(new Date().getTime())
    }, 700), [swapCurrentIndex])

    const onInputChange = (e, index) => {
        if (!isCleanNumberInput(e)) {
            setState({ ...state });
            return;
        }

        // if (state.swaps[index].value ===  && Number(e.target.value) === 0) {
        //     return;
        // }

        let value = e.target.value;

        if (!value) {
            if (index === ZERO) {
                swap1.value = value;
                swap2.value = value;
            } else if (index === ONE) {
                swap2.value = value;
                swap1.value = value;
            }
            setState({ ...state });
            return;
        }

        setPriceLoader(true);
        if (index === ZERO) {
            // value = (value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 7)) : value;
            swap1.value = value;
            state.swaps[0] = swap1;
            state.swaps[1].loader = true;
            setState({ ...state })
        } else if (index === ONE) {
            // value = (value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 7)) : value;
            swap2.value = value;
            state.swaps[1] = swap2;
            state.swaps[0].loader = true;
            setState({ ...state })
        }
        handleGetAmountOf(index);
    }

    const onSwapConfirm = async () => {
        let result = {};
        const [_token0, _token1] = AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.swaps[ZERO].symbol.toLowerCase()) ? [swap1, swap2] : [swap2, swap1];
        const transactionPayload = {};
        transactionPayload.fromTokenSymbol = swap1.symbol;
        transactionPayload.fromTokenName = swap1.name;
        transactionPayload.fromContractAddress = swap1.contractAddress;
        transactionPayload.toTokenSymbol = swap2.symbol;
        transactionPayload.toTokenName = swap2.name;
        transactionPayload.toContractAddress = swap2.contractAddress;
        transactionPayload.slippage = state.slippage;
        transactionPayload.poolId = 1;

        if (swap1.autoFilled) {
            let maximumInput = (Number(state.minMaxOutput.exact_value) + (Number(state.minMaxOutput.exact_value) * Number(state.slippage) / 100));
            if (!AppConstants.NATIVE_TOKEN_SYMBOLS.includes(swap1.symbol.toLowerCase())) {
                maximumInput = maximumInput.toFixed(6)
            }
            transactionPayload.fromTokenQuantity = getTokenBasedDecimalValue(maximumInput, swap1.symbol).toFixed(6);
            transactionPayload.toTokenQuantity = getTokenBasedDecimalValue(swap2.value, swap2.symbol).toFixed(6);

            result = await RouterContractService.swapTokensForExactTokens(
                currentPoolId,
                _token0.contractAddress,
                _token1.contractAddress,
                swap1.contractAddress,
                swap2.contractAddress,
                transactionPayload.fromTokenQuantity,
                transactionPayload.toTokenQuantity,
                transactionPayload
            );
        } else if (swap2.autoFilled) {
            let minimumOutput = (Number(state.minMaxOutput.exact_value) - (Number(state.minMaxOutput.exact_value) * Number(state.slippage) / 100))
            if (!AppConstants.NATIVE_TOKEN_SYMBOLS.includes(swap2.symbol.toLowerCase())) {
                minimumOutput = minimumOutput.toFixed(6)
            }

            transactionPayload.fromTokenQuantity = getTokenBasedDecimalValue((swap1.value), swap1.symbol).toFixed(6);
            transactionPayload.toTokenQuantity = getTokenBasedDecimalValue(minimumOutput, swap2.symbol).toFixed(6);

            result = await RouterContractService.swapExactTokensForTokens(
                currentPoolId,
                _token0.contractAddress,
                _token1.contractAddress,
                swap1.contractAddress,
                swap2.contractAddress,
                transactionPayload.fromTokenQuantity,
                transactionPayload.toTokenQuantity,
                transactionPayload
            );
        }

        velarToast(<div>
            <div className="p-3 pb-0">
                <div className="title">Swapping {swap1.value} {swap1.symbol} for {swap2.value} {swap2.symbol}</div>
                <div className="timestamp">Less than 25 sec ago</div>
            </div>
            <a className="link p-4 flex justify-between" target="_blank" href={`${Config.url}txid/${result?.txId}?chain=${Config.name}`}>
                View on explorer
                <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
            </a>
        </div>);
        calculatePriceComparision('confirm');
        setState({ ...state, confirmModal: false, transactionUrl: `${Config.url}txid/${result?.txId}?chain=${Config.name}`, successfulModal: true })
    }

    const [swap1, swap2] = state.swaps;

    const [swapPrice1, swapPrice2] = state.priceSwap ? [swap1, swap2] : [swap2, swap1];
    const [calcPrice1, calcPrice2] = [calcVal.val[0], calcVal.val[1]];
    const isLowPrice = false; //state.isDirty && (Number(swap1.value) < 1 || Number(swap2.value) < 1)

    const printSwapDireciton = () => {
        if (auth.isLoggedIn || swap.tokens.message.length > 0) {
            if (calcVal.loader || priceLoader) {
                return <div className="lg-text"><Loader /></div>
            } else {
                if (state.priceSwap) {
                    if (!swapDirection) {
                        return <p className="lg-text">{1} <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(calcPrice1 / calcPrice2)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                    } else {
                        return <p className="lg-text">{1} <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(calcPrice2)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                    }
                } else {
                    if (swapDirection) {
                        return <p className="lg-text">{1} <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(calcPrice1 / calcPrice2)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                    } else {
                        return <p className="lg-text">{1} <span style={{ fontWeight: 'normal' }}>{swapPrice2.symbol}</span> <span className="lowercase">≈</span> {(ifNanGiveZero(calcPrice2)).toFixed(6)} <span style={{ fontWeight: 'normal' }}>{swapPrice1.symbol}</span></p>
                    }
                }
            }
        }
    }

    const printMinMaxValues = (className = '') => {
        if (state.minMaxOutput.operator === '-') {
            let val = Number(state.minMaxOutput.exact_value) - (Number(state.minMaxOutput.exact_value) * Number(state.slippage) / 100);
            if (!AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.minMaxOutput.symbol.toLowerCase())) {
                val = val.toFixed(6);
            } else {
                val = val.toFixed(6);
            }
            return <h6 className={className + " value"}>{val} {state.minMaxOutput.symbol}</h6>
        } else if (state.minMaxOutput.operator === '+') {
            let val = (Number(state.minMaxOutput.exact_value) + (Number(state.minMaxOutput.exact_value) * Number(state.slippage) / 100));
            if (!AppConstants.NATIVE_TOKEN_SYMBOLS.includes(state.minMaxOutput.symbol.toLowerCase())) {
                val = val.toFixed(6);
            } else {
                val = val.toFixed(6);
            }
            return <h6 className={className + " value"}>{val} {state.minMaxOutput.symbol}</h6>
        }
    }

    return (
        <div className={`swap-main ${!state.isDirty ? 'mt-12' : ''}`}>
            <div className="swap-box">
                <div className="header">
                    <h1 className="heading">Swap</h1>
                    <div className="slippage">
                        <div className="sl-text">Slippage: {state.slippage}%</div>
                        <div
                            onClick={(() => {
                                if (auth.isLoggedIn) {
                                    setState({ ...state, transactionModal: true }); toggleBodyScroll(true)
                                } else {
                                    setLayoutState({
                                        showSideBar: !layoutState.showSideBar,
                                        showWalletSidebar: !layoutState.showWalletSidebar
                                    })
                                }
                            })}
                            className="sl-btn">Edit</div>
                    </div>
                    {/* <button
                        onClick={(() => {
                            if (auth.isLoggedIn) {
                                setState({ ...state, transactionModal: true }); toggleBodyScroll(true)
                            } else {
                                setLayoutState({
                                    showSideBar: !layoutState.showSideBar,
                                    showWalletSidebar: !layoutState.showWalletSidebar
                                })
                            }
                        })}
                        className="btn-settings" type="button">
                        <img src="/assets/icons/icon-settings.svg" alt="" />
                    </button> */}
                </div>
                <div className="swap-container">
                    <div className="swap-from mb-[3px]">
                        <div className="flex items-center justify-between bottom-line">
                            <p className="sm-text">From</p>
                            <div className="right flex items-center justify-between">
                                <div className="right flex items-center justify-between">
                                    <div className="swap-tokens-dd">
                                        <button onClick={() => { setState({ ...state, tokenModal: true, tokenModalIndex: 0 }); toggleBodyScroll(true) }} className="btn-select-coin">
                                            <img className="icon" src={swap1.imageUrl} alt="" />
                                            <h4 className="currency mr-[6px]">{swap1.symbol}</h4>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12.0605 13.2323L17.3534 7.93945L18.7676 9.35367L12.0605 16.0608L5.35336 9.35367L6.76758 7.93945L12.0605 13.2323Z" fill="white" />
                                            </svg>
                                        </button>
                                    </div>
                                    {/* <p onClick={() => {
                                        if (auth.isLoggedIn) {
                                            state.swaps[0].value = Number(state.swaps[0].balance || 0);
                                            setState({
                                                ...state,
                                            })
                                            setTimeout(() => {
                                                if (swap1.contractAddress) {
                                                    onInputChange({ target: { value: state.swaps[0].value } }, ZERO);
                                                }
                                            }, 0)
                                        } else {
                                            setLayoutState({
                                                showSideBar: !layoutState.showSideBar,
                                                showWalletSidebar: !layoutState.showWalletSidebar
                                            })
                                        }
                                    }} className="badge cursor-pointer">Max</p> */}
                                    {/* {swap1.loader && (
                                        <div className="loader-inline ml-3 flex items-center text-center">
                                            <Loader width={25} />
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between bottom-line">
                            <div className="flex-1">
                                <Input
                                    onChange={(e) => {
                                        if (swap1.contractAddress) {
                                            onInputChange(e, ZERO);
                                        }
                                    }}
                                    value={swap1.value || ''}
                                    type="number"
                                    step=".01"
                                    placeholder="0.00"
                                    className="amount" />
                            </div>
                            <div className="swap-tokens-dd flex gap-1 items-center justify-between">
                                <div className="balance">${ExchangeService.getTokenDollarPrice(swap1.value, swap1.symbol)}</div>
                                {(!!swap1.value) && (
                                    <>
                                        <span className="color-velar text-md">•</span>
                                        <span onClick={() => {
                                            state.isDirty = false;
                                            state.swaps[0].value = '';
                                            state.swaps[1].value = '';
                                            setState({
                                                ...state,
                                            })
                                        }} className="hand color-velar text-sm">Clear</span>
                                    </>
                                )}
                            </div>

                            {/* <div className="swap-tokens-dd">
                                <button onClick={() => { setState({ ...state, tokenModal: true, tokenModalIndex: 0 }); toggleBodyScroll(true) }} className="btn-select-coin">
                                    <img className="icon" src={swap1.imageUrl} alt="" />
                                    <h4 className="currency">{swap1.symbol}</h4>
                                    <svg className="w-5 ml-1.5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div> */}
                        </div>
                        <div className="flex items-center justify-between bottom-line-balance">
                            <div className="flex-1">
                                <div className="balance">Balance</div>
                            </div>
                            <div className="swap-tokens-dd flex gap-2 items-center justify-between">
                                <div className="balance">{isBalanceLoading() ? <Loader style={{ marginLeft: 10, display: 'inline-block' }} width={20} /> : swap1.balance}</div>
                                <span
                                    onClick={() => {
                                        if (auth.isLoggedIn) {
                                            state.swaps[0].value = Number(state.swaps[0].balance || 0);
                                            setState({
                                                ...state,
                                            })
                                            setTimeout(() => {
                                                if (swap1.contractAddress) {
                                                    onInputChange({ target: { value: state.swaps[0].value } }, ZERO);
                                                }
                                            }, 0)
                                        } else {
                                            setLayoutState({
                                                showSideBar: !layoutState.showSideBar,
                                                showWalletSidebar: !layoutState.showWalletSidebar
                                            })
                                        }
                                    }}
                                    className="color-orange hand text-sm">Use Max</span>
                            </div>
                        </div>
                    </div>
                    <div className="swap-to">
                        <div className="flex items-center justify-between bottom-line">
                            <p className="sm-text">To</p>
                            <div className="right flex items-center justify-between">
                                <div className="swap-tokens-dd">
                                    <button onClick={() => { setState({ ...state, tokenModal: true, tokenModalIndex: 1 }); toggleBodyScroll(true) }} className="btn-select-coin">
                                        <img className="icon" src={swap2.imageUrl} alt="" />
                                        <h4 className="currency  mr-[6px]">{swap2.symbol}</h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12.0605 13.2323L17.3534 7.93945L18.7676 9.35367L12.0605 16.0608L5.35336 9.35367L6.76758 7.93945L12.0605 13.2323Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between bottom-line">
                            <div className="flex-1">
                                <Input
                                    onChange={(e) => {
                                        if (swap2.contractAddress) {
                                            onInputChange(e, ONE);
                                        }
                                    }}
                                    step=".01"
                                    type="number"
                                    value={swap2.value || ''}
                                    placeholder="0.00"
                                    className="amount" />
                            </div>
                            <div className="balance">${ExchangeService.getTokenDollarPrice(swap2.value, swap2.symbol)}</div>
                            {/* <div className="swap-tokens-dd">
                                <button onClick={() => { setState({ ...state, tokenModal: true, tokenModalIndex: 1 }); toggleBodyScroll(true) }} className="btn-select-coin">
                                    <img className="icon" src={swap2.imageUrl} alt="" />
                                    <h4 className="currency">{swap2.symbol}</h4>
                                    <svg className="w-5 ml-1.5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div> */}
                        </div>
                        <div className="flex items-center justify-between bottom-line-balance">
                            <div className="flex-1">
                                <div className="balance">Balance</div>
                            </div>
                            <div className="swap-tokens-dd">
                                <div className="balance">{isBalanceLoading() ? <Loader style={{ marginLeft: 10, display: 'inline-block' }} width={20} /> : swap2.balance}</div>
                            </div>
                        </div>
                    </div>
                    <button onClick={(e) => {
                        const index = !swap2.autoFilled ? 0 : 1;
                        state.swaps = [swap2, swap1];
                        updateTokenBalances(state);
                        if (state.swaps[0].value > 0 || state.swaps[1].value > 0) {
                            handleGetAmountOf(index);
                        }
                        setSwapDirection(!swapDirection);
                    }} className="btn-swap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M14.9902 3.49977L20.0102 8.50977" stroke="#F5F7FA" strokeOpacity="0.13" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.9902 20.5L14.9902 3.5" stroke="#F5F7FA" strokeOpacity="0.13" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.01023 20.5L3.99023 15.49" stroke="#F5F7FA" strokeOpacity="0.13" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.00976 3.5L9.00977 20.5" stroke="#F5F7FA" strokeOpacity="0.13" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {/* <img className="icon" src="/assets/icons/direction-multi.svg" alt="" /> */}
                    </button>
                </div>

                {auth.isLoggedIn && (
                    <>
                        {isLowPrice ?
                            <button className="btn-insufficient">Amount too low</button>
                            : state.amountError || swap1.value > Number(swap1.balance) ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button disabled={(!swap1.value || !swap2.value)} className={((!swap1.value || !swap2.value) ? 'disabled ' : '') + " btn-warning btn-orange btn-new"} onClick={(() => {
                                    if (!swap1.value || !swap2.value) {
                                        toast.error("swap values are not correct.", { position: 'bottom-right' });
                                        return;
                                    }
                                    setState({ ...state, confirmModal: true }
                                    ); toggleBodyScroll(true)
                                })}>
                                    Confirm Swap
                                </button>
                                // <button disabled={(swap1.value < 1 || swap2.value < 1)} className={((swap1.value < 1 || swap2.value < 1) ? 'disabled ' : '') + "btn-warning"} onClick={(() => {
                                //     if (swap1.value < 1 || swap2.value < 1) {
                                //         toast.error("swap values are not correct.", { position: 'bottom-right' });
                                //         return;
                                //     }
                                //     setState({ ...state, confirmModal: true }
                                //     ); toggleBodyScroll(true)
                                // })}>
                                //     Swap
                                // </button>
                            )}
                        {/* <button className="btn-dark">Connect wallet</button> */}
                    </>
                )}

                {!auth.isLoggedIn && (
                    <>
                        <button
                            onClick={() => {
                                setLayoutState({
                                    showSideBar: !layoutState.showSideBar,
                                    showWalletSidebar: !layoutState.showWalletSidebar
                                })
                            }}
                            className="btn-warning btn-orange btn-new">
                            Connect Wallet
                        </button>
                        {/* <button className="btn-dark">Connect wallet</button> */}
                        {/* <button className="btn-insufficient">Insufficient Balance</button> */}
                    </>
                )}

                {(swap1.contractAddress && swap2.contractAddress) ? (
                    <div className="eth-per-ampl mt-[16px]">
                        <div className={((state.isDirty && priceDropdown) ? 'dropdown ' : '') + "direction-col p-2 ampl-padding flex items-center justify-between"}>
                            <div className="direction-row flex">
                                {printSwapDireciton()}
                                {/* <button onClick={() => {
                                    setState({ ...state, priceSwap: !state.priceSwap });
                                }} className="btn-reload"><img className="icon" src="/assets/icons/icon-reload.svg" alt="" />
                                </button> */}
                                {/* <p className="sm-text mr-3">Price</p> */}
                                {/* {priceLoader ? <Loader width={22} /> : " "} */}
                            </div>
                            {state.isDirty && (
                                <>
                                    <div style={{ transform: !priceDropdown ? 'rotate(0deg)' : 'rotate(180deg)' }} onClick={() => setPriceDropdown(!priceDropdown)} className="flex hand items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.0605 13.2323L17.3534 7.93945L18.7676 9.35367L12.0605 16.0608L5.35336 9.35367L6.76758 7.93945L12.0605 13.2323Z" fill="#F5F7FA" fillOpacity="0.6" />
                                        </svg>
                                    </div>
                                </>
                            )}
                        </div>

                        {(state.isDirty && priceDropdown) && (
                            <div className="direction-col mb-2 ampl-padding mt-2">
                                <div className="swap-details">
                                    {state.minMaxOutput.title && (
                                        <div className="flex items-center justify-between mb-2 md:mb-2">
                                            <div className="key">
                                                <p className="text">{state.minMaxOutput.title}</p>
                                                <img
                                                    data-tooltip-id="tooltip"
                                                    data-tooltip-content={state.minMaxOutput.operator === '-' ? 'The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will revert.' : 'The maximum amount you may be supposed to send. If the price slips any further, your transaction will revert.'}
                                                    className="icon hand" src="/assets/icons/icon-help-white.svg" alt="" />
                                            </div>
                                            {printMinMaxValues()}
                                        </div>
                                    )}
                                    <div className="flex items-center justify-between mb-2 md:mb-3">
                                        <div className="key">
                                            <p className="text">Slippage Tolerance</p>
                                            <img
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content="Transaction will revert if the price changes unfavourably by more than this percentage"
                                                className="icon hand" src="/assets/icons/icon-help-white.svg" alt="" />
                                        </div>
                                        {/* <h6 className="value text-warning">&#x3C;0.01%</h6> */}
                                        <h6 className="value text-success">{state.slippage}%</h6>
                                    </div>
                                    <div className="flex items-center justify-between mb-2 md:mb-3">
                                        <div className="key">
                                            <p className="text">Liquidity Provider Fee</p>
                                            <img
                                                className="icon hand"
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content="Fee paid to the liquidity provider and platform to process the transaction, This must be paid in token you are swapping"
                                                src="/assets/icons/icon-help-white.svg" alt="" />
                                        </div>
                                        <h6 className="value">{(swap1.value * 0.003).toFixed(6)} {swap1.symbol}</h6>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="key">
                                            <p className="text">Route</p>
                                            <img
                                                data-tooltip-id="tooltip"
                                                data-tooltip-content="The route for your transaction with the best optimised output"
                                                className="icon hand" src="/assets/icons/icon-help-white.svg" alt="" />
                                        </div>
                                        <h6 className="value">{swap1.symbol} &#62; {swap2.symbol}</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="eth-per-ampl mb-3 mt-[16px]">
                        <div className="flex items-center">
                            <button onClick={() => {
                                setState({ ...state, priceSwap: !state.priceSwap });
                            }} className="btn-reload">
                                {auth.info.loader ? <Loader width={24} /> : ""}
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {state.transactionModal && (
                <TransactionSettingsModal
                    setState={setState}
                    state={state}
                    onChange={(t) => {
                        setState({ ...state, slippage: t })
                    }}
                    onClose={() => {
                        setState({ ...state, transactionModal: false });
                        toggleBodyScroll(false)
                    }} />
            )}

            {state.tokenModal && (
                <TokenSelectionModal
                    otherToken={state.swaps[state.tokenModalIndex === 1 ? 0 : 1].symbol?.toLowerCase()}
                    selectedToken={state.swaps[state.tokenModalIndex]}
                    onSelect={(token) => {
                        const otherTokenIndex = state.tokenModalIndex === 1 ? 0 : 1;
                        const combinations = [
                            [token.symbol.toLowerCase(), state.swaps[otherTokenIndex].symbol.toLowerCase()].join('-'),
                            [state.swaps[otherTokenIndex].symbol.toLowerCase(), token.symbol.toLowerCase()].join('-'),
                        ]
                        let poolId = -1;
                        combinations.forEach((x) => {
                            const found = poolStore.pools.poolWithSymbols[x];
                            if (found) {
                                poolId = poolStore.pools.poolWithSymbols[x];
                            }
                        })
                        setCurrentPoolId(poolId);

                        if (combinations[0] !== combinations[1] && state.swaps[otherTokenIndex].symbol !== 'Select Token') {
                            const isAllowed = poolStore.pools.allowedPools.find((x) => {
                                const found = combinations.find((combo) => combo === x.join('-'));
                                return found;
                            });
                            if (!isAllowed) {
                                return;
                            }
                        }


                        token = Object.assign({}, token);
                        token.value = '';
                        token.balance = 0.0;
                        state.swaps[state.tokenModalIndex] = token;
                        state.tokenModal = false;

                        if (state.swaps[state.tokenModalIndex].contractAddress === state.swaps[otherTokenIndex].contractAddress) {
                            state.swaps[otherTokenIndex].name = 'Select Token';
                            state.swaps[otherTokenIndex].symbol = 'Select Token';
                            state.swaps[otherTokenIndex].contractAddress = '';
                            state.swaps[otherTokenIndex].imageUrl = '';
                            state.swaps[otherTokenIndex].balance = 0;
                            state.swaps[otherTokenIndex].value = '';
                        }

                        updateTokenBalances(state);
                        toggleBodyScroll(false);
                    }}
                    onClose={() => { setState({ ...state, tokenModal: false }); toggleBodyScroll(false) }} />
            )}

            {state.confirmModal && (
                <ConfirmSwapModal
                    printMinMaxValues={printMinMaxValues}
                    slippage={state.slippage}
                    minMaxOutput={state.minMaxOutput}
                    swaps={state.swaps}
                    swapPrice1={swapPrice1}
                    swapPrice2={swapPrice2}
                    onCofirm={() => {
                        onSwapConfirm();
                    }}
                    onClose={() => { setState({ ...state, confirmModal: false, successfulModal: false }); toggleBodyScroll(false) }} />
            )}

            {(state.successfulModal) && (
                <SuccessfulModal
                    forType={'swap'}
                    swap1={swap1}
                    swap2={swap2}
                    state={state}
                    onClose={() => {
                        if (state.successfulModal) {
                            state.isDirty = false;
                            state.swaps[0].value = '';
                            state.swaps[1].value = '';
                            setState({ ...state, successfulModal: false, confirmModal: false });
                            toggleBodyScroll(false)
                        }
                    }
                    } />
            )}

            {state.waitingModal && (
                <WaitingModal onClose={() => { setState({ ...state, waitingModal: false }); toggleBodyScroll(false) }} />
            )}

            {state.warningModal && (
                <WarningModal onClose={() => { setState({ ...state, warningModal: false }); toggleBodyScroll(false) }} />
            )}

            {state.loaderModal && (
                <LoaderModal onClose={() => { setState({ ...state, loaderModal: false }); toggleBodyScroll(false) }} />
            )}
        </div>
    )
}