import * as Chart from 'chart.js';

export default class BarWithLine extends Chart.BarController {
    draw (ease) {
      Chart.controllers.BarController.prototype.draw.call(this, ease);
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        let activePoint = this.chart.tooltip._active[0],
          ctx = this.chart.ctx,
          x = activePoint.element.x,
          topY = this.chart.legend?.bottom || 0,
          bottomY = this.chart.chartArea?.bottom || 0,
          y = activePoint.element.y,
          left = this.chart.chartArea?.left || 0,
          right = this.chart.chartArea?.right || 0;
  
        // Set line opts
        ctx.save();
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#fff';
  
        // draw vertical line      
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.stroke();
  
        // Draw horizontal line
        // ctx.beginPath();
        // ctx.moveTo(left, y);
        // ctx.lineTo(right, y);
        // ctx.stroke();
  
        ctx.restore();
      }
    }
}
BarWithLine.id = 'barwithline';
BarWithLine.defaults = Chart.BarController.defaults;
Chart.Chart.register(BarWithLine);
