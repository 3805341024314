import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SwapService from '../../services/SwapService';

const InitialState = {
    tokens: { statusCode: '', message: [], loader: false },
};

export const getTokens = createAsyncThunk(
    'swap/tokens',
    async (name, { rejectWithValue }) => {
        //   const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${name}`)
        //   const data = await response.json()
        //   if (response.status < 200 || response.status >= 300) {
        //     return rejectWithValue(data)
        //   }
        const tokens = await SwapService.getTokens();
        return tokens;
    }
)

export const swapSlice = createSlice({
    name: 'swap',
    initialState: InitialState,
    reducers: {
        setSwapTokenLoader (state, action) {
            state.tokens.loader = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(getTokens.pending, (state, action) => {
        //     state.statusByName[action.meta.arg] = 'pending'
        // })
        builder.addCase(getTokens.fulfilled, (state, action) => {
            // state.statusByName[action.meta.arg] = 'fulfilled'
            // state.dataByName[action.meta.arg] = action.payload
            state.tokens = action.payload;
            state.tokens.loader = false;
        })
        builder.addCase(getTokens.rejected, (state, action) => {
            state.tokens = InitialState.tokens;
            // state.statusByName[action.meta.arg] = 'rejected'
        })
    },
})


export const { setSwapTokenLoader } = swapSlice.actions
export default swapSlice.reducer
