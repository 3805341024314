import { useState } from 'react'
import Slider from 'react-rangeslider'
import { Input } from '../Input';
// import 'react-rangeslider/lib/index.css'


const horizontalLabels = {
    0: '0',
    25: '25',
    50: '50',
    75: '75',
    100: '100'
}

export default function LiquiditySlider(props) {
    const [state, setState] = useState({ value: props.value, init: false });

    const formatkg = value => value;
    const horizontal = 10;
    return (
        <div className="flex items-center relative" style={{ marginBottom: '2.5em' }}>
            <Slider
                min={0}
                max={100}
                labels={horizontalLabels}
                format={formatkg}
                value={state.value}
                className="range appearance-none accent-red-500 w-full cursor-pointer bg-cream-900"
                onChangeComplete={(val) => {
                    props.onComplete && props.onComplete(state.value)
                }}
                onChange={(val) => setState({ ...state, value: val })}
            />
            <Input
                onChange={(e) => {
                    const val = e.target.value;

                    if (val > 100) {
                        props.onComplete && props.onComplete(100)
                        setState({ value: 100 });
                        return;
                    } else if (Number(val) < 0) {
                        props.onComplete && props.onComplete(0)
                        setState({ value: 0 });
                        return;
                    }
                    if (state.init === false) {
                        setState({ ...state, init: true })
                    } else {
                        props.onComplete && props.onComplete(val)
                        setState({ ...state,  value: val  })
                    }
                }} 
                value={state.value} 
                className="range-percentage text-sm ml-2 text-center" type='text' />
            <span style={{ position: 'absolute', color: '#71717a', fontSize: 12, right: 8 }}>%</span>
        </div>
    )
}
