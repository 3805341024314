import React from 'react';
import { WalletToken } from './WalletToken';
import { WalletActivity } from './WalletActivity';
import { WalletPool } from './WalletPool';
import { WalletState } from './Wallet';
import { WalletTabs } from './WalletTabs';
import { AppConstants } from '../../constants';
import { copyToClipboard, isRunningOnProduction, stringToColour } from '../../helper';
import { useNavigate } from 'react-router-dom';
import ExchangeService from '../../services/ExchangeService';
import { Config } from '../../config';
import Loader from '../Loader';
import { useSelector } from 'react-redux';

export const WalletDesktop = (props) => {
    const navigate = useNavigate();
    const auth = useSelector((x) => x.auth);

    const { state, setState, onConnect, logout, balance, isMobile } = props;
    const hiroWalletConnect = ()=>{
        if(state.walletInstall.hiro){
            onConnect(AppConstants.Wallets.Hiro)
           } else {
            window.open(AppConstants.Hiro.browserExtensionLink, '_blank');
           }
    }
    const xverseWalletConnect = ()=>{
       if(state.walletInstall.xverse){
        onConnect(AppConstants.Wallets.xverse)
       } else {
        window.open(AppConstants.xverse.browserExtensionLink, '_blank');
       }
    }
    const assignaWalletConnect = ()=>{
        if(state.walletInstall.asigna){
         onConnect(AppConstants.Wallets.Asigna)
        } else {
         window.open(AppConstants.Asigna.browserExtensionLink, '_blank');
        }
     }
    const address = state?.authData?.stxAddress?.address || '';
    return (
        <div>
            { state.isLoggedIn && (
                <div className="wallet-info">
                    <div className='flex items-center justify-between p-4 pb-0 mb-2 gap-x-5'>
                        <div className="profile">
                            <div className="icon flex direction-row" style={{ 
                                backgroundImage: `linear-gradient(to right, ${stringToColour(address.substr(0, address.length / 2))}, ${stringToColour(address.substr(address.length / 2))})`,
                                borderRadius: '100%', 
                                borderRadius: '100%', overflow: 'hidden' }}>
                                {/* <img src='/assets/icons/icon-profile.svg' alt="" /> */}
                            </div>
                            <div>
                                {/* <h6 className='name'>{ state.authData.stxAddress.address.substr(0, 5) }</h6> */}
                                <div className='flex items-center'>
                                    <p className='text-white text-md'>{ state.authData.stxAddress.address.substr(0, 5) }...{ state.authData.stxAddress.address.substr(state.authData.stxAddress.address.length - 5) }</p>
                                    <button onClick={() => copyToClipboard(state.authData.stxAddress.address) } className='btn-copy-text'>
                                        <img className='ml-1' src='/assets/icons/icon-copy-text.svg' alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="action-btns">
                            <button onClick={() => {
                                logout();
                                setState({ ...state, isLoggedIn: !state.isLoggedIn });
                                window.location.href = '/';
                            }}>
                                <img src='/assets/icons/icon-power.svg' alt="" />
                            </button>
                            <a target='_blank' href={`https://explorer.hiro.so/address/${state?.authData?.stxAddress?.address}?chain=${Config.name}`}>
                                <img src='/assets/icons/icon-cube.svg' alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="balance mb-1 p-4 pb-0">
                        <h6 className='heading'>Wallet Balance</h6>
                        <div className='flex items-end'>
                            <p className='coin-name'>{String(state.balance)} STX</p>
                            <p className='converted-into'>(${ ExchangeService.getTokenDollarPrice(state.balance, 'wstx', true, 3) })</p>
                        </div>
                    </div>
                    <WalletTabs { ...props } />
                    <div>
                        { state.tab === WalletState.Token && <WalletToken state={state}></WalletToken> }
                        {/* { state.tab === WalletState.Activity && <WalletActivity state={state} transactions={state.transactions}></WalletActivity> } */}
                        { state.tab === WalletState.Pool && <WalletPool state={state}></WalletPool> }
                    </div>
                </div>
            )}
            { !state.isLoggedIn && (
                <div className="connect-wallet">
                    <div className='flex items-center justify-between mb-5 gap-x-5'>
                        <h5 className='heading'>{ auth.authLoginLoader ? 'Connecting...' : 'Connect a wallet' }</h5>
                        <div className="action-btns">
                            {/* <button onClick={() => setState({ ...state, isLoggedIn: !state.isLoggedIn })}>
                                <img src='/assets/icons/icon-power.svg' alt="" />
                            </button>
                            <button>
                                <img src='/assets/icons/icon-cube.svg' alt="" />
                            </button> */}
                        </div>
                    </div>
                    { auth.authLoginLoader  ? (
                        <div className='mb-10'>
                            <Loader />
                        </div>
                    ) : (
                        <div className='wallets-list'>
                            <div className='item'>
                                <div onClick={() => hiroWalletConnect()} className='flex items-center cursor-pointer'>
                                    <div className='icon flex items-center justify-center'>
                                        <img src='/assets/icons/leather-logo.svg' alt="" />
                                    </div>
                                    <div className='flex items-center justify-start gap-x-3'>
                                        <p className='name'>Leather Wallet </p>
                                        {!state.walletInstall.hiro ? <p className='not-installed'>Not installed</p> : ''}
                                    </div>
                                </div>
                                {!state.walletInstall.hiro ?  <button className='cursor-pointer btn-wallet-link'>
                                    <img src='/assets/icons/icon-wallet-link.svg' alt="" />
                                </button> : ''}
                            </div>
                            <div className='item'>
                                <div onClick={() => xverseWalletConnect()}  className='flex items-center cursor-pointer'>
                                    <div className='icon flex items-center justify-center'>
                                        <img style={{ width: 24 }} src='/assets/icons/icon-xverse.svg' alt="" />
                                    </div>
                                    <div className='flex items-center justify-start gap-x-3'>
                                        <p className='name'>xverse</p>
                                        {!state.walletInstall.xverse ? <p className='not-installed'>Not installed</p> : ''}
                                    </div>
                                </div>
                                {!state.walletInstall.xverse ?  <button className='cursor-pointer btn-wallet-link'>
                                    <img src='/assets/icons/icon-wallet-link.svg' alt="" />
                                </button> : ''}
                            </div>
                            <div className='item'>
                            <div onClick={() => assignaWalletConnect()} className='flex items-center cursor-pointer'>
                                    <div className='icon'>
                                        <img src='/assets/icons/asigna-logo.png' alt="" />
                                    </div>
                                    <div className='flex items-center justify-start gap-x-3'>
                                        <p className='name'>Asigna</p>
                                        {!state.walletInstall.hiro ? <p className='not-installed'>Not installed</p> : ''}
                                    </div>
                                </div>
                                {!state.walletInstall.asigna ?  <button className='cursor-pointer btn-wallet-link'>
                                    <img src='/assets/icons/icon-wallet-link.svg' alt="" />
                                </button> : ''}
                            </div>   
                            <p className='t-and-c-text p-2 mt-1 ml-1'>
                                By connecting a wallet, you agree to Velar’s <a href='#'>Terms and conditions and Privacy policy</a>
                            </p>
                        </div>
                    )}
                </div>
            ) }
        </div>
    )
}
