import Breadcrumbs from "../components/Breadcrumbs";
import StakeUnstakeProcess from "../components/StakeUnstakeProcess";

export const Stake = (props) => {
    
    return (
        <div className="stake-main mt-12">
            {/* <div className="global-page-header v1"> */}
                {/* <h2 className="title">Staking</h2>
                <h6 className="desc velar-color">Provide liquidity and earn fees.</h6> */}
                {/* <form>
                    <input type="search" className="header-searchbar" placeholder="Search token, token pairs etc.." />
                </form> */}
            {/* </div> */}
            <StakeUnstakeProcess />
        </div>
    )
}