import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthService from '../../services/AuthService';
import { getRandomColor } from '../../helper';
import { cloneDeep } from 'lodash';

export const authInitialState = {
    authLoginLoader: false,
    isLoggedIn: false,
    authData: undefined,
    coins: [],
    coinLoader: false,
    info: {  tokens: [], loader: false, }
};

export const getAccountTokens = createAsyncThunk(
    'auth/tokens',
    async (name, x) => {
        if (window.isWalletBalanceLoading) { return { tokens: [], loader: true }; }
        const state = x.getState();
        if (state.auth.isLoggedIn) {
            const address = state.auth?.authData?.stxAddress?.address || '';
            if (address) {
                window.isWalletBalanceLoading = true;
                let tokens = await AuthService.getAccountInfoByAddress(address);
                window.isWalletBalanceLoading = false;
                tokens = cloneDeep(tokens);
                tokens.tokens = tokens.tokens.map((x) => { 

                    x.token = true; 
                    return x; 
                });
                return tokens;
            }
        }
        return [];
    }
)


export const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        setTokenLoader (state, action) {
            state.info.loader = action.payload;
        },
        setCoinLoader (state, action) {
            state.coinLoader = action.payload;
        },
        setAuthLoginLoader (state, action) {
            state.authLoginLoader = action.payload;
        },
        setCoins (state, action) {
            state.coins = action.payload;
            state.coinLoader = false;
        },
        updateAuth: (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.authData = action.payload.authData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccountTokens.fulfilled, (state, action) => {
            if (Array.isArray(action?.payload?.tokens)) {
                state.info = action.payload;
            }
            state.info.loader = false;
            state.coinLoader = false;
        })
        builder.addCase(getAccountTokens.rejected, (state, action) => {
            state.info = authInitialState.info;
            state.info.loader = false;
        })
    },
})

export const { setAuthLoginLoader, updateAuth, setTokenLoader, setCoins, setCoinLoader } = authSlice.actions
export default authSlice.reducer
