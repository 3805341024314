export const SWAP_INITIAL_STATE = {
    reloadBalance: false,
    amountError: false,
    transactionUrl: 'https://explorer.hiro.so/',
    slippage: 4,
    priceSwap: true,
    isDirty: false,
    transactionModal: false,
    tokenModal: false,
    tokenModalIndex: 0,
    confirmModal: false,
    successfulModal: false,
    waitingModal: false,
    loaderModal: false,
    warningModal: false,
    init: false,
    minMaxOutput: {
        title: '',
        value: '0',
        symbol: '',
        operator: '',
        exact_value: 0
    },
    swaps: [
        {
            imageUrl: "/placeholder.jpg",
            value: '',
            balance: 0.0,
            name: '',
            symbol: '',
            price: 0,
            contractAddress: '',
            loader: false,
            autoFilled: false,
            calc_val: 1
        },
        {
            imageUrl: "/placeholder.jpg",
            value: '',
            price: 0,
            balance: 0.0,
            name: '',
            symbol: '',
            contractAddress: '',
            loader: false,
            autoFilled: false,
            calc_val: 1
        },
    ]
}