import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { commarize } from '../helper';
import Loader from './Loader';

export default function Summary(props) {
    const pool = useSelector((x) => x.pool);
    const stats = pool.stats[props.poolId] || { data: {} };

    let summary = [
        { 
            name: "TVL",
            value: '$' + commarize(stats.data.tvl?.value), 
            unit: stats.data.tvl?.priceChange24h ? stats.data.tvl?.priceChange24h.toFixed(6) : '-', 
            showPriceChange: true,
            isFine: stats.data.tvl?.priceChange24h > 0,
        },
        { 
            name: "Volume (24h)",
            value: '$' + commarize(stats.data.volume?.value, 6),  
            unit: stats.data.volume?.priceChange24h ? stats.data.volume?.priceChange24h.toFixed(5) : '-', 
            showPriceChange: true,
            isFine: stats.data.volume?.priceChange24h > 0,
        },
        { 
            name: "Fees (24h)",
            value: '$' + commarize(stats.data.fees?.value, 6), 
            unit: stats.data.fees?.priceChange24h ? stats.data.fees?.priceChange24h.toFixed(5) : '-', 
            showPriceChange: true,
            isFine: stats.data.fees?.priceChange24h > 0,
        },
        { 
            name: "Transactions (24h)",
            value: stats.data.transaction?.value, 
            unit: stats.data.transaction?.priceChange24h ? stats.data.transaction?.priceChange24h.toFixed(2) : '-', 
            showPriceChange: true,
            isFine: stats.data.transaction?.priceChange24h > 0,
        },
        // { 
        //     name: "Price",
        //     value: Number(stats.data.price?.value).toFixed(6), 
        //     unit: stats.data.price?.priceChange24h,
        //     showPriceChange: true,
        //     isFine: stats.data.price?.priceChange24h > 0,
        // },
        { 
            name: "APR",
            value: Number(stats.data.apr?.value).toFixed(6), 
            unit: stats.data.apr?.priceChange24h ? stats.data.apr?.priceChange24h.toFixed(6) : '-', 
            showPriceChange: true,
            showPercentageSign: true,
            isFine: stats.data.apr?.priceChange24h > 0,
        },
    ]
    
    return (
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-3 mb-5">
            {summary.map((item, index) => (
                    <Link to="#" className="summary-widget">
                        {pool.statsLoader ? (
                            <Loader />
                        ) : (
                            <>
                                <p className='key'>{item.name}</p>
                                {/* <h4 className="value">--</h4> */}
                                <h4 className="value">{item.value} { item.showPercentageSign ? '%' : '' }</h4>
                                { (item.showPriceChange && item.unit) && (
                                    // <h6 className={(item.isFine ? 'positive ' : 'positive ') +  `percentage`}>--</h6>
                                    <h6 className={(item.isFine ? 'positive ' : 'negative ') +  `percentage`}>{ item.isFine ? '+' : '' }{ item.unit }%</h6>
                                ) }
                            </>
                        )}
                    </Link>            
            ))}
        </div>
    )
}