import axios from 'axios';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';
import { AppConstants } from '../constants';
import { Config } from '../config';

export const connectWithXVerse = async () => {
    return new Promise (async (resolve, reject) => {
        const options = {
            payload: {
                purposes: ['ordinals', 'payment'],
                message: 'Address for receiving Ordinals and payments',
                network: {
                    type: 'Testnet'
                },
            },
            onFinish: (response) => { resolve(response) },
            onCancel: () => { reject() },
        }
        try  {
            // const authenticate = async () => {
            //     await microStacksclient.authenticate({
            //       onFinish: payload => {
            //       },
            //     });

            //     console.log('a');
            // };
            // await authenticate();
        } catch (error) {
            console.log(error);
            reject(error);
        }
    })
}

export const connectWithHiro = async () => {
    return new Promise (async (resolve, reject) => {
        const appConfig = new AppConfig(['store_write', 'publish_data']);
        const userSession = new UserSession({ appConfig });
        showConnect({
            userSession,
            appDetails: {
              name: AppConstants.App.Name,
              icon: AppConstants.App.Icon,
            },
            onFinish: () => {
             resolve(userSession);
            },
            onCancel: () => {
             reject();
            },
        });
    })
}

export const getWalletProviders = (key = AppConstants.Wallets.Hiro) => {
    const providers = {
        [AppConstants.Wallets.Hiro]: window.LeatherProvider || window?.StacksProvider,
        [AppConstants.Wallets.XVerse]: window?.XverseProviders?.StacksProvider,
        [AppConstants.Wallets.Asigna]: window?.AsignaProvider
    }

    return providers[key];
}

export const getHiroOptions = () => {
    return {
        redirectTo: '',
        manifestPath: '',
        authOrigin: undefined,
        sendToSignIn: false,
        appDetails: {
            name: AppConstants.App.Name,
            icon: AppConstants.App.Icon
        },
    }
}

export const mapViralWalletState = (payload, walletId = AppConstants.Wallets.Hiro) => {
    const date = new Date();
    const { authResponsePayload } = payload;
    if (walletId === AppConstants.Wallets.Hiro || walletId === AppConstants.Wallets.XVerse || walletId === AppConstants.Wallets.Asigna) {
        const currentEnvAddress = { 
            testnet: authResponsePayload.profile.stxAddress.testnet,
            mainnet: authResponsePayload.profile.stxAddress.mainnet
        }
        return {
            wallet: walletId,
            expiry: date.getTime(),
            btcAddress: {
                address: authResponsePayload?.profile?.btcAddress?.p2tr?.mainnet || '',
                publicKey: authResponsePayload?.profile?.btcPublicKey?.p2tr || '',
            },
            stxAddress: {
                address:  currentEnvAddress[Config.name],
                publicKey: authResponsePayload.public_keys[0],
                publicAddressMainnet: authResponsePayload.profile.stxAddress.mainnet,
                publicAddressTestnet: authResponsePayload.profile.stxAddress.testnet,
            }
        };
    }
    return false;
}