import http from '../utils/axiosProvider';

class ExchangeService {
    getTokenDollarPrice (balance = 0, symbol = '', fixedDecimal = true, decimals = 3) {
        balance = Number(balance);
        const store = window.velarStore;
        const state = store.getState();
        const tokens = state.swap?.tokens?.message || [];
        if (!symbol || !balance) return 0;
        const found = tokens.find((x) => x?.symbol?.toLowerCase() === symbol?.toLowerCase() || x?.vsymbol?.toLowerCase() === symbol?.toLowerCase());
        if (found) {
            const price =  balance * (found.price || 0);
            return fixedDecimal ? price.toFixed(decimals) : price;
        }
        return 0;
    }   
}

export default (new ExchangeService());