import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Loader';
import ExchangeService from '../../services/ExchangeService';
import { getTokenBasedExcatValue } from '../../helper';
import { Link } from 'react-router-dom';

export const WalletPool = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);
    const pool = useSelector((x) => x.pool);
    const { state } = props;

    const pools = pool.pools?.message || [];
    const tokens = auth.info?.tokens || [];

    return (
        <div className='sidebar-tab-content pool-content' style={{ maxHeight: state.isMobile ? 350 : 'auto' }}>
            {pool.poolsLoader && (
                <div className="flex mt-4 items-center justify-center">
                    <Loader width={35} />
                </div>
            )}
            {pools.map((item, index) => {
                const token = tokens.find((x) => x.contract === pool.lpTokenContractAddress);
                const totalReceived = Number(item?.balance || 0);
                const totalSupply = Number(item?.totalSupply || 1);
                const reserve0 = item?.blockchain?.my?.amt0 || 0;
                const reserve1 = item?.blockchain?.my?.amt1 || 0;
                const lpTokenValue = item?.blockchain?.my?.balance || 0;
                let lpTokenBalance = Number(lpTokenValue);
                const percent = (lpTokenValue / totalSupply) * 100;
                let balance0 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve0, item.token0Symbol), item.token0Symbol);
                let balance1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve1, item.token1Symbol), item.token1Symbol);
                let dollarBalance = Number(balance0) + Number(balance1);
  

                return (
                    <div key={index} className='item items-center'>
                        <div className='left'>
                            <Link to={`/pool/${item.lpTokenSymbol}`} className="conversion">
                                <div className="icons">
                                    <img
                                        className="z-6"
                                        src={item.token0ImageUrl}
                                        alt="coin two" />
                                    <img
                                        className="z-7"
                                        src={item.token1ImageUrl}
                                        alt="coin one" />
                                </div>
                            </Link>
                            <div>
                                <p className='text-upr'>{ item.name }</p>
                                <p className='text-lwr color-velar'>{ percent.toFixed(2) } % of pool</p>
                            </div>
                        </div>
                        <div className='right'>
                            <p className='text-lwr'>${ dollarBalance.toFixed(3) }</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}