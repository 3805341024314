import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { filterDecimals, removeLeadingZeros } from "../helper";

export const TransactionSettingsModal = (props) => {
    const { state } = props;
    const ref = useRef(null);
    const { onClose } = props;
    const tolerances = [
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 }
    ]

    const myClose = (e) => {

        if (ref.current) {
            ref.current.classList.remove('opacity-100');
        }
        setTimeout(() => {
            onClose && onClose(e)
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    return createPortal(
        <div ref={ref} id="transactionSettingsModal" tabIndex="-1" aria-hidden="true" className="transition-all duration-300 opacity-0 fixed h-screen flex items-center top-0 left-0 right-0 z-[901] w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content max-w-[400px] flex justify-center flex-col m-auto">
                <div className="p-3">
                    <div className="modal-header">
                        <h5 className="modal-title">Transaction Setting</h5>
                        <button onClick={(e) => myClose(e)} type="button" className="btn-close" data-modal-hide="transactionSettingsModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>                
                    <p className="mb-2" style={{ color: '#F5F7FA5C' }}>Set Slippage</p>
                    <div className="flex justify-center flex-row mb-3 m-auto">
                        <div className="tolerance-selection">
                            { tolerances.map((t, i) => {
                                return <div onClick={() => {
                                        props.onChange(t.value) 
                                    }} className="item" key={i}>
                                    <input checked={t.value == props.state.slippage} type="radio" id={"tolerance-item" + i} name="select-tolerance" />
                                    <label className="item" htmlFor={"tolerance-item" + i}>
                                        {t.label}%
                                    </label>
                                </div>
                            }) }
                        </div>
                        <div className="hidden md:block">
                            <div className="custom-percentage">
                                {/* <p className="text">Enter a custom percentage</p> */}
                                <input value={props.state.slippage} onChange={(e) => {
                                    const removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, "");
                                    if (!(/^[0-9.]*$/.test(e.target.value))) {
                                        return;
                                    }
                                    const removeExtraDot = num => {
                                        let nums = num.split('.');
                                        num = nums[0];
                                        if (nums.length > 1) {
                                            num += '.' + nums[1];
                                        }
                                        return filterDecimals(num.toString().replace(/\.+/g, "."), '', 3);
                                    };

                                    e.target.value = removeNonNumeric(removeExtraDot(e.target.value)); 
                                    e.target.value = removeLeadingZeros(e.target.value);

                                    let val = e.target.value;
                                    if (Number(val) > 10) {
                                        props.onChange(10);
                                    } else {
                                        props.onChange(val)
                                    }
                                }} type="text" className="field noarrow" placeholder="00" />
                            </div>
                        </div>
                    </div>
                    <div className="block md:hidden">
                        <div className="custom-percentage block md:hidden w-100">
                                {/* <p className="text">Enter a custom percentage</p> */}
                                <input value={props.state.slippage} onChange={(e) => {
                                    const removeNonNumeric = num => num.toString().replace(/[^0-9.]/g, "");
                                    if (!(/^[0-9.]*$/.test(e.target.value))) {
                                        return;
                                    }
                                    const removeExtraDot = num => {
                                        let nums = num.split('.');
                                        num = nums[0];
                                        if (nums.length > 1) {
                                            num += '.' + nums[1];
                                        }
                                        return filterDecimals(num.toString().replace(/\.+/g, "."), '', 3);
                                    };

                                    e.target.value = removeNonNumeric(removeExtraDot(e.target.value)); 
                                    e.target.value = removeLeadingZeros(e.target.value);

                                    let val = e.target.value;
                                    if (Number(val) > 10) {
                                        props.onChange(10);
                                    } else {
                                        props.onChange(val)
                                    }
                                }} type="text" style={{ width: '100%', marginLeft: 0 }} className="field noarrow" placeholder="00" />
                        </div>
                    </div>
                </div>
                <div className="slippage-tolerance p-5"  style={{ backgroundColor: '#1A100A' }}>
                    <p className="text">
                        <span>Slippage tolerance</span>
                        <img 
                            data-tooltip-id="tooltip" 
                            data-tooltip-content="Transaction will revert if the price changes unfavourably by more than this percentage" 
                            className="icon mt-1 ml-3 hand" src="/assets/icons/icon-help-warning.svg" alt="" />
                    </p>
                    <h6 className="value">{ props.state.slippage } %</h6>
                </div>
            </div>
        </div>
    , document.body)
}