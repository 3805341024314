import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import swapSlice from './slices/swapSlice'
import poolSlice from './slices/poolSlice'
import marketSlice from './slices/marketSlice'
import chainSlice from './slices/chainSlice'
import appSlice from './slices/appSlice'
import farmSlice from './slices/farmSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    swap: swapSlice,
    market: marketSlice,
    pool: poolSlice,
    chain: chainSlice,
    app: appSlice,
    farm: farmSlice,
  }
})
window.velarStore = store;