import { Chart as ChartJs, LineProps, Filler, CategoryScale, Tooltip, LinearScale, PointElement, LineElement } from 'chart.js';
import React from "react";
import { Line, Chart } from "react-chartjs-2";
import { commarize } from '../../helper';
ChartJs.register(CategoryScale, LinearScale, Filler, Tooltip, PointElement, LineElement);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.background = '#F8931F';
      // tr.style.borderRadius = '5px';
      // tr.style.borderWidth = '5px';

      const th = document.createElement('th');
      // th.style.borderWidth = '5px';
      th.style.borderRadius = '15px';
      th.style.padding = '5px';
      th.style.fontSize = '10pt';
      th.style.fontWeight = '400';
      th.style.color = '#000';
      th.style.paddingLeft = '10px';
      th.style.paddingRight = '10px';

      const text = document.createTextNode(tooltip.dataPoints[0].raw);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      // const span = document.createElement('span');
      // span.style.background = colors.backgroundColor;
      // span.style.borderColor = colors.borderColor;
      // span.style.borderWidth = '2px';
      // span.style.marginRight = '10px';
      // span.style.height = '10px';
      // span.style.width = '10px';
      // span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      // tr.style.backgroundColor = 'red';
      // tr.style.borderWidth = '5px';

      const td = document.createElement('td');
      // td.style.borderWidth = '5px';
      // tr.style.backgroundColor = 'red';

      const text = document.createTextNode(body);

      // td.appendChild(span);
      // td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + (tooltip.caretY - 55) + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};


export const FarmMyRewardGraph = (props) => {
  const labels = props.labels || [];
  let records = props.records || [];
  let blocks = props.blocks || [];
  const dollar = props.dollar === true ? '$' : '';
  const maxVal = Math.max(...records);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
        position: 'nearest',
        external: externalTooltipHandler
      }
    },
    hover: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        display: true,
        border: {
          display: true
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          color: '#1a1411',
          drawTicks: true,
        },
        ticks: {
          display: true
        },
      },
      y: {
        display: true,
        ticks: {
          display: true,
          // stepSize: 1,
          // callback: function (value, index, values) {
          //   return (index) + '%';
          // },
          // format: {
          //   style: 'percent'
          // }
        },
      },
    }
  };

  const data = {
    labels: blocks,
    datasets: [
      {
        fill: false,
        backgroundColor: '#f19c39',
        lineTension: 0.01,
        borderColor: "#F8931F",
        borderDashOffset: 0.0,
        pointBorderColor: "#F8931F",
        pointBackgroundColor: "#F8931F",
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        borderWidth: 2,
        pointHoverBackgroundColor: "#F8931F",
        pointHoverBorderColor: "#F8931F",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: records
      }
    ]
  };

  return (
    <div className='h-[350px] mb-2 mt-2'>
      <Chart type='linewithline-farm' data={data} options={options} />
    </div>
  );
}