import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PoolService from '../../services/PoolService';
import FarmService from '../../services/FarmService';

export const totalStakeGraphInitalState = {
    statusCode: 200,
    interval: "month",
    type: "total staked",
    data: [], 
    timestamp: new Date().getTime() 
}

export const myRewardsGraphInitalState = {
    statusCode: 200,
    interval: "month",
    type: "total staked",
    data: [], 
    timestamp: new Date().getTime() 
}

const InitialState = {
    totalStakeGraphLoader: false,
    totalStakeGraph: {
        1: totalStakeGraphInitalState,
    },
    myRewardsGraphLoader: false,
    myRewardsGraph: {
        1: myRewardsGraphInitalState,
    }
};

export const getTotalStakeGraph = createAsyncThunk(
    'farm-total-stake/graph',
    async (params, store) => {
        const graph = await FarmService.getTotalStakeGraphValues(params.poolId, params.interval);
        graph.id = params.poolId;
        return graph;
    }
)


export const getMyRewardsGraph = createAsyncThunk(
    'farm-my-rewards/graph',
    async (params, store) => {
        const graph = await FarmService.getMyRewardsGraphValues(params.poolId, params.interval);
        graph.id = params.poolId;
        return graph;
    }
)


export const farmSlice = createSlice({
    name: 'farm',
    initialState: InitialState,
    reducers: {
        setTotalStakeGraphEmpty (state, action) {
            state.totalStakeGraph[action.payload] = totalStakeGraphInitalState
        },
        setTotalStakeGraphLoader (state, action) {
            state.totalStakeGraphLoader = action.payload;
        },
        setMyRewardsGraphEmpty (state, action) {
            state.myRewardsGraph[action.payload] = myRewardsGraphInitalState
        },
        setMyRewardsGraphLoader (state, action) {
            state.myRewardsGraphLoader = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTotalStakeGraph.fulfilled, (state, action) => {
            state.totalStakeGraphLoader = false;
            state.totalStakeGraph[action.payload.id] = action.payload;
        })
        builder.addCase(getTotalStakeGraph.rejected, (state, action) => {
            state.totalStakeGraphLoader = false;
            state.totalStakeGraph = totalStakeGraphInitalState;
        })

        builder.addCase(getMyRewardsGraph.fulfilled, (state, action) => {
            state.myRewardsGraphLoader = false;
            state.myRewardsGraph[action.payload.id] = action.payload;
        })
        builder.addCase(getMyRewardsGraph.rejected, (state, action) => {
            state.myRewardsGraphLoader = false;
            state.myRewardsGraph = myRewardsGraphInitalState;
        })
    },
})

export const { setTotalStakeGraphLoader, setTotalStakeGraphEmpty, setMyRewardsGraphEmpty, setMyRewardsGraphLoader } = farmSlice.actions
export default farmSlice.reducer
