import { BASE_ADDRESS, Config } from '../config';
import { buildPostConditions, getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, contractPrincipalCV, tupleCV, cvToHex, cvToJSON, hexToCV, principalCV, listCV, PostConditionMode, FungibleConditionCode } from '@stacks/transactions';
import { ContractService } from './ContractService';
import { store } from '../store';
import { AppConstants } from '../constants';

export class FarmDistributorContractService {
    constructor(contractName) {
        this.contractName = contractName;
        const contractService = new ContractService(
          getPureAddress(BASE_ADDRESS),
          contractName
        );
        this.contractService = contractService;
    }    
    // constructor() {
    //     const addressInfo = getContractAddressAndName(Config.ContractAddresses.FarmDistributor);
    //     const contractService = new ContractService(addressInfo.address, addressInfo.name);
    //     this.contractService = contractService;
    // }

    async getGenesisBlock() {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('get-genesis-block', {
            sender: address,
            "arguments": []
        });
        return Number(hexToCV(result.data.result).value);
    }

    async getReward(startEpoch) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        try {
            if (auth.isLoggedIn) {
                const account = auth.authData.stxAddress.address;
                const result = await this.contractService.callReadOnly('get-reward', {
                    sender: account,
                    "arguments": [
                        cvToHex(principalCV(account)),
                        cvToHex(uintCV(startEpoch)),
                    ]
                });
                const result1 = hexToCV(result.data.result);
                return result1;
            }
            return false;
        } catch (error) {
            return {
                value: {
                    data: {
                        amt: {
                            value: 0
                        },
                        share: {
                            data: {
                                staked: {
                                    value: 0,                                
                                },
                                total: {
                                    value: 0
                                }
                            }
                        }
                    },
                }
            }
        }
    }

    async getRewards(epoch) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        if (auth.isLoggedIn) {
            const account = auth.authData.stxAddress.address;
            const result = await this.contractService.callReadOnly('get-rewards', {
                sender: account,
                "arguments": [
                    cvToHex(principalCV(account)),
                    cvToHex(uintCV(epoch)),
                ]
            });
            return hexToCV(result.data.result);
        }

        return false;
    }

    async distributeBlocks(blocks = [], blockInfo) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const token1 = getContractAddressAndName(Config.ContractAddresses.Velar);
        const transactionPayload = {};
        if (auth.isLoggedIn) {
            const cvBlocks = blocks.map((x) => uintCV(x));
            const account = auth.authData.stxAddress.address;
            
            const postConditionMeta = [{
                account: token1.address,
                type: AppConstants.PostConditionType.ContractFungible,
                code: FungibleConditionCode.Equal,
                amount: blockInfo.revAmount,
                assetInfo: {
                  address: token1.address,
                  contractName: token1.name,
                  name: "velar",
                },
                ownerContract: this.contractName
            }];

            const postconditions = buildPostConditions(postConditionMeta);
            const result = await this.contractService.callPublicFunction(
                "distribute-epochs",
                [
                  principalCV(account),
                  contractPrincipalCV(token1.address, token1.name),
                  listCV(cvBlocks)
                ],
                postconditions,
                transactionPayload
            );

            return result;
        }
    }
}

export default (new FarmDistributorContractService('wstx-velar-farming-distributor'));