import { Link, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { cleanNumberByKeyCodeInput, filterDecimals, getTokenBasedExcatValue, ifNanGiveZero, isCleanNumberInput, toggleBodyScroll, velarToast } from "../helper";
import { Config } from '../config';
import StakeContractService from '../services/StakeContractService';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountTokens, setTokenLoader } from '../store/slices/authSlice';
import { getTokens } from '../store/slices/swapSlice';
import { toast } from 'react-toastify';
import { useConnect } from '../hooks/useConnect';
import { LayoutContext } from '../contexts/LayoutContext';
import { getPools, setPoolLoader } from '../store/slices/poolSlice';
import { Input } from './Input';
import { SuccessfulModal } from '../modals/SuccessfulModal';
import Loader from './Loader';
import { FarmCoreContractService as FarmCoreContract } from '../services/FarmCoreContractService';
import { FarmMyRewards } from './FarmMyRewards';

const Tabs = {
    Stack: 'stack',
    UnStake: 'unstack',
    MyRewards: 'my-rewards'
}
export default function FarmStakeUnstakeProcess(props) {
    const settings = props.settings;
    const dispatch = useDispatch();
    const params = useParams();
    const swap = useSelector((x) => x.swap);
    const auth = useSelector((x) => x.auth);
    const [state, setState] = useState({ transactionUrl: '', lpTokenAddress: '', txMessage: '', successfulModal: false, balance: 0, totalStake: 0, str_balance: '', amount: 0, debalance: 0, deamount: 0, balance: 0,  stakingDetails: false, transactionModal: false, loader: false });
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [ tab, setTab ] = useState(Tabs.Stack);
    const FarmCoreContractService = new FarmCoreContract(settings.poolInfo.contracts.farm['farming-core']);
    const epoch = props.epoch || {  };

    useEffect(() => {
        (async () => {
            if (Array.isArray(auth.info.tokens)) {
                setState({ ...state, loader: true })
                const lpTokenAddress = settings.poolInfo.lpTokenContractAddress;
                const token = auth.info.tokens.find((x) => x.contract === lpTokenAddress);
                if (token) {
                    const userShare = await FarmCoreContractService.getUserSahre();
                    const totalStake = await FarmCoreContractService.getTotalStake();
    
                    setState({ 
                        ...state, 
                        lpTokenAddress: lpTokenAddress,
                        totalStake: Number(totalStake),
                        debalance: Number(userShare),
                        balance: token?.balance, 
                        loader: false 
                    })
                }
            }
        })()
    }, [auth.info?.tokens?.length, settings.poolInfo])


    const aproveStack = async () => {
        try {
            const lpTokenAddress = state.lpTokenAddress;
            const transactionPayload = {};
            transactionPayload.tokenSymbol = settings?.poolInfo?.symbol;
            transactionPayload.tokenContractAddress = lpTokenAddress;
            transactionPayload.tokenQuantity = String(state.amount);

            const result = await FarmCoreContractService.stack(state.amount, lpTokenAddress, transactionPayload, settings?.poolInfo?.symbol);
            let message = `Staking ${state.amount} LP tokens in ${settings?.poolInfo?.name} farm` //Staking ${state.amount} ` + ;
            velarToast(<div>
                <div className='p-3 pb-0'>
                    <div className="title">Staking {state.amount} LP tokens in { settings?.poolInfo?.name } farming pool</div>
                    <div className="timestamp">Less than 25 sec ago</div>
                </div>
                <a className="link p-4 flex justify-between" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                    View on explorer
                    <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
                </a>
            </div>);

            setTimeout(() => {
                setTab(Tabs.Stack);
                setState({ ...state, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, txMessage: message, successfulModal: true, amount: 0, deamount: 0 })
            }, 0);
        } catch (error) {
            console.log(error);
            // toast(<div>
            //     <div className="title">Staking some thing went wrong please try again.</div>
            // </div>);
        }
    }

    const aproveUnStack = async () => {
        try {
            const lpTokenAddress = state.lpTokenAddress;
            const transactionPayload = {};
            transactionPayload.tokenSymbol = settings?.poolInfo?.symbol;
            transactionPayload.tokenContractAddress = lpTokenAddress;
            transactionPayload.tokenQuantity = String(state.deamount);

            const result = await FarmCoreContractService.unStack(state.deamount, lpTokenAddress, transactionPayload, settings?.poolInfo?.symbol, settings.poolInfo.contracts.farm['farming-core']);
            // let message = `Unstaking ${state.deamount} ${ settings?.poolInfo?.name }`;
            let message = `Unstaking ${state.deamount} lp token from ${settings?.poolInfo?.name} farm` //Staking ${state.amount} ` + ;

            velarToast(<div>
                <div className='p-3 pb-0'>
                    <div className="title">Unstaking {state.deamount} LP tokens from { settings?.poolInfo?.name } farming pool</div>
                    <div className="timestamp">Less than 25 sec ago</div>
                </div>
                <a className="link p-4 flex justify-between" target="_blank" href={`${Config.url}txid/${result.txId}?chain=${Config.name}`}>
                    View on explorer
                    <img className="icon" src="/assets/icons/toast-link-icon.svg" alt="" />
                </a>
            </div>);

            setTimeout(() => {
                setTab(Tabs.Stack);
                setState({ ...state, transactionUrl: `${Config.url}txid/${result.txId}?chain=${Config.name}`, txMessage: message, successfulModal: true, amount: 0, deamount: 0 });
            }, 0)

        } catch (error) {
            console.log(error);
            // toast(<div>
            //     <div className="title">UnStaking some thing went wrong please try again.</div>
            // </div>);
            // setState({ ...state })
        }
    }


    return (    
        <>
        <div className="stake-box">
            <div className="header">
                <div className="stacking-tabs flex items-center">
                    <button onClick={() => setTab(Tabs.Stack)} className={(tab === Tabs.Stack ? 'active ' : '') + "tab-item" }>Stake</button>
                    <button onClick={() => setTab(Tabs.UnStake)} className={(tab === Tabs.UnStake ? 'active ' : '') +  "tab-item" }>Unstake</button>
                    {/* <button onClick={() => { 
                        if (auth.isLoggedIn) {
                            setTab(Tabs.MyRewards)
                        } else {
                            setLayoutState({
                                showSideBar: !layoutState.showSideBar,
                                showWalletSidebar: !layoutState.showWalletSidebar
                            })
                        }
                    }} className={(tab === Tabs.MyRewards ? 'active ' : '') +  "tab-item" }>My Rewards</button> */}
                </div>
                {/* { tab === Tabs.MyRewards && (
                    <div className='flex justify-between items-center'>
                        { !epoch.loading && (
                            <span className='d-block' style={{ width: 8, height: 8, borderRadius: '100%', backgroundColor: 'greenyellow' }}></span>
                        )}
                        <span className='text-gray relative flex items-center' style={{ marginLeft: 7, fontSize: 14 }}>{ epoch.loading ? <Loader /> : 'Current Epoch ' + epoch.epoch }</span>
                    </div>
                ) } */}
            </div>

            { tab == Tabs.Stack && (
                <div className="body">
                    <form>
                    <div className='flex px-3 py-2 velar-bg stake-token-amount items-center justify-between'>
                            <div className='left color-velar'>
                                <span>{ settings.poolInfo?.name } Amount</span>
                            </div>
                            <div className='right token-icons'>
                                <div className="icons">
                                    <img
                                        className="z-6"
                                        src={settings.poolInfo.token0ImageUrl}
                                        alt="coin two" />
                                    <img
                                        style={{ borderRadius: '100%', border: '1px solid #222' }}
                                        className="z-7"
                                        src={settings.poolInfo.token1ImageUrl}
                                        alt="coin one" />
                                </div>
                            </div>
                        </div>
                        <div className='flex px-3 py-2 velar-bg stake-token-input items-center justify-between'>
                            <div className='left input color-velar'>
                                <Input 
                                    value={state.amount || ''} 
                                    allowDecimal={false}
                                    onChange={(e) => {
                                        if (!isCleanNumberInput(e)) {
                                            setState({ ...state });
                                            return;
                                        }
                                        setState({ ...state, amount: e.target.value })
                                    }} 
                                    type="number" 
                                    className="field" 
                                    placeholder={`0.00`} />
                            </div>
                            <div className='right'>
                                <span className='color-velar'>$0.0</span>
                            </div>
                        </div>
                        <div className='flex p-3 velar-bg stake-token-balance items-center justify-between'>
                            <div className='left input color-velar'>
                               <span>Balance</span>
                            </div>
                            <div className='flex right items-center justify-between gap-2'>
                                <span className='color-velar'>{ state.balance } LP</span>
                                <span onClick={() => setState({ ...state, amount: state.balance })} className='hand color-orange'>Use Max</span>
                            </div>
                        </div>
                    </form>
                    {/* <p className="velar-balance">LP Balance:  {  state.balance }</p> */}
                    <div className="will-recieve mt-3 mb-3">
                        <p className="text color-velar">You will stake</p>
                        <p className="value color-orange">{ state.amount || '0' } { settings?.poolInfo?.name }</p>
                    </div>
                    {/* <div className="staking-details my-3" data-accordion="collapse">
                        <button onClick={() => setState({ ...state, stakingDetails: !state.stakingDetails })}
                        type="button" className="text-pink-100 font-bold text-lg flex items-center justify-between w-full"
                        data-accordion-target="#staking-details" ariaaria-expanded="true" aria-controls="collapsible-details"
                    >
                        <span>Staking details</span>
                        <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.stakingDetails && 'rotate-180')}
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        </button>
                        <div id="staking-details" className={state.stakingDetails ? 'hidden' : ''}>
                        <div className="flex info-no-border items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text color-velar">Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your total staked " + state.debalance.toFixed(0) + " in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value  color-velar">{ state.debalance.toFixed(0) } { settings?.poolInfo?.name }</p>
                        </div>
                        <div className="flex info-no-border items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text  color-velar">Total Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Total " + state.totalStake.toFixed(0) + " staked in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value  color-velar">{ state.totalStake.toFixed(0) } { settings?.poolInfo?.name }</p>
                        </div>
                        <div className="info-no-border flex items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text  color-velar">Pool Share</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your " + (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) + "% share in the staking pool"} 
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value  color-velar">{ (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) }%</p>
                        </div>
                        </div>
                    </div> */}
                    <div>
                    </div>
                    {!auth.isLoggedIn ? (
                        <button onClick={() => {
                            setLayoutState({
                                showSideBar: !layoutState.showSideBar,
                                showWalletSidebar: !layoutState.showWalletSidebar
                            })
                        }} className="btn-warning btn-orange">Connect wallet</button>
                    ) : (
                        <>
                            { Number(state.amount) > Number(state.balance) ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button onClick={(e) => aproveStack()} disabled={(state.amount < 1)} className={((state.amount < 1) ? 'disabled ' : '') + "btn-warning btn-orange"}>Stake</button>
                            ) }
                        </>
                    )}


                    {/* <button className="btn-warning">Approve unstaking</button> */}
                </div>
            )}

            { tab == Tabs.UnStake && (
                <div className="body">
                    <form>
                    <div className='flex px-3 py-2 velar-bg stake-token-amount items-center justify-between'>
                            <div className='left color-velar'>
                                <span>{ settings.poolInfo?.name } Amount</span>
                            </div>
                            <div className='right token-icons'>
                                <div className="icons">
                                    <img
                                        className="z-6"
                                        src={settings.poolInfo.token0ImageUrl}
                                        alt="coin two" />
                                    <img
                                        style={{ borderRadius: '100%', border: '1px solid #222' }}
                                        className="z-7"
                                        src={settings.poolInfo.token1ImageUrl}
                                        alt="coin one" />
                                </div>
                            </div>
                        </div>
                        <div className='flex px-3 py-2 velar-bg stake-token-input items-center justify-between'>
                            <div className='left input color-velar'>
                                <Input 
                                    value={state.deamount || ''} 
                                    step=".01"
                                    onChange={(e) => {
                                        if (!isCleanNumberInput(e)) {
                                            setState({ ...state });
                                            return;
                                        }
                                        setState({ ...state, deamount: e.target.value })
                                    }} 
                                    type="number" className="field" placeholder={`0.00`} />
                            </div>
                            <div className='right'>
                                <span className='color-velar'>$0.0</span>
                            </div>
                        </div>
                        <div className='flex p-3 velar-bg stake-token-balance items-center justify-between'>
                            <div className='left input color-velar'>
                               <span>Balance</span>
                            </div>
                            <div className='flex right items-center justify-between gap-2'>
                                <span className='color-velar'>{ state.debalance } LP</span>
                                <span onClick={() => setState({ ...state, deamount: state.debalance })} className='hand color-orange'>Use Max</span>
                            </div>
                        </div>
                    </form>
                    {/* <p className="velar-balance">Staked amount: { state.debalance }</p> */}
                    <div className="will-recieve mt-3 mb-3">
                        <p className="text color-velar">You will unstake</p>
                        <p className="value color-orange">{ state.deamount || '0' } { settings?.poolInfo?.name }</p>
                    </div>

                    {/* <div className="staking-details my-3" data-accordion="collapse">
                        <button onClick={() => setState({ ...state, stakingDetails: !state.stakingDetails })}
                        type="button" className="text-pink-100 font-bold text-lg flex items-center justify-between w-full"
                        data-accordion-target="#staking-details" ariaaria-expanded="true" aria-controls="collapsible-details">
                        <span>Staking details</span>
                        <svg data-accordion-icon className={"w-6 h-6 shrink-0 " + (!state.stakingDetails ? 'rotate-180' : '')}
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        </button>
                        <div id="staking-details" className={state.stakingDetails ? 'hidden' : ''}>
                        <div className="flex info-no-border items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text color-velar">Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your total staked " + state.debalance + " in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value color-velar">{ state.debalance } { settings?.poolInfo?.name }</p>
                        </div>
                        <div className="flex info-no-border items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text color-velar">Total Staked</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Total " + state.totalStake + " staked in the pool"}
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value color-velar">{ state.totalStake } { settings?.poolInfo?.name }</p>
                        </div>
                        <div className="flex info-no-border items-center justify-between">
                            <div className="key direction-row flex items-center">
                                <p className="text color-velar">Pool Share</p>
                                <img 
                                    data-tooltip-id="tooltip" 
                                    data-tooltip-content={"Your " + (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) + "% share in the staking pool"} 
                                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
                            </div>
                            <p className="value color-velar">{ (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) }%</p>
                        </div>
                        </div>
                    </div> */}

                    {!auth.isLoggedIn ? (
                        <button onClick={() => {
                            setLayoutState({
                                showSideBar: !layoutState.showSideBar,
                                showWalletSidebar: !layoutState.showWalletSidebar
                            })
                        }} className="btn-warning btn-orange">Connect wallet</button>
                    ) : (
                        <>
                            { Number(state.deamount) > Number(state.debalance) ? (
                                <button className="btn-insufficient">Insufficient Balance</button>
                            ) : (
                                <button onClick={(e) => aproveUnStack()} disabled={(state.deamount < 1)} className={((state.deamount < 1) ? 'disabled ' : 'hand ') + "btn-warning btn-orange" }>Remove Stake</button>
                            ) }
                        </>
                    )}

                    {/* <button className="btn-dark">Connect wallet</button> */}
                    {/* <button className="btn-insufficient">Insufficient Balance</button> */}
                    {/* <button className="btn-warning">Approve unstaking</button> */}
                </div>
            )}

            {tab === Tabs.MyRewards && (
                <div>
                    <FarmMyRewards settings={settings} currentEpoch={epoch.epoch} />
                </div>
            )}

            {(state.successfulModal) && (
                <SuccessfulModal
                    forType={'farm-stake'}
                    message={state.txMessage}
                    state={state}
                    onClose={() => {
                        setState({ ...state, transactionUrl: '', successfulModal: false, confirmModal: false });
                        toggleBodyScroll(false)
                    }
                } />
            )}            
        </div>

        <div className="position-card position-card-v2">
        <div className="header">
            <h6 className="heading">My Position</h6>
            <div className='flex items-center justify-between'>
                {/* {(!settings.calcBurn.init && settings.calcBurn.inProcess) && <Loader width={22} />} */}
                {/* <p className="value ml-3">$343</p> */}
            </div>
        </div>
        <div 
            className="flex items-center justify-between ps ps1">
            <div className="coin flex items-center">
                <h6 className="name">Staked</h6>
                <img 
                    data-tooltip-id="tooltip" 
                    data-tooltip-content={"Your total staked " + state.debalance.toFixed(0) + " in the pool"}
                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
            </div>
            <p className="amount">{ ifNanGiveZero(state.debalance).toFixed(0) } { settings?.poolInfo?.name }</p>
        </div>
        <div className="flex items-center justify-between ps ps2">
            <div className="coin flex items-center">
                {/* <img className='w-5 mr-2' src={settings.poolInfo.token1ImageUrl} alt="coin-two" /> */}
                <h6 className="name">Pool Share</h6>
                <img 
                    data-tooltip-id="tooltip" 
                    data-tooltip-content={"Your " + (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) + "% share in the staking pool"} 
                    className="icon hand w-[15px] ml-2" src="/assets/icons/icon-help-white.svg" alt="" />
            </div>
            <p className="amount">{ (ifNanGiveZero(state.debalance / state.totalStake) * 100).toFixed(6) }%</p>
        </div>
        </div>
    </>
    )
}
