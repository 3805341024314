import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppConstants } from "../../constants";
import { getTokenBasedDecimalValue, getTokenBasedExcatValue } from "../../helper";
import ExchangeService from "../../services/ExchangeService";

export default function MyPositionListView(props) {
  const auth = useSelector((x) => x.auth);
  const swap = useSelector((x) => x.swap);
  const { liquidities } = props;

  return (
    <div className="pool-list">
      <div className="overflow-x-auto">
        <table className="pool-table">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Name</th>
              <th>Amount Locked</th>
              <th>Pool Share</th>
              <th>Fees (24h)</th>
              <th>APR</th>
            </tr>
          </thead>
          <tbody>
            {liquidities.map((item, index) => {
              const totalSupply = Number(item?.totalSupply || 1);
              const reserve0 = item?.blockchain?.my?.amt0 || 0;
              const reserve1 = item?.blockchain?.my?.amt1 || 0;
              const lpTokenValue = item?.blockchain?.my?.balance || 0;
              let lpTokenBalance = Number(lpTokenValue);
              const percent = (lpTokenValue / totalSupply) * 100;
              let balance0 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve0, item.token0Symbol), item.token0Symbol);
              let balance1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve1, item.token1Symbol), item.token1Symbol);
              let dollarBalance = (Number(balance0) + Number(balance1)).toFixed(6);

              return (
                <tr key={index}>
                  <td className="text-center">{ index + 1 }</td>
                  <td>
                    <Link
                      to={`/pool/${item.lpTokenSymbol}?tab=ml`}
                      className="conversion"
                    >
                      <div className="icons">
                        <img
                          className="z-6"
                          src={item.token0ImageUrl}
                          alt="coin two"
                        />
                        <img
                          className="z-7"
                          src={item.token1ImageUrl}
                          alt="coin one"
                        />
                      </div>
                      <div className="coins">{item.name}</div>
                      <div className="fee">{percent.toFixed(2)}%</div>
                    </Link>
                  </td>
                  <td>${ dollarBalance }</td>
                  <td>{((lpTokenBalance / totalSupply) * 100).toFixed(2)} %</td>
                  <td>--</td>
                  <td>
                    <div className="flex items-center">
                      {/* <img
                        className="mr-1.5"
                        src="/assets/icons/fire.svg"
                        alt="fire"
                      /> */}
                      <span>--</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
