import { Chart as ChartJs, LineProps, Filler, CategoryScale, Tooltip, LinearScale , PointElement, LineElement } from 'chart.js';
import React, { useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
import { commarize } from '../../helper';
ChartJs.register(CategoryScale, LinearScale, Filler, Tooltip, PointElement, LineElement);


export const VLineChart = (props) => {
  const labels = props.labels || [];
  const records = props.records || [];
  const dollar = props.dollar === true  ? '$' : '';
  let ref = useRef(null);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        position: 'nearest',

        callbacks: {
          usePointStyle: true,
          beforeBody: function (context) {
            if (dollar) {
              return dollar + commarize(context[0].raw);
            } else {
              return context[0].raw;
            }
          },
          label : function (c) {
            return '';
          },
          labelColor: function(context) {
              return {
                  borderColor: 'rgb(0, 0, 255)',
                  backgroundColor: '#aaa',
                  borderWidth: 0,
                  borderDash: [2, 2],
                  borderRadius: 0,
              };
          },
          labelTextColor: function(context) {
              return '#aaa';
          }
        }
      }
    },
    
    scales: {
      x: {
        border: {
          display: false
        },
        ticks: {
          display: false
        },
      },
      y: {
          display: false,
          gridLines: {
              display: true
          },
          ticks: {
              display: true
          },
      },
    }
  };

  let fillColor = '#f19c39';
  if (ref.current?.ctx) {
    let gradient = ref.current?.ctx.createLinearGradient(0, 0, 0, 340);
    gradient.addColorStop(0, 'rgba(248, 147, 31, 1)');   
    gradient.addColorStop(1, 'rgba(248, 147, 31, 0)');
    fillColor = gradient; 

  }

  const data = {
    labels: labels,
    datasets: [
      {
        fill: true,
        backgroundColor: fillColor,
        lineTension: 0.2,
        borderColor: "rgba(248, 147, 31, 1)",
        borderDashOffset: 0.0,
        pointBorderColor: "#F8931F",
        pointBackgroundColor: "rgba(248, 147, 31, 1)",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        borderWidth: 2,
        pointHoverBackgroundColor: "rgba(248, 147, 31, 1)",
        pointHoverBorderColor: "#F8931F",
        pointHoverBorderWidth: 2,
        pointRadius: 3,
        pointHitRadius: 10,
        data: records
      }
    ]
  };


  return (
    <div className='h-[350px]'>
        <Chart ref={ref} type='linewithline' data={data} options={options} />
    </div>
    );
}