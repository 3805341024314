import http from '../utils/axiosProvider';
import VelarService from './VelarService';
import { StacksDevnet } from '@stacks/network'
import { openContractCall } from '@stacks/connect';
import axios from 'axios';


export class MarketService { 
    async getLatestCryptocurrency () {
        return new Promise ((resolve, reject) => {
            resolve([]);
        });
    }
}

export default ( new MarketService() )