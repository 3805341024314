import React from 'react';
import { Link } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMoreTransactions, getTransactions, setNotificationParams, setTransactionLoader } from "../store/slices/chainSlice";
import { LayoutContext } from "../contexts/LayoutContext";
import Loader from "../components/Loader";
import { getContractAddressAndName, getFirstAndLast, getTokenBasedDecimalValue, getTokenBasedExcatValue } from "../helper";
import { isBaseAddress, onTxNavigate, renderLiquidityPollTransaction, renderNotifications, renderSwapTransaction } from "../utils/notification.helper-v2";
import { getTokens } from "../store/slices/swapSlice";
import { AppConstants } from "../constants";
import { Config } from "../config";
import Search from '../components/Liquidity/Search';
import CircleLoader from "../components/CirclLoader";
import ActionSheet from 'actionsheet-react';
import { isMobile } from '@stacks/connect';

const Tabs = {
    Updates: 'pending',
    Swap: 'swap',
    Stake: 'stake',
    Rewards: 'rewards',
    Liquidity: 'pool',
    Farm: 'farm',
    All: '',
}

const Images = {
    ['pending']: '/assets/icons/updates.svg',
    swap: '/assets/icons/swap.svg',
    stake: '/assets/icons/swap.svg',
    pool: '/assets/icons/rewards.svg',
    Rewards: '/assets/icons/rewards.svg',
    farm: '/assets/icons/rewards.svg',
    '': '/assets/icons/double-check.svg',
}

const tabs = [
    { label: 'All', key: '', display: true },
    { label: 'Swap', key: 'swap', display: true },
    // { label: 'Pending', key: 'pending', display: true },
    { label: 'Pool', key: 'pool', display: true },
    { label: 'Stake', key: 'stake', display: true },
    { label: 'Farm', key: 'farm', display: true },
    // { label: 'Rewards', key: 'rewards', display: true },
]

export const NotificationsV2 = (props) => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [tab, setTab] = useState(Tabs.All)
    const [state, setState] = useState({ md: false })
    const [ details, setDetails ] = useState({})
    const [activeOver, setActiveOver] = useState('')
    const ref = useRef(null);
    const chain = useSelector((x) => x.chain);
    const swap = useSelector((x) => x.swap);
    const actionSheetRef = useRef();

    const dispatch = useDispatch();
    const [currentTransactions, setCurrentTransactions] = useState([]);
    const [memePool, setMemePool] = useState([]);
    const transactions = chain.transaction.data.result;
    const transactionLoader = chain.transaction.loader;
    const mempoolTransaction = transactions?.filter((x) => x.memepool && isBaseAddress(x?.contract_call?.contract_id));
    const txMeta = chain?.transaction?.data?.meta?.transactions || { offset: 0, total: 0 };

    // useEffect(() => {
    //     dispatch(setNotificationParams({ type: tab }));
    //     dispatch(setTransactionLoader(true));
    //     dispatch(getTransactions());
    // }, [tab])

    useEffect(() => {
        let txs = (transactions || [])?.filter((x) => {
            if (tab === Tabs.Swap) {
                if (x?.contract_call?.function_name?.indexOf('swap') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if (tab === Tabs.Stake) {
                if (Config.ContractAddresses.Staking === x?.contract_call?.contract_id && x?.contract_call?.function_name?.indexOf('stake') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if (tab === Tabs.Farm) {
                if (x?.contract_call?.contract_id?.indexOf('farming') > -1 && x?.contract_call?.function_name?.indexOf('stake') > -1 && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                } else if (AppConstants.STAKE_REWARDS.includes(x?.contract_call?.function_name) && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else if (tab === Tabs.Updates) {
                if (x?.memepool) {
                    return x;
                }
            } else if (tab === Tabs.Liquidity) {
                if (AppConstants.POOL_FUNCTIONS.includes(x?.contract_call?.function_name) && isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                }
            } else {
                if (isBaseAddress(x?.contract_call?.contract_id)) {
                    return x;
                } else {
                    return null;
                }
            }
        }).filter(Boolean);
        setMemePool(mempoolTransaction || []);
        setCurrentTransactions(txs);

    }, [tab, (transactions || []).length, mempoolTransaction?.length])

    useEffect(() => {
        setLayoutState({ ...layoutState, isNotificationViewed: true });
        dispatch(setTransactionLoader(true));
        dispatch(getTransactions());
        dispatch(getTokens());

        return (() => {
            dispatch(setNotificationParams({ type: 'all' }));
            dispatch(setTransactionLoader(true));
            dispatch(getTransactions());
            dispatch(getTokens());
        })
    }, [])

    const loadMore = () => {
        dispatch(setTransactionLoader(true));
        dispatch(getMoreTransactions(txMeta.offset + 50));
    }

    return (
        <React.Fragment>
            <ActionSheet
                onClose={() => {
                    setDetails({});
                    // setLayoutState({ showSideBar: false, showWalletSidebar: false })
                }}
                ref={actionSheetRef}
                sheetStyle={{
                    backgroundColor: '#0D0805',
                    paddingBottom: 20,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                }}
                bgStyle={{
                    backgroundColor: "rgba(1, 1, 1, 0.8)"
                }}>
                    <div className='wallet-info actionsheet'>
                        <div className='actionsheet-bar'>
                        </div>
                        <div className='flex items-center justify-between mb-1 p-4 pb-0 gap-x-5'>
                            <h2 className='heading text-white'>Transaction details</h2>
                            <div className='hand' onClick={() => actionSheetRef.current.close()}>
                                <img src="/assets/icons/cross.svg" />
                            </div>
                        </div>
                        <div className='content'>
                            <div className='text-center tx-infos mb-4 flex direction-col items-center justify-center' style={{ flexDirection: 'column' }}>
                                {/* <img alt='' className='mb-4' src="/assets/icons/check-circle.svg" /> */}
                                <div className={'mb-4 badge-velar-status-mobile badge-velar-tx-status-bg tx-mobile-icon ' + details?.badge?.className}>
                                    { details?.badge?.className === 'in-progress' ? (
                                        <CircleLoader color={'#563419'} customHeight={0} borderWidth={2} width={30} />
                                    ) : (
                                        <img src={details?.badge?.status_image} />
                                    ) }
                                </div>
                                <h2 className='text-white mb-2'>Transaction Submitted</h2>
                                <h2 className='color-velar'>{ details?.name }</h2>
                            </div>
                            <div className='info p-5'>
                                <div className='flex items-between justify-between p-4'  style={{ marginBottom: 1, borderTopLeftRadius: 5, borderTopRightRadius: 5,  backgroundColor: '#F5F7FA14' }}>
                                    <div className='title color-velar' style={{ fontSize: 14 }}>
                                        Trade Info
                                    </div>
                                    <div className='value text-white' style={{ fontSize: 14 }}>
                                        { details?.desc?.details } 
                                    </div>
                                </div>
                                <div className='flex items-between justify-between p-4'  style={{ marginBottom: 1, backgroundColor: '#F5F7FA14' }}>
                                    <div className='title color-velar' style={{ fontSize: 14 }}>
                                        Time
                                    </div>
                                    <div className='value text-white' style={{ fontSize: 14 }}>
                                        { details?.desc?.time } 
                                    </div>
                                </div>
                                <div className='flex items-between justify-between p-4'  style={{ marginBottom: 1, borderBottomRightRadius: 5, borderBottomLeftRadius: 5,  backgroundColor: '#F5F7FA14' }}>
                                    <div className='title color-velar' style={{ fontSize: 14 }}>
                                        Trnx Address
                                    </div>
                                    <div className='value color-orange' style={{ fontSize: 14 }}>
                                        { details?.desc?.tx }  
                                    </div>
                                </div>
                            </div>
                            <div className='info p-5 pt-1'>
                                <button onClick={() => onTxNavigate(details) } className='btn btn-orange btn-warning' style={{ height: 45 }}>View on explorer</button>
                            </div>
                        </div>
                    </div>
            </ActionSheet>

            <section className="pool-main notifications-main max-w-950">
                <div className="global-page-header">
                    <div className="heading">
                        <h2 className="title">Notifications</h2>
                        <h6 className="desc">Provide liquidity and earn fees</h6>
                    </div>
                    <div className="image hidden md:block">
                        <img style={{ position: 'absolute', top: 0 }} src="/assets/imgs/pool-back.svg" />
                        <img src="/assets/imgs/list-header-bg.svg" />
                    </div>
                </div>

                <div className="list table-velar">
                    <div className='flex items-start lg:items-center justify-between pt-4 pb-4 table-header'>
                        <div className='pool-tabs mb-3 lg:mb-0'>
                            {tabs.map((tabinfo) => {
                                return (
                                    <button key={tabinfo.key} onClick={(e) => setTab(tabinfo.key)} className={"tab-item" + (tab === tabinfo.key ? ' active' : '')}>{tabinfo.label}</button>
                                )
                            })}
                        </div>
                        <div className="view-filters" role="group">
                            {transactionLoader && (
                                <div className="flex mr-3 items-center justify-center">
                                    <Loader width={30} />
                                </div>
                            )}
                            {/* <Loader /> */}
                            {/* <Search data={currentTransactions} /> */}
                        </div>
                    </div>
                    <div className="pool-list">
                        {false ? (
                            <div className='p-10'>
                                <Loader />
                            </div>
                        ) : (currentTransactions || []).length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className='pool-table notification-table'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 50 }} className="text-center">#</th>
                                            <th style={{ width: window.innerWidth < 990 ? '100%' : '35%' }}>Name</th>
                                            <th className='hidden md:table-cell' style={{ width: '35%', textAlign: 'right' }}>Trade info</th>
                                            <th className='hidden md:table-cell' style={{ width: '20%', textAlign: 'right' }}>Time</th>
                                            <th className='hidden md:table-cell' style={{ width: '15%', textAlign: 'center' }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(currentTransactions || []).map((transaction, index) => {
                                        return renderNotifications(transaction, index, ref, 'page', swap.tokens?.message || [])
                                    }).filter((tx) => tx?.name).map((tx, index) => {
                                        if ( tx?.name ) {
                                            return (
                                                <tr className='hand notification-row' onClick={() => {
                                                    if (window.innerWidth < 1025) {
                                                        setDetails(tx);
                                                        actionSheetRef.current.open()
                                                    } else {
                                                        onTxNavigate(tx);
                                                    }
                                                }} key={index}>
                                                    <td style={{ maxWidth: 50 }} className="text-center">{ index + 1 }</td>
                                                    <td style={{ minWidth: 200, maxWidth: 200 }}>
                                                        <div className='flex items-center justify-start gap-2'>
                                                            <div className='md:hidden'>
                                                                <span className={'badge-velar-status-mobile badge-velar-tx-status-bg ' + tx.badge.className}>
                                                                    {/* { tx.desc.tx } */}
                                                                    { tx.badge.className === 'in-progress' ? (
                                                                        <CircleLoader color={'#563419'} customHeight={13} borderWidth={1.3} width={15} />
                                                                    ) : (
                                                                        <img src={tx.badge.status_image} />
                                                                    ) }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span>{ tx.name }</span>
                                                                <span className='inline-block badge-velar-tx ml-1 m-1'>{ tx.desc.tx }</span>
                                                                <span className="hidden navigate-icon-notification ml-2">
                                                                    <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div>                                                   
                                                    </td>
                                                    <td className='hidden md:table-cell' style={{ minWidth: 100, textAlign: 'right'}}>{ tx.desc.details }</td>
                                                    <td className='hidden md:table-cell' style={{ minWidth: 100, textAlign: 'right' }}>{ tx.desc.time }</td>
                                                    <td className='hidden md:table-cell' style={{ minWidth: 100, textAlign: 'center' }}><span className={'badge-velar-tx-status ' + tx.badge.className}>{ tx.badge.status }</span></td>
                                                </tr>
                                            )
                                        }
                                    })}
                                    </tbody>
                                </table>                               
                            </div>
                        ) : (
                            <div className='text-center md:p-12 p-6'>
                                <h2 className='text-white mb-2'>Nothing to report here</h2>
                                <p className='color-velar'>No activity detected on this wallet</p>
                            </div>
                        )}
                    </div>
                    {currentTransactions.length != 0 && (txMeta.total !== 0 && (Number(txMeta?.total) < Number(txMeta?.fetchedTotal))) ? (
                    <div className="flex items-center justify-center mt-2">
                        <button onClick={() => loadMore()} className="btn-loader p-3">
                            { transactionLoader ? (
                                <Loader />
                            ) : (
                                'Load more'
                            ) }
                        </button>
                    </div>
                    ) : '' }
                </div>
            </section>
        </React.Fragment>
    )
}