import { commarize, getRandomColor } from '../helper';
import http, { swapHttp } from '../utils/axiosProvider';

class AuthService {
    async getAccountInfoByAddress (address) {
        const USER_BALANCES_URL = `/extended/v1/address/${address}/balances`
        const TOKEN_META_URL = (tokenAddress) => `/metadata/v1/ft/${tokenAddress}`;
        const balances = await http.get(USER_BALANCES_URL);
        const data = Object.assign({}, balances.data);
        
        data.tokens = [];
        for (let key in data.fungible_tokens) {
            const [contract, name] = key.split('::');
            let metadata = {};
            let b = data?.fungible_tokens[key]?.balance || 0;
            const balance = commarize(Number(Number(b).toFixed(6)));
            data.tokens.push ({
                contract,
                name,
                ...data.fungible_tokens[key],
                str_balance: balance, 
                balance: data.fungible_tokens[key].balance,
                metadata: metadata,
                bgColor: getRandomColor(360),
            })
        }
        return data;
    }

    async saveUserAddresses (payload) {
        const publicAddressMainnet = payload.publicAddressMainnet;
        const publicAddressTestnet = payload.publicAddressTestnet;

        await swapHttp.post('/swap/user', { publicAddressMainnet, publicAddressTestnet });
    }
}

export default (new AuthService());