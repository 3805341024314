import { authenticate, isMobile, openSignatureRequestPopup } from '@stacks/connect';
import { useContext, useEffect, useState } from 'react';
import { ConnectContext } from '../contexts/ConnectContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountTokens, setAuthLoginLoader, setCoinLoader, setCoins, updateAuth } from '../store/slices/authSlice';
import { getHiroOptions, getWalletProviders, mapViralWalletState } from '../utils/walletUtils';
import { AppConstants } from '../constants';
import axiosProvider, { authHttp } from '../utils/axiosProvider';
import { addScript, commarize } from '../helper';
import { setTokenLoader } from '../store/slices/authSlice';
import { LayoutContext } from '../contexts/LayoutContext';
import AuthService from '../services/AuthService';
import VelarService from '../services/VelarService';
import axios from 'axios';
import { toast } from 'react-toastify';

export const useConnect = () => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { isLoggedIn, authData } = useContext(ConnectContext);
    const [ client, setClient ] = useState(undefined);
    const dispatch = useDispatch();
    const auth = useSelector((x) => x.auth);
    const app = useSelector((x) => x.app);

    const login = (walletId = AppConstants.Wallets.Hiro, options = {}) => {
        const authOptions = getHiroOptions();
        // const walletProvider = walletId === AppConstants.Wallets.Hiro ? AppConstants.Hiro.ID : AppConstants.XVerse.ID;
        if (isMobile()) {
            const _options = {
                ...authOptions,
                ...options,
                onFinish: (payload) => {
                    dispatch(setAuthLoginLoader(true))
                    const authData = mapViralWalletState(payload, walletId);
                    const publicAddressMainnet = authData.stxAddress.publicAddressMainnet;
                    const publicAddressTestnet = authData.stxAddress.publicAddressTestnet;
                    authHttp.post('/auth/softlogin', {
                        publicAddressMainnet,
                        publicAddressTestnet,
                        wallet: walletId,
                    }).then((loginRes) => {
                        // const authData = mapViralWalletState(payload, walletId)
                        localStorage.setItem('wallet-state', JSON.stringify({ ...authData }));
                        dispatch(updateAuth({ isLoggedIn: true, authData }));
                        dispatch(setAuthLoginLoader(false))
                    }).catch((error) => {
                        dispatch(setAuthLoginLoader(false))
                    })
                },
                sendToSignIn: true,
            };
            authenticate(_options, getWalletProviders(walletId));
        } else {
            console.log(walletId);
            // const script = document.getElementById(walletProvider).src;
            // addScript(script).then(() => {
                const _options = {
                    ...authOptions,
                    ...options,
                    onFinish: (payload) => {
                        dispatch(setAuthLoginLoader(true))
                        const authData = mapViralWalletState(payload, walletId)
                        const publicAddressMainnet = authData.stxAddress.publicAddressMainnet;
                        const publicAddressTestnet = authData.stxAddress.publicAddressTestnet;
                        authHttp.post('/auth/softlogin', {
                            publicAddressMainnet,
                            publicAddressTestnet,
                            wallet: walletId,
                        }).then((loginRes) => {
                            // const authData = mapViralWalletState(payload, walletId)
                            localStorage.setItem('wallet-state', JSON.stringify({ ...authData }));
                            dispatch(updateAuth({ isLoggedIn: true, authData }));
                            dispatch(setAuthLoginLoader(false))
                        }).catch((error) => {
                            dispatch(setAuthLoginLoader(false))
                        })                    
                    },
                    sendToSignIn: true,
                };
                authenticate(_options, getWalletProviders(walletId));
            // });
        }
    };

    const loginWithSignature = (walletId = AppConstants.Wallets.Hiro, options = {}) => {
        const authOptions = getHiroOptions();
        const _options = {
            ...authOptions,
            ...options,
            onFinish: (payload) => {
                const authData = mapViralWalletState(payload, walletId)
                const publicAddressMainnet = authData.stxAddress.publicAddressMainnet;
                const publicAddressTestnet = authData.stxAddress.publicAddressTestnet;
                authHttp.post('/auth/nonce', {
                    publicAddressMainnet,
                    publicAddressTestnet,
                    wallet: walletId,
                }).then((nonceRes) => {
                    const nonce = nonceRes.data.data.nonce;
                    const message = AppConstants.AUTH_MESSAGE(nonce);
                    openSignatureRequestPopup({
                      message,
                      network: VelarService.getNetwork(),
                      appDetails: VelarService.getAppDetails(),
                      onFinish(data) {
                        authHttp.post('/auth/login', {
                            publicAddressMainnet,
                            publicAddressTestnet,
                            publicKey: data.publicKey,
                            signature: data.signature,
                            wallet: walletId,
                        }).then((loginRes) => {
                            const tokens = loginRes.data.data;
                            const authData = mapViralWalletState(payload, walletId)
                            localStorage.setItem('wallet-state', JSON.stringify({ ...authData, ...tokens }));
                            dispatch(updateAuth({ isLoggedIn: true, authData }));
                        })
                      },
                    });
                })            
            },
            sendToSignIn: true,
        };
        authenticate(_options, getWalletProviders(walletId));
    };

    const logout = ()=>{
        localStorage.clear();
        dispatch(updateAuth({ isLoggedIn: false, authData:undefined }));
    }

    const checkExpiry = ()=>{
        const date = new Date(authData.expiry);
        if (Date.now() >= date) {
            dispatch(updateAuth({ isLoggedIn: false, authData:undefined }));
            return false;
          } else {
            return true;
          }
    }

    const useBalance = () => {
        const [ balance, setBalance ] = useState(0);

        useEffect(() => {
            if (auth.info.loader === false && auth.coinLoader === false) {
                dispatch(setTokenLoader(true));
                dispatch(setCoinLoader(true))
                dispatch(getAccountTokens());
            }
        }, [authData?.stxAddress?.address])

        useEffect(() => {
            if (auth.info.loader === false && auth.info?.stx?.balance) {
                const _balance = auth.info.stx.balance;
                setBalance((Number(_balance) / 1000000).toFixed(2));
                dispatch(setCoins([
                    // {
                    //     coin: true,
                    //     name: 'BTC',
                    //     symbol: 'btc',
                    //     imageUrl: '/assets/icons/icon-bitcoin-yellow.svg',
                    //     balance:  0,
                    //     str_balance: commarize(((Number(_balance)) * 0.000000000025).toFixed(6))
                    // },
                    {
                        coin: true,
                        name: 'STX',
                        symbol: 'wstx',
                        imageUrl: 'https://raw.githubusercontent.com/velar-be/asset-hosting/main/stx.jpg',
                        balance:  Number(_balance) / 1000000,
                        str_balance: commarize((Number(_balance) / 1000000).toFixed(6))
                    }
                ]))
            }
        }, [auth.info?.tokens?.length ,authData?.stxAddress?.address, auth.info?.stx?.balance, app.appFocus])

        return balance;
    }

    const useWalletExtension = () => {
        const [ walletInstall, setWalletInstall ] = useState({ xverse: false, hiro: false, asgina: false });
        useEffect(() => {
            let state = walletInstall;
            if(getWalletProviders(AppConstants.Wallets.Hiro)){
                state.hiro = true;
                setWalletInstall({ ...state });
            }
            if (getWalletProviders(AppConstants.Wallets.XVerse)){
                state.xverse = true;
                setWalletInstall({ ...state });
            }
            if (getWalletProviders(AppConstants.Wallets.Asigna)){
                state.asigna = true;
                setWalletInstall({ ...state });
            }
        }, [window.LeatherProvider, window.BitcoinProvider, window.AsignaProvider])

        return walletInstall;
    }

    return {
        useBalance,
        useWalletExtension,
        isLoggedIn,
        authData,
        login,
        logout,
        checkExpiry
    };
};
