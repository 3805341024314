import http from '../utils/axiosProvider';

class BlockService {
    constructor () {}

    async getCurrentBlock () {
        const blockInfoRes = (await http.get('/extended/v1/block?limit=1')).data;
        return blockInfoRes?.results?.find(Boolean)?.height || false;
    }
}

export default (new BlockService());