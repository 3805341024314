import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import MarketService from '../../services/MarketService';

export const InitialState = {
    cryptocurrency: { loader: false, data: [] }
};

export const getCryptocurrency = createAsyncThunk(
    'market/currencies',
    async (name, x) => {
        const currencies = await MarketService.getLatestCryptocurrency();
        return currencies;
    }
)


const marketSlice = createSlice({
    name: 'market',
    initialState: InitialState,
    reducers: {
        setMarketLoader (state, action) {
            state.cryptocurrency.loader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCryptocurrency.fulfilled, (state, action) => {
            state.cryptocurrency = { loader: false, data: action.payload };
        })
        builder.addCase(getCryptocurrency.rejected, (state, action) => {
            state.cryptocurrency = InitialState.cryptocurrency;
        })
    },
})

export const { setMarketLoader } = marketSlice.actions
export default marketSlice.reducer
