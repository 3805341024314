import { Link } from 'react-router-dom';
import { useState } from "react";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { toggleBodyScroll } from "../helper";
import WaveLoader from './WaveLoader';

export default function Loader(props) {
    const { completed } = props;
    const width = props.width || 100;
    const style = props.style || {};
    const height = width;
    return (
        <WaveLoader style={style} width={width} height={height} />
    )
}



// <div className="lds-ring">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:1.2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>

// <div className="lds-ring" style="--d:2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:3">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>