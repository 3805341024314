import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";

export const WarningModal = (props) => {
    const ref = useRef(null);
    const [ style, setStyle ] = useState({ state: true });
    const [state, setState] = useState({ state: true });
    const { onClose } = props;

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    return createPortal(
        <div ref={ref} id="warningModal" tabIndex="-1" aria-hidden="true" className="swapActionModal transition-all duration-300 opacity-0 fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content flex justify-center flex-col m-auto z-[901] pt-8">
                <div className="text-center mb-6">
                    <img className="w-40 mx-auto" src="/assets/icons/icon-warning-modal.svg" alt="" />
                </div>
                <h4 className="heading">Something went wrong.</h4>
                <p className="desc-sm"><a href="#">Shoot us a DM here</a> if you’re facing issues troubleshooting this.</p>
                <a href="#" className="btn-warning btn-orange flex items-center justify-center mt-6">Try again</a>
            </div>
        </div>
        , document.body)
}