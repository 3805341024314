import React, { useRef, useEffect } from "react";

export function useOutsideAlerter(refs, state, callback = () => {}) {
  useEffect(() => {
    function handleClickOutside(event) {
      const refsGates = [];
      refs.forEach((ref) => {
        refsGates.push(ref.current && !ref.current.contains(event.target))
      })
      if (refsGates.every((x) => x === true)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refs, state]);
}

export function useOutsideAlerterSingle(ref, state, callback = () => {}) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
