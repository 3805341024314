import { isMobile } from "@stacks/connect";
import { Config } from "../config";
import http, { blockWatcher, farmHttp } from "../utils/axiosProvider";
import { store } from "../store";

export class FarmService {
  async saveTransaction (data) {
    const payload = {
      "publicAddress": data.publicAddress,
      "tokenSymbol": data.tokenSymbol,
      "tokenContractAddress": data.tokenContractAddress,
      "tokenQuantity": String(data.tokenQuantity),
      "status": "success",
      "transactionHash": data.transactionHash,
      "txResponse": data.txResponse,
      "walletInUse": data.walletInUse || 'hiro',
      "txType": data.txType,
      "deviceType": isMobile() ? 'mobile' : 'web',
    };
    await farmHttp.post('/farm/transactions', payload);
  }

  async getTotalStakeGraphValues(poolID, interval = 24) {
    const result = await blockWatcher.get(`/farm/${poolID}?interval=${interval}`);
    return result.data;
  }

  async getMyRewardsGraphValues(poolID, interval = 24) {
    const auth = store.getState().auth;
    console.log(auth);
    if (auth.isLoggedIn) {
      const account = auth.authData.stxAddress.address;
      const result = await blockWatcher.get(`/farm/${poolID}/${account}/?interval=${interval}`);
      return result.data;
    }
    throw new Error('Not Logged in')
  }
}

export default new FarmService();
