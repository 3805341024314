import { Link } from 'react-router-dom';
import { useState } from "react";
import { TransactionSettingsModal } from "../modals/TransactionSettingsModal";
import { toggleBodyScroll } from "../helper";

export default function WaveLoader(props) {
    const { completed } = props;
    const width = props.width || 100;
    const style = props.style || {};
    const height = width;
    return (
        <div style={style}>
            { !completed ? (
                <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {/* <span className="dot"></span> */}
                    </div>            
                ) : (
                <div className='lds-done'>
                    A
                    <img src='/assets/imgs/tick.png' />
                </div> 
            ) }
        </div>
    )
}



// <div className="lds-ring">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:1.2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>

// <div className="lds-ring" style="--d:2">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>
// <div className="lds-ring" style="--d:3">
//   <div></div>
//   <div></div>
//   <div></div>
//   <div></div>
// </div>