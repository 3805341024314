import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { ifNanGiveZero } from "../helper";
import ExchangeService from "../services/ExchangeService";

export const AggrementModal = (props) => {
    const ref = useRef(null);
    const [ state, setState ] = useState({ aggrement: false })
    const { onClose } = props;

    const myClose = (e) => {
        localStorage.setItem('velar-aggrement', String(state.aggrement));
        if (ref.current) {
            ref.current.classList.remove('opacity-100');
        }
        setTimeout(() => {
            onClose && onClose(e)
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                // ref.current.classList.remove('opacity-0');
                ref.current.classList.add('opacity-100');
            }
        }, 0)
    }, [])

    return createPortal(
        <div ref={ref} id="confirmSwapModal" tabIndex="-1" aria-hidden="true" className="transition-all duration-300 opacity-0 fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content  flex justify-center flex-col m-auto z-[901]" style={{ maxWidth: 400, backgroundColor: '#151110' }}>
                <div className="modal-header">
                    <h5 className="modal-title flex" style={{ color: '#f9ce9d', fontSize: 16 }}>
                        <img src="/assets/imgs/warning.svg" />
                        <p className="ml-2">Velar Dharma mainnet is Live in Beta.</p>
                    </h5>
                    {/* <button onClick={(e) => myClose(e)} type="button" className="btn-close" data-modal-hide="confirmSwapModal">
                        <svg aria-hidden="true" className="w-5 md:w-6 h-5 md:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button> */}
                </div>
                <div className="swap-container relative text-gray">
                    <div className="img flex justify-center">
                        <img src="/assets/imgs/velar-aggrement.svg" />
                    </div>
                    <p className="mt-5">
                        Velar Dharma Mainnet is in Beta, only use the swap feature for testing purpose, and do not put liquidity as contracts may be redeployed.
                    </p>
                    <div className="mt-5 text-gray flex space-between">
                        <div className="checkbox-container mt-1">
                            <input checked={state.aggrement} className="checkbox inline" type="checkbox" />
                            <span onClick={(e) => { 
                                if (state.aggrement) {
                                    setState({ aggrement: false }) 
                                } else {
                                    setState({ aggrement: true }) 
                                }
                            }} className="checkmark"></span>
                        </div>
                        <p className="ml-1" style={{ fontSize: 14 }}>
                            I acknowledge that I have read the disclaimer and will use the app for only testing purpose until announced otherwise.
                        </p>
                    </div>
                    <div className="mt-10">
                        <button onClick={myClose} className={ "btn-warning btn-orange " + (!state.aggrement ? ' disabled' : '') }>Start using Velar</button>
                    </div>
                </div>
            </div>
        </div>
        , document.body)
}