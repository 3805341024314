import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthService from '../../services/AuthService';
import { getRandomColor } from '../../helper';
import { cloneDeep } from 'lodash';
import StorageService from '../../services/StorageService';
import { AppConstants } from '../../constants';

const appPersistance = StorageService.getItem(AppConstants.LOCAL_STORAGE_KEYS.App);



export const appInitialState = {
    appFocus: true,
    listView: appPersistance?.listView || AppConstants.Views.LIST
};

export const appSlice = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: {
        setAppFocus (state, action) {
            state.appFocus = action.payload;
        },
        setListView (state, action) {
            state.listView = action.payload;
            StorageService.setItem(AppConstants.LOCAL_STORAGE_KEYS.App, { ...state });
        }
    },
    extraReducers: (builder) => {},
})

export const { setListView, setAppFocus } = appSlice.actions
export default appSlice.reducer
