import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import ModalLoader from "../components/ModalLoader";

export const ModalState = {
    IN_PROGRESS: 'inprogress',
    DONE: 'done'
}

export const LoaderModal = (props) => {
    const [state, setState] = useState(ModalState.IN_PROGRESS);
    const { onClose } = props;

    useEffect(() => {
        setTimeout(() => {
            setState(ModalState.DONE);
            setTimeout(() => {
                onClose && onClose();
            }, 2000);
        }, 2000);
    })
 


    return createPortal(
        <div id="successfulModal" tabIndex="-1" aria-hidden="true" className="swapActionModal fixed h-screen z-[901] top-0 left-0 right-0 flex items-center w-full p-4 overflow-y-auto overflow-x-hidden">
            <div className="modal-content flex justify-center flex-col m-auto z-[901] pt-14">
                { state === ModalState.IN_PROGRESS ? (
                    <>
                        <div className="text-center mb-10">
                            <ModalLoader />
                        </div>
                        <h4 className="heading">Waiting for your <span>transaction</span></h4>
                        <p className="heading" style={{ fontSize: 18, marginTop: 10 }}>to be confirm on blockchain</p>
                    </>
                ) : (
                    <>
                        <div className="text-center mb-10">
                            <ModalLoader completed={true} />
                        </div>
                        <h4 className="heading">You swapped <span>1 Velar</span> for <span>1.08 USDC</span></h4>
                        <p className="heading" style={{ fontSize: 18, marginTop: 10 }}>to be confirm on blockchain</p>
                    </>
                ) }
            </div>
        </div>
    , document.body)
}