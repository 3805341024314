import React from 'react'
import { Link } from 'react-router-dom'
import ExchangeService from '../../services/ExchangeService';
import { commarize, getTokenBasedExcatValue } from '../../helper';

export default function GridView(props) {

    const { liquidities } = props;

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
            {liquidities.map((item, index) => {
                const stats = item.stats || {};
                const totalReceived = Number(item?.balance || 0);
                const totalSupply = Number(item?.totalSupply || 1);
                const percent = totalReceived / totalSupply;
                const reserve0 = item?.blockchain?.reserve0?.value || 0;
                const reserve1 = item?.blockchain?.reserve1?.value || 0;
                let lpTokenBalance = Number(item.balance);

                // let balance0 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve0, item.token0Symbol), item.token0Symbol);
                // let balance1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve1, item.token1Symbol), item.token1Symbol);
                // let dollarBalance = commarize(Number(balance0) + Number(balance1));
                return (
                <Link key={index} to={`/pool/${item.lpTokenSymbol}`} className="pool-grid-box">
                    <div className="conversion">
                        <div className="icons">
                            <img
                                className="z-6"
                                src={item.token0ImageUrl}
                                alt="coin two" />
                            <img
                                className="z-7"
                                src={item.token1ImageUrl}
                                alt="coin one" />
                        </div>
                        <div className="coins">{ item.name }</div>
                        <div className="fee">0.3%</div>
                    </div>
                    <div className="details">
                        <div className="item">
                            <p className="header-text">TVL</p>
                            <p className="body-text">${ commarize(stats.tvl.value, 2) }</p>
                        </div>
                        <div className="item">
                            <p className="header-text">Volume (24h)</p>
                            <p className="body-text">
                                ${ commarize(stats.volume?.value, 6)}
                            </p>
                        </div>
                        <div className="item">
                            <p className="header-text">APR</p>
                            <div className='flex items-center'>
                                {/* <img className='mr-1' src="/assets/icons/fire.svg" alt="fire" /> */}
                                {/* <p className="body-text">10.95%</p> */}
                                <p className="body-text">
                                    { commarize(stats.apr?.value, 6)}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            )})}
        </div>
    )
}
