import React from "react";
import { Link } from "react-router-dom";
import { getTokenBasedExcatValue } from "../../helper";
import ExchangeService from "../../services/ExchangeService";

export default function MyPositionGridView(props) {
  const { liquidities } = props;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
      {liquidities.map((item, index) => {
        const reserve0 = item?.blockchain?.my?.amt0 || 0;
        const reserve1 = item?.blockchain?.my?.amt1 || 0;
        
        const totalReceived = Number(item?.balance || 0);
        const totalSupply = Number(item?.totalSupply || 1);
        const percent = totalReceived / totalSupply;

        let balance0 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve0, item.token0Symbol), item.token0Symbol);
        let balance1 = ExchangeService.getTokenDollarPrice(getTokenBasedExcatValue(reserve1, item.token1Symbol), item.token1Symbol);
        let dollarBalance = Number(balance0) + Number(balance1);

        return (
          <Link
            key={index}
            to={`/pool/${item.lpTokenSymbol}?tab=ml`}
            className="pool-grid-box"
          >
            <div className="conversion">
              <div className="icons">
                <img className="z-6" src={item.token0ImageUrl} alt="coin two" />
                <img className="z-7" src={item.token1ImageUrl} alt="coin one" />
              </div>
              <div className="coins">{item.name}</div>
              <div className="fee">{percent.toFixed(6)}%</div>
            </div>
            <div className="details">
              <div className="item">
                <p className="header-text">Amount Locked</p>
                <p className="body-text">${ dollarBalance || 0}</p>
              </div>
              <div className="item">
                <p className="header-text">Pool Share</p>
                <div className="flex items-center">
                  {/* <img
                    className="mr-1"
                    src="/assets/icons/fire.svg"
                    alt="fire"
                  /> */}
                  <p className="body-text">--</p>
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
