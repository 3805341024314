import React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Search from "../components/Liquidity/Search";
import iconChevronDown from "./icon-chevron-down.svg";
import { farmHttp } from '../utils/axiosProvider';
import Loader from '../components/Loader';
import { useSelector } from 'react-redux';
import { getFirstAndLast } from '../helper';
import { Config } from '../config';

export const Points = (props) => {
	const [ search, setSearch ] = useState('');
	const [ accordian, setAccordian ] = useState(1);
	const [ loader, setLoader ] = useState(false);
	const [ loaderMyPoint, setMyPointLoader ] = useState(false);
	const [ data, setData ] = useState([]);
	const [ myPoints, setMyPoints ] = useState({ data: { total_points: '-', rank: '-' } });
	const [ meta, setMeta ] = useState({ limit: 5, skip: 0, total: 0 });
	const auth = useSelector((x) => x.auth);

	useEffect(() => {
		getPoints();
		getMyPoints();
	}, [])

	useEffect(() => {
		if (auth?.isLoggedIn) {
			getMyPoints();
		}
	}, [auth?.authData?.stxAddress?.address])

	useEffect(() => {
		getPoints(true);
	}, [meta.skip])

	useEffect(() => {
		getPoints(false);
	}, [search])

	const getPoints = async (append = false) => {
		try {
			setLoader(true);
			const response = (await farmHttp.get(`/points?address=${search}&skip=${meta.skip}`)).data;
			response.data = response.data.map((x) => {
				x.total_points = parseFloat(x.total_points).toFixed(6)
				return x;
			})
			if (append) {
				setData([...data, ...response.data ]);
			} else {
				setData([...response.data ]);
			}
			setMeta(response.meta);
			setLoader(false);
		} catch (error) {
			setLoader(false);
		}
	}

	const getMyPoints = async () => {
		try {
			setMyPointLoader(true);
			const response = (await farmHttp.get(`/points/${auth.authData.stxAddress.address}`)).data;
			console.log(response);
			if (response?.data?.total_points) {
				response.data.total_points = parseFloat(response?.data.total_points).toFixed(0)
			} else {
				response.data.total_points = '-';
				//; .toFixed(0)
			}
			setMyPoints(response);
			setMyPointLoader(false);
		} catch (error) {
			setMyPointLoader(false);
		}
	}

	return (
		<div className="points-main max-w-950">
			<div className="page-items">
				<div className="item">
					<h1 className="page-title">Velar Points</h1>
					<p className="page-desc">
						Users earn points on Farcaster by receiving tips from others. You get a daily
						tip allowance, visible on this page after connecting your wallet.
					</p>
				</div>
				<hr />
				{ auth.isLoggedIn && (
					<React.Fragment>
						<div className="item points-stats-card">
							<div className="stats-item">
								<h4 className="title">Your stats</h4>
								<h6 className="desc">Your ranking and total points earned</h6>
							</div>
							<div className="stats-item">
								<h6 className="key">Rank</h6>
								<h4 className="value">
									{ loaderMyPoint ? (
										<div className='text-left flex items-start min-w-[70px]'>
											<Loader />
										</div>
									) : (
										<span>{ myPoints?.data?.rank == '-' ? '' : '#' } { myPoints?.data?.rank }</span>
									) }
								</h4>
							</div>
							<div className="stats-item">
								<h6 className="key">Wallet Address</h6>
								<h4 className="value">
									{ loaderMyPoint ? (
										<div className='text-left flex items-start min-w-[120px]'>
											<Loader />
										</div>
									) : (
										<span>{ getFirstAndLast(auth.authData.stxAddress.address, 4) } </span>
									) }
								</h4>
							</div>
							<div className="stats-item">
								<h6 className="key">Total Points</h6>
								<h4 className="value">
									{ loaderMyPoint ? (
										<div className='text-left flex items-start min-w-[140px]'>
											<Loader />
										</div>
									) : (
										<span>{ myPoints?.data?.total_points } </span>
									) }
								</h4>
							</div>
						</div>
						<hr />
					</React.Fragment>					
				) }
				<div className="item points-leaderboard-card">
					<div className="leaderboard-header">
						<h5 className="heading">Point Leaderboard</h5>
						<div className='flex items-center gap-5'>
							{loader && (
								<Loader />
							)}
							<Search 
								inputStyle={{ minWidth: search.length > 30 ? '468px' : '200px' }}
								placeholder={'Search address'}
								onTextChange={ (text) => setSearch(text) } asSearchBox={true} data={[]} />
						</div>
					</div>
					<div className="leaderboard-body table-responsive">
						<table>
							<thead>
								<tr>
									<th>Rank</th>
									<th>User address</th>
									<th>Total Points</th>
								</tr>
							</thead>
							<tbody>
								{data.map((row, index) => {
									return (
										<tr>
											<td># {row.rank}</td>
											<td>
												<a className="address" target='_blank' href={`https://explorer.hiro.so/address/${row?.address}?chain=${Config.name}`}>
													<span className="text">{row.address}</span>
													{/* <span className="badge">0x3F...120D</span> */}
												</a>
											</td>
											<td>{Number(row.total_points).toFixed(0)}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					{(data.length < meta.total) && (
						<button onClick={(e) => setMeta({ ...meta, skip: 20 })} className="btn-load-more">
							{loader ? (
								<Loader />
							) : (
								<span>Load more</span>
							)}
						</button>
					)}
				</div>
				<hr />
				<div className="item">
					<h5 className="points-works-title">How Points work?</h5>
					<div class="points-accordion">
						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 1 ? 0 : 1)} class={(accordian == 1 ? 'collapsed ' : '') + "accordion-button"}>
								<span>What Are Velar Points</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 1 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										By actively participating in liquidity pools and staking your LP tokens, you unlock the potential to earn Velar Points daily. It's our way of thanking you for contributing to the platform's growth and stability.
										Connect Your Wallet, Watch Your Points Grow
										Easily track your Velar Points by connecting your wallet to our platform. With daily updates at the end of every 24-hour cycle, you're always informed about your accumulated rewards. Remember, you are rewarded when you stake your LP tokens. Please note, that updates are based on UTC, ensuring a consistent global standard for all users.
									</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 2 ? 0 : 2)} class={(accordian == 2 ? 'collapsed ' : '') + "accordion-button"}>
								<span>How It Works</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 2 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">Earn Daily: Each pool has a fixed point allocation per day. Whether it's STX-AEUSDC or STX-ABTC, your active participation is rewarded.
										Stake and Accumulate: Add liquidity, stake your LP tokens, and let us do the rest. We calculate the USD value of your staked LP tokens at the end of each day (UTC) and allocate points directly to your account.
										Total Points: Your daily rewards are a sum of the points earned across all pools you're involved in. The more you stake, the more you earn.</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 3 ? 0 : 3)} class={(accordian == 3 ? 'collapsed ' : '') + "accordion-button"}>
								<span>How It's Calculated</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 3 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										Each day, at the end of every 24-hour cycle (UTC), we evaluate the USD value of your staked LP tokens.
										We then calculate your points based on the formula: Total Points for Pool (e.g., STX-AEUSDC) = LP Token USD Value × Points Per Day for that Pool
										Your final daily points are the sum of points across all pools you're engaged in.
									</p>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 4 ? 0 : 4)} class={(accordian == 4 ? 'collapsed ' : '') + "accordion-button"}>
								<span>Eligibility</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 4 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										Points are awarded for staked LP tokens only. Simply providing liquidity without staking does not qualify for point accumulation.
									</p>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 5 ? 0 : 5)} class={(accordian == 5 ? 'collapsed ' : '') + "accordion-button"}>
								<span>Why Participate?</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 5 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										Velar Points are a testament to your active role in our ecosystem. By staking LP tokens, you're not just earning rewards but also contributing to the liquidity and efficiency of the DeFi market on Velar. It's a win-win for everyone involved.
									</p>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 6 ? 0 : 6)} class={(accordian == 6 ? 'collapsed ' : '') + "accordion-button"}>
								<span>Start Earning Today</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 6 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										Connect your wallet, stake your LP tokens, and start accumulating Velar Points today. Your contribution to Velar's DeFi ecosystem is valuable, and we're excited to reward you for it.
									</p>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<button onClick={() => setAccordian(accordian == 7 ? 0 : 7)} class={(accordian == 7 ? 'collapsed ' : '') + "accordion-button"}>
								<span>Key Considerations</span>
								<img className="w-100" src={iconChevronDown} alt="" />
							</button>
							<div class={(accordian == 7 ? 'show ' : '') + "accordion-collapse"}>
								<div class="accordion-body">
									<p className="text">
										All pools have predetermined points per day, promoting equitable reward distribution.
										Users' final points for the day are a comprehensive tally from all pools they've participated in.
										The system is designed to reward active participation and staking in the Velar ecosystem.
									</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}