import { AppConstants } from "./constants";

export const ROOT_URL = '/';
const env = process.env.REACT_APP_NETWORK;
const CONTRACT_BASE_ADDRESS = process.env.REACT_APP_CONTRACT_BASE_ADDRESS;
const VELAR_WALLET_ADDRESS = process.env.REACT_APP_VELAR_WALLET_ADDRESS;
const SWAP_API_URL = process.env.REACT_APP_SWAP_API_URL;
const POOL_API_URL = process.env.REACT_APP_POOL_API_URL;
const STAKE_API_URL = process.env.REACT_APP_STAKE_API_URL;
const FAUCET_API_URL = process.env.REACT_APP_FAUCET_API_URL;
const FARM_API_URL = process.env.REACT_APP_FARM_API_URL;
const BLOCKCHAIN_API_URL = process.env.REACT_APP_BLOCKCHAIN_API_URL;
const BLOCKCHAIN_EXPLORER = process.env.REACT_APP_BLOCKCHAIN_EXPLORER;
const BLOCKWATHCER_API_URL = process.env.REACT_APP_BLOCKWATHCER_API_URL;
const APP_TX_VALUES = process.env.REACT_APP_TX_VALUES;
const APP_USE_PROXY = process.env.REACT_APP_USE_PROXY;
const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;


const ContractAddressNames = {
    testnet: {
        'staking-distributor': '.staking-distributor',
        'router': '.univ2-router',
        'library': '.univ2-library',
        'core': '.univ2-core' //'.core',
    },
    mainnet: {
        'staking-distributor': '.univ2-share-fee-to',
        'router': '.univ2-router',
        'library': '.univ2-library',
        'core': '.univ2-core'

        // 'staking-distributor': '.univ2-fee-to',
        // 'router': '.univ2-router',
        // 'library': '.univ2-library',
        // 'core': '.univ2-core'
    }
}
export const Names = ContractAddressNames[env];

export const Config = {
    velarAddress: VELAR_WALLET_ADDRESS,
    authApi: AUTH_API_URL, //'https://test-swap-app.velar.co',
    swapApi: SWAP_API_URL, //'https://test-swap-app.velar.co',
    poolApi: POOL_API_URL, //'https://test-pool-app.velar.co',
    stackApi: STAKE_API_URL, //'https://test-staking-app.velar.co',
    faucetApi: FAUCET_API_URL, //'https://test-faucet-app.velar.co',
    farmApi: FARM_API_URL, //'https://test-faucet-app.velar.co',
    blockWatcher: BLOCKWATHCER_API_URL, //'https://test-faucet-app.velar.co', //'https://mainnet-proxy-service-dc11e5ac360c.herokuapp.com/watcher', //
    ContractAddresses: {
        Staking: CONTRACT_BASE_ADDRESS + '.staking-core',
        FarmStaking: CONTRACT_BASE_ADDRESS + '.wstx-velar-farming-core',
        Library: CONTRACT_BASE_ADDRESS + Names['library'],
        Core: CONTRACT_BASE_ADDRESS + Names['core'],
        Distributor: CONTRACT_BASE_ADDRESS + Names['staking-distributor'], //'.staking-distributor',
        FarmDistributor: CONTRACT_BASE_ADDRESS + '.wstx-velar-farming-distributor-v2',
        StakingDistributor: CONTRACT_BASE_ADDRESS + Names['staking-distributor'],
        Router: CONTRACT_BASE_ADDRESS + Names['router'],
        Create: CONTRACT_BASE_ADDRESS + '.create',
        Velar: CONTRACT_BASE_ADDRESS + '.velar',
        WSTX: CONTRACT_BASE_ADDRESS + '.wstx',
        Pool: {
            WSTX_VELAR: CONTRACT_BASE_ADDRESS + '.wstx-velar'
        }
    },
    txValues: Boolean(APP_TX_VALUES),
    useProxy: Boolean(APP_USE_PROXY),
    apiURL: BLOCKCHAIN_API_URL, //'https://distinguished-lively-dream.stacks-testnet.discover.quiknode.pro', //
    name: env,
    url: BLOCKCHAIN_EXPLORER
}; 

export const BASE_ADDRESS = CONTRACT_BASE_ADDRESS;