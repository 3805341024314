import { Config } from '../config';
import { getContractAddressAndName, getPureAddress } from '../helper';
import http from '../utils/axiosProvider';
import { uintCV, contractPrincipalCV, tupleCV, cvToHex, cvToJSON, hexToCV, principalCV, listCV, PostConditionMode } from '@stacks/transactions';
import { ContractService } from './ContractService';
import { store } from '../store';

class DistributorContractService {
    constructor() {
        const addressInfo = getContractAddressAndName(Config.ContractAddresses.Distributor);
        const contractService = new ContractService(addressInfo.address, addressInfo.name);
        this.contractService = contractService;
    }

    async getGenesisBlock() {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const address = auth.isLoggedIn ? auth.authData.stxAddress.address : Config.velarAddress
        const result = await this.contractService.callReadOnly('get-genesis-block', {
            sender: address,
            "arguments": []
        });
        return Number(hexToCV(result.data.result).value);
    }

    async getReward(startEpoch) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        if (auth.isLoggedIn) {
            const account = auth.authData.stxAddress.address;
            const result = await this.contractService.callReadOnly('get-reward', {
                sender: account,
                "arguments": [
                    cvToHex(principalCV(account)),
                    cvToHex(uintCV(1)),
                    cvToHex(uintCV(startEpoch)),
                ]
            });
            return hexToCV(result.data.result);
        }

        return false;
    }

    async getRewards(epoch) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        if (auth.isLoggedIn) {
            const account = auth.authData.stxAddress.address;
            const result = await this.contractService.callReadOnly('get-rewards', {
                sender: account,
                "arguments": [
                    cvToHex(principalCV(account)),
                    cvToHex(uintCV(1)),
                    cvToHex(uintCV(epoch)),
                ]
            });
            return hexToCV(result.data.result);
        }

        return false;
    }

    async distributeBlocks(blocks = []) {
        const store = window.velarStore;
        const auth = store.getState().auth;
        const token0 = getContractAddressAndName(Config.ContractAddresses.WSTX);
        const token1 = getContractAddressAndName(Config.ContractAddresses.Velar);
        const transactionPayload = {};
        if (auth.isLoggedIn) {
            const cvBlocks = blocks.map((x) => uintCV(x));
            const account = auth.authData.stxAddress.address;
            const result = await this.contractService.callPublicFunction(
                "distribute-epochs",
                [
                  principalCV(account),
                  uintCV(1),
                  contractPrincipalCV(token0.address, token0.name),
                  contractPrincipalCV(token1.address, token1.name),
                  listCV(cvBlocks)
                ],
                [], //postconditions,
                transactionPayload,
                PostConditionMode.Allow
            );

            return result;
        }
    }
}

export default (new DistributorContractService());