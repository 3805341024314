import * as Chart from 'chart.js';

export default class LineWithLine extends Chart.LineController {
    draw (ease) {
      Chart.controllers.LineController.prototype.draw.call(this, ease);
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        let activePoint = this.chart.tooltip._active[0],
          ctx = this.chart.ctx,
          x = activePoint.element.x,
          topY = this.chart.legend?.bottom || 0,
          bottomY = this.chart.chartArea?.bottom || 0;

        ctx.save();
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#fff';
  
        // draw vertical line      
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.stroke();
  
        // Draw horizontal line
        // ctx.beginPath();
        // ctx.moveTo(left, y);
        // ctx.lineTo(right, y);
        // ctx.stroke();
  
        ctx.restore();
      }
    }
}
LineWithLine.id = 'linewithline';
LineWithLine.defaults = Chart.LineController.defaults;
Chart.Chart.register(LineWithLine);
