import './linewithline.chart';
import './linewithline-reawrd.chart';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FarmMyRewardGraph } from './FarmMyRewardGraph'
import { FarmTotalStakeGraph } from './FarmTotalStakeGraph'
import { useDispatch, useSelector } from 'react-redux'
import { getPoolGraph, setGraphEmpty, setGraphPoolLoader } from '../../store/slices/poolSlice'
import moment from 'moment';
import { sum } from 'lodash'
import Loader from '../Loader'
import { useOutsideAlerter, useOutsideAlerterSingle } from '../../hooks/useOutsideAlerter';
import { getMyRewardsGraph, getTotalStakeGraph, setMyRewardsGraphEmpty, setMyRewardsGraphLoader, setTotalStakeGraphEmpty, setTotalStakeGraphLoader } from '../../store/slices/farmSlice';

const CHART_TYPES = [
  { name: 'Total Staked', value: 'total-staked' },
  // { name: 'My Rewards', value: 'my-rewards' },
]

const CHART_INTERVAL = [
  // { name: '1H', value: 'hour' },
  { name: '1D', value: 'day' },
  { name: '1W', value: 'week' },
  { name: '1M', value: 'month' },
  { name: '1Y', value: 'year' },
]

export default function FarmChart({ settings }) {
  const [ type, setType ] = useState({ name: 'Total Staked', value: 'total-staked' });
  const [ interval, setInterval ] = useState({ name: '1D', value: 'day' });
  const [ data, setData ] = useState({ labels: [], records: [] });
  const [ myRewards, setMyRewards ] = useState({ labels: [], blocks: [], records: [] });

  const dispatch = useDispatch();
  const farm = useSelector((x) => x.farm);
  const dollarValues = ['tvl', 'volume', 'fees'];
  const poolID = settings?.poolInfo?.id;
  const [showVolume, setShowVolume] = useState(false);
  const [showInterval, setShowInterval] = useState(false);
  const volumeRef = useRef(null);
  const intervalRef = useRef(null);
  const graph = farm.totalStakeGraph[poolID] || { data: [] }
  const rewardsGraph = farm.myRewardsGraph[poolID] || { data: [] }


  useOutsideAlerterSingle(volumeRef, showVolume, () => {
    setShowVolume(false)
  });

  useOutsideAlerterSingle(intervalRef, showInterval, () => {
    setShowInterval(false)
  });

  useEffect(() => {
    dispatch(setTotalStakeGraphEmpty(poolID));
  }, [])

  useEffect(() => {
    if (Number(poolID) > 0 && type.value === 'total-staked') {
      dispatch(setTotalStakeGraphEmpty(poolID));
      dispatch(setTotalStakeGraphLoader(true));
      dispatch(getTotalStakeGraph({ poolId: poolID, interval: interval.value }));
    } else if (Number(poolID) > 0 && type.value === 'my-rewards') {
      dispatch(setMyRewardsGraphEmpty(poolID));
      dispatch(setMyRewardsGraphLoader(true));
      dispatch(getMyRewardsGraph({ poolId: poolID, interval: interval.value }));
    }
  }, [type.value, interval.value, poolID])

  useEffect(() => {
    const records = rewardsGraph.data.map((x) => Number(Number(x.amount).toFixed(6)));
    const labels = rewardsGraph.data.map((x) => moment(x.datetime).format('YYYY-MM-DD hh:mm'));
    const blocks = rewardsGraph.data.map((x) => x.block);

    setMyRewards({ records, labels, blocks });
  }, [rewardsGraph.timestamp])

  useEffect(() => {
    const records = graph.data.map((x) => Number(Number(x.value).toFixed(6)));
    const labels = graph.data.map((x) => moment(x.datetime).format('YYYY-MM-DD hh:mm'));
    setData({ records, labels });
  }, [graph.timestamp])

  return (
    <div className='pool-chart'>
        <div className='chart-links'>
          <div className='flex items-center gap-x-6'>
            { CHART_TYPES.map((ct) => {
              return <a href='#' onClick={(e) => { e.preventDefault(); setType(ct); }} key={ct.value} className={(type.value === ct.value ? 'active ' : ' ') + 'chart-pill' }>{ ct.name }</a>
            })}
          </div>
          <div className='flex items-center gap-x-3'>
            { CHART_INTERVAL.map((_interval, index) => {
              return <a href='#' key={index} onClick={(e) => { e.preventDefault(); setInterval(_interval) }} className={(interval.value === _interval.value ? 'active ' : ' ') + 'chart-pill' }>{ _interval.name }</a>
            }) }
          </div>
        </div>

        <div className='sm-chart-info'>
          <p className='heading'>Chart info</p>
          <div className='flex items-center gap-x-3'>

            <div ref={volumeRef} className="action-dd">
              <button onClick={() => { setShowVolume(!showVolume); setShowInterval(false) }} type="button" className="btn-select" id="volume-dd" aria-expanded="true" aria-haspopup="true">
                { type.name }
                <svg className="w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </button>
              <div style={{ minWidth: 85 }} className={ !showVolume ? 'hidden ' : '' + "dd-list" } role="menu" aria-orientation="vertical" aria-labelledby="volume-dd" tabIndex="-1">
                <div role="none">
                  { CHART_TYPES.map((ct) => {
                    return <a style={{ fontSize: 13 }} href='#' onClick={(e) => { e.preventDefault(); setShowVolume(false); setType(ct); }} key={ct.value} className={(type.value === ct.value ? 'active ' : ' ') + 'chart-pill' }>{ ct.name }</a>
                  })}
                </div>
              </div>
            </div>

            <div ref={intervalRef} className="action-dd">
              <button onClick={() => { setShowInterval(!showInterval); setShowVolume(false); }}  type="button" className="btn-select" id="duration-dd" aria-expanded="true" aria-haspopup="true">
                { interval.name }
                <svg className="w-4" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                </svg>
              </button>
              <div className={ !showInterval ? 'hidden ' : '' + "dd-list" } role="menu" aria-orientation="vertical" aria-labelledby="duration-dd" tabIndex="-1">
                <div role="none">
                    { CHART_INTERVAL.map((_interval, index) => {
                      return <a style={{ fontSize: 13 }} href='#' key={index} onClick={(e) => { e.preventDefault(); setShowInterval(false); setInterval(_interval) }} className={(interval.value === _interval.value ? 'active ' : ' ') + 'chart-pill' }>{ _interval.name }</a>
                    }) }
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="relative">
          {/* { sum(data.records) } */}
          {/* <p className='text-white'>A { sum(data.records) }</p> */}
          { farm.totalStakeGraphLoader && (
            <div className='absolute' style={{ marginLeft: '50%', marginTop: '15%' }}>
              <Loader width={200} />
            </div>
          ) }
          { type.value === 'my-rewards' ? (
            <FarmMyRewardGraph dollar={dollarValues.includes(type.value)} labels={myRewards.labels} blocks={myRewards.blocks} records={myRewards.records}  />
          ) : type.value === 'total-staked' ? (
            <FarmTotalStakeGraph dollar={dollarValues.includes(type.value)} labels={data.labels} records={data.records}  />
          ) : '' }
        </div>        
    </div>
  )
}
