import { Link } from "react-router-dom";
import { getContractAddressAndName, getFirstAndLast, getTokenBasedExcatValue } from "../helper";
import moment from 'moment';
import { BASE_ADDRESS, Config } from '../config'
import { uintCV, contractPrincipalCV, tupleCV, cvToHex, cvToJSON, hexToCV, principalCV, listCV, PostConditionMode, cloneDeep } from '@stacks/transactions';
import { AppConstants } from "../constants";

const mapping = AppConstants.NOTIFICATION_NAME_MAPPING;

export const isBaseAddress = (address = '') => {
    if ([BASE_ADDRESS].includes(getContractAddressAndName(address).address)) {
        return true;
    }
    return false;
}

export let renderNotifications = (transaction, i, ref, isPage, xtokens = []) => {
    let tokens = cloneDeep(xtokens).map((token) => {
        if (token.assetName === 'sbtc') {
            token.vsymbol = 'asset-3';
        }

        return token;
    })
    let isSwap = transaction?.contract_call?.function_name === 'swap-exact-tokens-for-tokens' ||  transaction?.contract_call?.function_name === 'swap-tokens-for-exact-tokens';
    let isLiquidity = transaction?.contract_call?.function_name === 'remove-liquidity' ||  transaction?.contract_call?.function_name === 'add-liquidity';
    let isStake = transaction?.contract_call?.function_name === 'stake' ||  transaction?.contract_call?.function_name === 'unstake';
    let isStakeRewards = transaction?.contract_call?.function_name === 'distribute-epochs';
    let isPending = transaction?.memepool;
    
    let onNavigate = (transaction) => {
        // ref && ref.current && ref.current.classList.add('hidden')
        window.open(`https://explorer.hiro.so/txid/${transaction.txId}?chain=${Config.name}`, '_blank');
    }

    if (!isBaseAddress(transaction?.contract_call?.contract_id)) {
        // return renderTransactionTemplate(transaction, i, isPending, ref, isPage, onNavigate);
        return '';
    }

    if (isSwap) {
        return renderSwapTransaction(transaction, i, isPending, ref, isPage, onNavigate, tokens);
    } else if (isLiquidity) {
        return renderLiquidityPollTransaction(transaction, i, isPending, ref, isPage, onNavigate, tokens);
    } else if (transaction?.contract_call?.contract_id?.indexOf('farming')) {
        return renderFarmStakeTransaction(transaction, i, isPending, ref, isPage, onNavigate);
    } else if (Config.ContractAddresses.FarmDistributor === transaction?.contract_call?.contract_id) {
        return renderFarmRewardTransaction(transaction, i, isPending, ref, isPage, onNavigate);
    } else {
        if (Config.name === AppConstants.Networks.TESTNET) {
            if (Config.ContractAddresses.Staking === transaction?.contract_call?.contract_id) {
                return renderStakeTransaction(transaction, i, isPending, ref, isPage, onNavigate);
            }  else if (Config.ContractAddresses.StakingDistributor === transaction?.contract_call?.contract_id) {
                return renderStakeRewardTransaction(transaction, i, isPending, ref, isPage, onNavigate);
            }  else {
                return '';
            }
        }
    }
}

export let renderStakeRewardTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let message = '';
        try {
            const listBlocks = hexToCV(transaction?.contract_call.function_args.at(-1).hex);
            const firstEpoch = listBlocks.list[0].value;
            const lastEpoch = listBlocks.list.at(-1).value;
            const epouchs = listBlocks.list.length > 1 ? "epochs " + firstEpoch + ".." + lastEpoch : "epoch " + firstEpoch;
            message = epouchs;
        } catch (e) {
            console.log(e);
        }
        const amt = ''; //getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Reward claiming' : 'Reward claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
            icon = 'cross.svg';
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {amt} { message }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                <p className="desc">
                    { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                    {/* { transaction?.contract_call?.function_name } */}
                    {/* <p className='text-lwr'>{ title } {token0Value} amount from { token0.name } {'->'} { token1.name } pool at { time }</p> */}
                </p>
            </div>
        </Link>
        );
    }
}

export let renderFarmRewardTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let message = '';
        // try {
        //     const listBlocks = hexToCV(transaction?.contract_call.function_args.at(-1).hex);
        //     const firstEpoch = listBlocks.list[0].value;
        //     const lastEpoch = listBlocks.list.at(-1).value;
        //     const epouchs = listBlocks.list.length > 1 ? "epochs " + firstEpoch + ".." + lastEpoch : "epoch " + firstEpoch;
        //     message = epouchs;
        // } catch (e) {
        //     console.log(e);
        // }
        const amt = ''; //getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Reward claiming' : 'Reward claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        if (isPending) {
            message = 'Claiming wSTX-VELAR farming pool reward'
        } else {
            message = 'wSTX-VELAR farming pool reward claimed successfully';
        }
        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ message }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                <p className="desc mt-1">
                    { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                    {/* { transaction?.contract_call?.function_name } */}
                    {/* <p className='text-lwr'>{ title } {token0Value} amount from { token0.name } {'->'} { token1.name } pool at { time }</p> */}
                </p>
            </div>
        </Link>
        );
    }
}

export let renderSwapTransaction = (transaction, index, isPending, ref, isPage, onNavigate, tokens = []) => {
    if (transaction?.contract_call?.function_name === 'swap-exact-tokens-for-tokens') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        
        let token0 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[4].repr);

        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);

        
        let token0ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        
        if (token_at_index_0.name === token1.name) {
            [token0ValueComparasion, token1ValueComparasion] = [token1ValueComparasion, token0ValueComparasion]
        }
        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;

        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Swapping' : 'Swapped'; 
        let endText = isPending ? '' : 'successfully';
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isPending && !isSuccess) {
            title = 'Failed to swap';
            endText = '';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center direction-row mr-3">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } { token0Value } { token0Name } to {token1Value} { token1Name } {endText}</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0ValueComparasion, token1ValueComparasion, true, tokens) }
            </div>
        </Link>
        );
    } else if (transaction?.contract_call?.function_name === 'swap-tokens-for-exact-tokens') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[4].repr);

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Swapping' : 'Swapped'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let endText = isPending ? '' : 'successfully';

        let token0ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        
        if (token_at_index_0.name === token1.name) {
            [token0ValueComparasion, token1ValueComparasion] = [token1ValueComparasion, token0ValueComparasion]
        }

        if (!isPending && !isSuccess) {
            title = 'Failed to swap';
            endText = '';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{title} { token0Value } { token0Name } to {token1Value} { token1Name } {endText}</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                   { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0ValueComparasion, token1ValueComparasion, true, tokens) }
            </div>
        </Link>
        );
    }
}

export let renderStakeTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'stake') {
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Staking' : 'Staked'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {amt} velar { endText }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                <p className="desc mt-1">
                    { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                    {/* { transaction?.contract_call?.function_name } */}
                    {/* <p className='text-lwr'>{ title } {token0Value} amount from { token0.name } {'->'} { token1.name } pool at { time }</p> */}
                </p>
            </div>
        </Link>
        );
    } else if (transaction?.contract_call?.function_name === 'unstake') {
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Unstaking' : 'Unstaked'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to add';
            endText = 'to Liquidity pool';
            icon = 'cross.svg';
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {amt} velar { endText }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    {/* <div style={{ minWidth: 80, width: 80 }} className="cusror-default title flex items-center justify-between direction-row">
                        <p className='text-lwr mt-0' style={{ fontWeight: 'normal', color: '#999', fontSize: 12, textTransform: 'capitalize' }}>{(transaction.status || '').replace(/[_]/g, ' ')}</p>
                        <a onClick={(e) => {
                            e.stopPropagation();
                        }} target="_blank" className="ml-3" href={`https://explorer.hiro.so/txid/${transaction.txId}?chain=testnet`}><img style={{ width: 15 }} src="/assets/icons/icon-cube.svg" alt="" /></a>
                    </div> */}
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                {/* { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value) } */}
                {/* <p className="desc mt-1">
                    <p className='text-lwr'>{title} { token0Value } { token0.name } and {token1Value} { token1.name } in { token2.name } liquidity pool at { time }</p>
                </p> */}
            </div>
        </Link>
        );
    }
}

export let renderFarmStakeTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'stake') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(1, 3).map((x) => {
            x = mapping[x] || x;
            return x;
        }).join('-');
        // let symbol = 'wstx-velar';
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Staking' : 'Staked'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            icon = 'cross.svg'
        }
        endText = 'farming pool';

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {amt} LP tokens in { symbol } { endText }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                <p className="desc mt-1">
                    { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                    {/* { transaction?.contract_call?.function_name } */}
                    {/* <p className='text-lwr'>{ title } {token0Value} amount from { token0.name } {'->'} { token1.name } pool at { time }</p> */}
                </p>
            </div>
        </Link>
        );
            // 
    } else if (transaction?.contract_call?.function_name === 'unstake') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(1, 3).map((x) => {
            x = mapping[x] || x;
            return x;
        }).join('-');
        // let symbol = 'wstx-velar';
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Unstaking' : 'Unstaked'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to unstake';
            icon = 'cross.svg'
        }
        endText = 'farming pool';

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {amt} LP tokens in { symbol } { endText }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    {/* <div style={{ minWidth: 80, width: 80 }} className="cusror-default title flex items-center justify-between direction-row">
                        <p className='text-lwr mt-0' style={{ fontWeight: 'normal', color: '#999', fontSize: 12, textTransform: 'capitalize' }}>{(transaction.status || '').replace(/[_]/g, ' ')}</p>
                        <a onClick={(e) => {
                            e.stopPropagation();
                        }} target="_blank" className="ml-3" href={`https://explorer.hiro.so/txid/${transaction.txId}?chain=testnet`}><img style={{ width: 15 }} src="/assets/icons/icon-cube.svg" alt="" /></a>
                    </div> */}
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                {/* { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value) } */}
                {/* <p className="desc mt-1">
                    <p className='text-lwr'>{title} { token0Value } { token0.name } and {token1Value} { token1.name } in { token2.name } liquidity pool at { time }</p>
                </p> */}
            </div>
        </Link>
        );
    } else if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(0, 2).map((x) => {
            x = mapping[x] || x;
            return x;
        }).join('-');

        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'check-circle.svg'
        let title = isPending ? 'Claiming' : 'Claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to claim';
            icon = 'cross.svg'
        }
        endText = 'farming pool';

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } rewards in { symbol } { endText }</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    {/* <div style={{ minWidth: 80, width: 80 }} className="cusror-default title flex items-center justify-between direction-row">
                        <p className='text-lwr mt-0' style={{ fontWeight: 'normal', color: '#999', fontSize: 12, textTransform: 'capitalize' }}>{(transaction.status || '').replace(/[_]/g, ' ')}</p>
                        <a onClick={(e) => {
                            e.stopPropagation();
                        }} target="_blank" className="ml-3" href={`https://explorer.hiro.so/txid/${transaction.txId}?chain=testnet`}><img style={{ width: 15 }} src="/assets/icons/icon-cube.svg" alt="" /></a>
                    </div> */}
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
                {/* { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value) } */}
                {/* <p className="desc mt-1">
                    <p className='text-lwr'>{title} { token0Value } { token0.name } and {token1Value} { token1.name } in { token2.name } liquidity pool at { time }</p>
                </p> */}
            </div>
        </Link>
        );
    }
}

export let renderLiquidityPollTransaction = (transaction, index, isPending, ref, isPage, onNavigate, tokens = []) => {
    if (transaction?.contract_call?.function_name === 'remove-liquidity') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[5].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'remove-notification.svg'
        let title = isPending ? 'Removing' : 'Removed'; 
        let endText = isPending ? 'from Liquidity pool' : 'from Liquidity pool successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        if (!isSuccess && !isPending) {
            title = 'Failed to remove';
            endText = 'from Liquidity pool';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{ title } {token0Value} { token0Name } and {token1Value} { token1Name } {endText}</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                <p className="desc mt-1">
                    { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value, true, tokens) }
                    {/* { transaction?.contract_call?.function_name } */}
                    {/* <p className='text-lwr'>{ title } {token0Value} amount from { token0.name } {'->'} { token1.name } pool at { time }</p> */}
                </p>
            </div>
        </Link>
        );
    } else if (transaction?.contract_call?.function_name === 'add-liquidity') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        let token2 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-v2.svg' : 'add-liq-notification.svg'
        let title = isPending ? 'Adding' : 'Added'; 
        let endText = isPending ? 'to Liquidity pool' : 'to Liquidity pool successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        if (!isSuccess && !isPending) {
            title = 'Failed to add';
            endText = 'to Liquidity pool';
            icon = 'cross.svg'
        }

        return (
            <Link to={isPage ? "/notifications" : "#"} key={index} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6 className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{title} { token0Value } { token0Name } and {token1Value} { token1Name } {endText}</p>
                        </div>
                        <div className="na ml-2">
                            <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                            </svg>
                        </div>
                    </div>
                    {/* <div style={{ minWidth: 80, width: 80 }} className="cusror-default title flex items-center justify-between direction-row">
                        <p className='text-lwr mt-0' style={{ fontWeight: 'normal', color: '#999', fontSize: 12, textTransform: 'capitalize' }}>{(transaction.status || '').replace(/[_]/g, ' ')}</p>
                        <a onClick={(e) => {
                            e.stopPropagation();
                        }} target="_blank" className="ml-3" href={`https://explorer.hiro.so/txid/${transaction.txId}?chain=testnet`}><img style={{ width: 15 }} src="/assets/icons/icon-cube.svg" alt="" /></a>
                    </div> */}
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value, true, tokens) }
                {/* <p className="desc mt-1">
                    <p className='text-lwr'>{title} { token0Value } { token0.name } and {token1Value} { token1.name } in { token2.name } liquidity pool at { time }</p>
                </p> */}
            </div>
        </Link>
        );
    }
}

export let renderTransactionTemplate = (transaction, i, isPending, ref, isPage, onNavigate) => {
    let icon = isPending ? 'loader-v2.svg' : 'envelope.svg'
    let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
    let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

    return (
        <Link to={isPage ? "/notifications" : "#"} key={i} className="item">
            <div className="pt-1 mr-3">
                <img className={isPending ? 'spin w-5' : "w-5"} src={"/assets/icons/" + icon} alt="envelope" />
            </div>
            <div className="w-full">
                <h6  className="cusror-default title flex items-center justify-between direction-row">
                    <div className="nt title hand flex items-center justify-between direction-row">
                        <div onClick={() => { onNavigate(transaction) }}>
                            <p  className='text-upr mb-0'>{getFirstAndLast(transaction.txId)}</p>
                            <div className="na ml-2">
                                <svg width="9" height="9" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.56248 1V5.875C7.56248 6.02418 7.50322 6.16726 7.39773 6.27275C7.29224 6.37824 7.14916 6.4375 6.99998 6.4375C6.8508 6.4375 6.70772 6.37824 6.60223 6.27275C6.49674 6.16726 6.43748 6.02418 6.43748 5.875V2.35937L1.39795 7.39797C1.29228 7.50364 1.14895 7.56301 0.999511 7.56301C0.850068 7.56301 0.706746 7.50364 0.601073 7.39797C0.495401 7.2923 0.436035 7.14897 0.436035 6.99953C0.436035 6.85009 0.495401 6.70677 0.601073 6.60109L5.6406 1.5625H2.12498C1.9758 1.5625 1.83272 1.50324 1.72723 1.39775C1.62174 1.29226 1.56248 1.14918 1.56248 1C1.56248 0.850816 1.62174 0.707742 1.72723 0.602252C1.83272 0.496763 1.9758 0.4375 2.12498 0.4375H6.99998C7.14916 0.4375 7.29224 0.496763 7.39773 0.602252C7.50322 0.707742 7.56248 0.850816 7.56248 1Z" fill="#717179"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    { renderNotificationBadge(transaction, isPending) }
                </h6>
                { renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) }
            </div>
        </Link>
    )
}

let renderNotificaitonDescription = (transaction, formatedTime, isPage, token0, token1, token0Val = 0, token1Val = 0, showPerValue = true, tokens = []) => {
    let val = (Number(token0Val) / Number(token1Val)).toFixed(6);

    const token0Name = tokens.find((x) => x.symbol === token1.name || x.vsymbol === token1.name)?.symbol || token1.name;
    const token1Name = tokens.find((x) => x.symbol === token0.name || x.vsymbol === token0.name)?.symbol || token0.name;

    if (!isPage) { 
       return (
             <div style={{ cursor: 'default', color: '#717179', fontSize: '0.85rem' }} className={"desc flex-col flex items-start space-between" }>
                { showPerValue && (
                    <span>{val} { token0Name } per { token1Name } at { formatedTime }</span>
                ) }
            </div>    
       )
    }

    return (
        <div style={{ cursor: 'default', color: '#717179', fontSize: '0.85rem' }} className={isPage ? "mt-1 flex-col md:flex-row flex items-start md:items-center space-between" : "desc mt-1 flex-col flex items-start space-between" }>
            { showPerValue && (
                <div className="direction-row flex items-center">
                    <img src="/assets/icons/dollar-notification.svg" alt="" />
                    <span className="ml-2">{val} { token0Name } per { token1Name }</span>
                </div>
            )}
            <div className={ !showPerValue ? 'direction-row flex items-center' : isPage ? "direction-row flex md:ml-2 mt-2 md:mt-0 items-center" : "direction-row flex mt-2 items-center" }>
                <img src="/assets/icons/watch.svg" alt="" />
                <span className="ml-2">{ formatedTime }</span>
            </div>
            <div className={ isPage ? "direction-row md:ml-2 flex mt-2 md:mt-0 items-center" : "direction-row flex mt-2 items-center" }>
                <img src="/assets/icons/hash.svg" alt="" />
                <span className="ml-2">{ getFirstAndLast(transaction.txId) }</span>
            </div>
        </div>
    )
}

let renderNotificationBadge = (transaction, isPending) => {
    return '';
    let isSuccess = transaction?.status?.indexOf('success') > -1;
    return  (
        <div style={{ minWidth: 60, width: 60 }} className="cusror-default title flex items-center justify-between direction-row">
            { isPending ? (
                <p className="notification-badge processing ml-2">Processing</p>
            ) : isSuccess ? (
                <p className="notification-badge ml-2">Completed</p>
            ) : (
                <p className="notification-badge ml-2 failed">Failed</p>
            )}
            {/* <p className='text-lwr mt-0' style={{ fontWeight: 'normal', color: '#999', fontSize: 12, textTransform: 'capitalize' }}>{(transaction.status || '').replace(/[_]/g, ' ')}</p>
            <a onClick={(e) => {
                e.stopPropagation();
            }} target="_blank" className="ml-3" href={`https://explorer.hiro.so/txid/${transaction.txId}?chain=testnet`}><img style={{ width: 15 }} src="/assets/icons/icon-cube.svg" alt="" /></a> */}
        </div>
    )
}
