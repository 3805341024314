import { isMobile } from "@stacks/connect";
import { Config } from "../config";
import http, { stakeHttp } from "../utils/axiosProvider";

export class StackService {
  async saveTransaction (data) {
    const payload = {
      "publicAddress": data.publicAddress,
      "tokenSymbol": data.tokenSymbol,
      "tokenContractAddress": data.tokenContractAddress,
      "tokenQuantity": String(data.tokenQuantity),
      "status": "success",
      "transactionHash": data.transactionHash,
      "txResponse": data.txResponse,
      "walletInUse": data.walletInUse || 'hiro',
      "txType": data.txType,
      "deviceType": isMobile() ? 'mobile' : 'web',
    };
    await stakeHttp.post('/stake/transactions', payload);
  }
}

export default new StackService();
