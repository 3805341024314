import { Link } from "react-router-dom";
import { getContractAddressAndName, getFirstAndLast, getTokenBasedExcatValue } from "../helper";
import moment from 'moment';
import { BASE_ADDRESS, Config } from '../config'
import { uintCV, contractPrincipalCV, tupleCV, cvToHex, cvToJSON, hexToCV, principalCV, listCV, PostConditionMode, cloneDeep } from '@stacks/transactions';
import { AppConstants } from "../constants";

const mapping = AppConstants.NOTIFICATION_NAME_MAPPING;

export let onTxNavigate = (transaction) => {
    window.open(`https://explorer.hiro.so/txid/${transaction.txId}?chain=${Config.name}`, '_blank');
}

export const isBaseAddress = (address = '') => {
    if ([BASE_ADDRESS].includes(getContractAddressAndName(address).address)) {
        return true;
    }
    return false;
}

export let renderNotifications = (transaction, i, ref, isPage, xtokens = []) => {
    let tokens = cloneDeep(xtokens).map((token) => {
        if (token.assetName === 'sbtc') {
            token.vsymbol = 'asset-3';
        }

        return token;
    })
    let isSwap = transaction?.contract_call?.function_name === 'swap-exact-tokens-for-tokens' ||  transaction?.contract_call?.function_name === 'swap-tokens-for-exact-tokens';
    let isLiquidity = transaction?.contract_call?.function_name === 'remove-liquidity' ||  transaction?.contract_call?.function_name === 'add-liquidity';
    let isStake = transaction?.contract_call?.function_name === 'stake' ||  transaction?.contract_call?.function_name === 'unstake';
    let isStakeRewards = transaction?.contract_call?.function_name === 'distribute-epochs';
    let isPending = transaction?.memepool;
    
    let onNavigate = (transaction) => {
        // ref && ref.current && ref.current.classList.add('hidden')
        window.open(`https://explorer.hiro.so/txid/${transaction.txId}?chain=${Config.name}`, '_blank');
    }

    if (!isBaseAddress(transaction?.contract_call?.contract_id)) {
        // return renderTransactionTemplate(transaction, i, isPending, ref, isPage, onNavigate);
        return '';
    }

    if (isSwap) {
        return renderSwapTransaction(transaction, i, isPending, ref, isPage, onNavigate, tokens);
    } else if (isLiquidity) {
        return renderLiquidityPollTransaction(transaction, i, isPending, ref, isPage, onNavigate, tokens);
    } else if (transaction?.contract_call?.contract_id?.indexOf('farming')) {
        return renderFarmStakeTransaction(transaction, i, isPending, ref, isPage, onNavigate);
    } else if (Config.ContractAddresses.FarmDistributor === transaction?.contract_call?.contract_id) {
        return renderFarmRewardTransaction(transaction, i, isPending, ref, isPage, onNavigate);
    } else {
        if (Config.name === AppConstants.Networks.TESTNET) {
            if (Config.ContractAddresses.Staking === transaction?.contract_call?.contract_id) {
                return renderStakeTransaction(transaction, i, isPending, ref, isPage, onNavigate);
            }  else if (Config.ContractAddresses.StakingDistributor === transaction?.contract_call?.contract_id) {
                return renderStakeRewardTransaction(transaction, i, isPending, ref, isPage, onNavigate);
            }  else {
                return '';
            }
        }
    }
}

export let renderStakeRewardTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let message = '';
        try {
            const listBlocks = hexToCV(transaction?.contract_call.function_args.at(-1).hex);
            const firstEpoch = listBlocks.list[0].value;
            const lastEpoch = listBlocks.list.at(-1).value;
            const epouchs = listBlocks.list.length > 1 ? "epochs " + firstEpoch + ".." + lastEpoch : "epoch " + firstEpoch;
            message = epouchs;
        } catch (e) {
            console.log(e);
        }
        const amt = ''; //getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Reward claiming' : 'Reward claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
        }

        return {
            txId: transaction.txId,
            name: `${ title } ${amt} ${ message }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false)
        }
    }
}

export let renderFarmRewardTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let message = '';
        const amt = ''; //getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Reward claiming' : 'Reward claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        if (isPending) {
            message = 'Claiming wSTX-VELAR farming pool reward'
        } else {
            message = 'wSTX-VELAR farming pool reward claimed successfully';
        }
        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
        }

        return {
            txId: transaction.txId,
            name: message,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false)
        }
    }
}

export let renderSwapTransaction = (transaction, index, isPending, ref, isPage, onNavigate, tokens = []) => {
    if (transaction?.contract_call?.function_name === 'swap-exact-tokens-for-tokens') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        
        let token0 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[4].repr);

        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);

        
        let token0ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        
        if (token_at_index_0.name === token1.name) {
            [token0ValueComparasion, token1ValueComparasion] = [token1ValueComparasion, token0ValueComparasion]
        }
        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;

        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'swap-notification.svg'
        let title = isPending ? 'Swapping' : 'Swapped'; 
        let endText = isPending ? '' : 'successfully';
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isPending && !isSuccess) {
            title = 'Failed to swap';
            endText = '';
        }

        return {
            txId: transaction.txId,
            name: `${title} ${token0Value } ${token0Name } to ${token1Value} ${token1Name } ${endText}`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0ValueComparasion, token1ValueComparasion, true, tokens)
        }

    } else if (transaction?.contract_call?.function_name === 'swap-tokens-for-exact-tokens') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[4].repr);

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'swap-notification.svg'
        let title = isPending ? 'Swapping' : 'Swapped'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let endText = isPending ? '' : 'successfully';

        let token0ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1ValueComparasion = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        
        if (token_at_index_0.name === token1.name) {
            [token0ValueComparasion, token1ValueComparasion] = [token1ValueComparasion, token0ValueComparasion]
        }

        if (!isPending && !isSuccess) {
            title = 'Failed to swap';
            endText = '';
        }

        return {
            txId: transaction.txId,
            name: `${title} ${ token0Value } ${ token0Name } to ${token1Value} ${ token1Name } ${endText}`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0ValueComparasion, token1ValueComparasion, true, tokens)
        }
    }
}

export let renderStakeTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'stake') {
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Staking' : 'Staked'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
            endText = '';
        }

        return {
            txId: transaction.txId,
            name: `${ title } ${amt} velar ${ endText }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false)
        }
    } else if (transaction?.contract_call?.function_name === 'unstake') {
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, 'velar');
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Unstaking' : 'Unstaked'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to add';
            endText = 'to Liquidity pool';
        }

        return {
            txId: transaction.txId,
            name: `${ title } ${amt} velar ${ endText }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false)
        }
    }
}

export let renderFarmStakeTransaction = (transaction, index, isPending, ref, isPage, onNavigate) => {
    if (transaction?.contract_call?.function_name === 'stake') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(1, 3).map((x) => {
            x = mapping[x] || x;
            console.log(x, mapping[x]);
            return x;
        }).join('-');
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Staking' : 'Staked'; 
        let endText = isPending ? '' : 'successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        if (!isSuccess && !isPending) {
            title = 'Failed to stake';
        }
        endText = 'farming pool';

        return {
            txId: transaction.txId,
            name: `${ title } ${amt} LP tokens in ${ symbol } ${ endText }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) 
        }
    } else if (transaction?.contract_call?.function_name === 'unstake') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(1, 3).map((x) => {
            x = mapping[x] || x;
            return x;
        }).join('-');
        // let symbol = 'wstx-velar';
        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Unstaking' : 'Unstaked'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to unstake';
        }
        endText = 'farming pool';

        return {
            txId: transaction.txId,
            name: `${ title } ${amt} LP tokens in ${ symbol } ${ endText }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) 
        }

    } else if (transaction?.contract_call?.function_name === 'distribute-epochs') {
        let symbol = getContractAddressAndName(transaction.contract_call.contract_id).name.split('-').slice(0, 2).map((x) => {
            x = mapping[x] || x;
            return x;
        }).join('-');

        const amt = getTokenBasedExcatValue(transaction?.contract_call.function_args[0]?.repr, symbol);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'stake-velar.svg'
        let title = isPending ? 'Claiming' : 'Claimed'; 
        let endText = isPending ? '' : 'successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        if (!isSuccess && !isPending) {
            title = 'Failed to claim';
        }
        endText = 'farming pool';

        return {
            txId: transaction.txId,
            name: `${ title } rewards in ${ symbol } ${ endText }`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false) 
        }
    }
}

export let renderLiquidityPollTransaction = (transaction, index, isPending, ref, isPage, onNavigate, tokens = []) => {
    if (transaction?.contract_call?.function_name === 'remove-liquidity') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[5].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'remove-notification.svg'
        let title = isPending ? 'Removing' : 'Removed'; 
        let endText = isPending ? 'from Liquidity pool' : 'from Liquidity pool successfully'; 
        let isSuccess = transaction?.status?.indexOf('success') > -1;
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        if (!isSuccess && !isPending) {
            title = 'Failed to remove';
            endText = 'from Liquidity pool';
        }

        return {
            txId: transaction.txId,
            name: `${ title } ${token0Value} ${ token0Name } and ${token1Value} ${ token1Name } ${endText}`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value, true, tokens)
        }
    } else if (transaction?.contract_call?.function_name === 'add-liquidity') {
        let token_at_index_0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token_at_index_1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);

        let token0 = getContractAddressAndName(transaction.contract_call.function_args[1].repr);
        let token1 = getContractAddressAndName(transaction.contract_call.function_args[2].repr);
        let token2 = getContractAddressAndName(transaction.contract_call.function_args[3].repr);
        let token0Value = getTokenBasedExcatValue(transaction.contract_call.function_args[6].repr, token0.name);
        let token1Value = getTokenBasedExcatValue(transaction.contract_call.function_args[7].repr, token1.name);
        let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
        let icon = isPending ? 'loader-notification.svg' : 'add-liq-notification.svg'
        let title = isPending ? 'Adding' : 'Added'; 
        let endText = isPending ? 'to Liquidity pool' : 'to Liquidity pool successfully'; 
        let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')
        let isSuccess = transaction?.status?.indexOf('success') > -1;

        const token0Name = tokens.find((x) => x.vsymbol === token0.name)?.symbol || token0.name;
        const token1Name = tokens.find((x) => x.vsymbol === token1.name)?.symbol || token1.name;


        if (!isSuccess && !isPending) {
            title = 'Failed to add';
            endText = 'to Liquidity pool';
        }

        return {
            txId: transaction.txId,
            name: `${ title } ${token0Value} ${ token0Name } and ${token1Value} ${ token1Name } ${endText}`,
            pending: isPending,
            icon: "/assets/icons/" + icon,
            page: isPage,
            badge: renderNotificationBadge(transaction, isPending),
            desc: renderNotificaitonDescription(transaction, formatedTime, isPage, token_at_index_0, token_at_index_1, token0Value, token1Value, true, tokens)
        }
    }
}

export let renderTransactionTemplate = (transaction, i, isPending, ref, isPage, onNavigate) => {
    let icon = isPending ? 'loader-notification.svg' : 'envelope.svg'
    let time = isPending ? transaction.receipt_time_iso : transaction.burn_block_time_iso;
    let formatedTime = moment(time).format('YYYY-MM-DD hh:mm:ss')

    return {
        txId: transaction.txId,
        name: getFirstAndLast(transaction.txId),
        pending: isPending,
        icon: "/assets/icons/" + icon,
        page: isPage,
        badge: renderNotificationBadge(transaction, isPending),
        desc: renderNotificaitonDescription(transaction, formatedTime, isPage, { name: '' }, { name: '' }, 1, 1, false)
    }
}

let renderNotificaitonDescription = (transaction, formatedTime, isPage, token0, token1, token0Val = 0, token1Val = 0, showPerValue = true, tokens = []) => {
    if (!isPage) { return ''; }
    let val = (Number(token0Val) / Number(token1Val)).toFixed(6);

    const token0Name = tokens.find((x) => x.symbol === token1.name || x.vsymbol === token1.name)?.symbol || token1.name;
    const token1Name = tokens.find((x) => x.symbol === token0.name || x.vsymbol === token0.name)?.symbol || token0.name;
    return {
        details: showPerValue ? `${val} ${ token0Name } per ${ token1Name }` : '',
        tx: getFirstAndLast(transaction.txId),
        time: formatedTime
    }
}

let renderNotificationBadge = (transaction, isPending) => {
    let isSuccess = transaction?.status?.indexOf('success') > -1;
    const className = isPending ? 'in-progress' : isSuccess ? 'completed' : 'failed';
    const status_image = isPending ? '/assets/icons/loader.svg' : isSuccess ? '/assets/icons/check.svg' : '/assets/icons/cross.svg';
    return { className, status: isPending ? 'Processing' : isSuccess ? 'Completed' : 'Failed', status_image };
}
