import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { commarize } from '../../helper';
import Loader from '../Loader';

export default function FarmSummary(props) {
    const pool = useSelector((x) => x.pool);
    const chain = useSelector((x) => x.chain);

    const stats = pool.stats[props.poolId] || { data: {} };

    let summary = [
        // { 
        //     name: "Current Epoch",
        //     value: props.epoch.epoch, 
        //     unit: stats.data.tvl?.priceChange24h ? stats.data.tvl?.priceChange24h.toFixed(6) : '-', 
        //     showPriceChange: true,
        //     isFine: stats.data.tvl?.priceChange24h > 0,
        //     custom: () => {
        //         return <div className='epoch-indicator'></div>
        //     }
        // },
        // { 
        //     name: "Rewards share",
        //     value: '2000 Velar/day',  
        //     unit: stats.data.volume?.priceChange24h ? stats.data.volume?.priceChange24h.toFixed(5) : '-', 
        //     showPriceChange: true,
        //     isFine: stats.data.volume?.priceChange24h > 0,
        // },
        { 
            name: "Reward point per day",
            value: 100 + ' Velar/day',  
            unit: stats.data.volume?.priceChange24h ? stats.data.volume?.priceChange24h.toFixed(5) : '-', 
            showPriceChange: true,
            isFine: stats.data.volume?.priceChange24h > 0,
        },
        { 
            name: "Current Block",
            value: chain.currentBlockNumber, 
            unit: stats.data.fees?.priceChange24h ? stats.data.fees?.priceChange24h.toFixed(5) : '-', 
            showPriceChange: true,
            isFine: stats.data.fees?.priceChange24h > 0,
        },
        // { 
        //     name: "New Epoch will start after",
        //     value: props.epoch.startEnd[1],
        //     // value: props.epoch.startEnd[0] + ' - ' + props.epoch.startEnd[1],  
        //     unit: stats.data.fees?.priceChange24h ? stats.data.fees?.priceChange24h.toFixed(5) : '-', 
        //     showPriceChange: true,
        //     isFine: stats.data.fees?.priceChange24h > 0,
        // },
        { 
            name: "Total Staked",
            value: '890461657', 
            unit: stats.data.fees?.priceChange24h ? stats.data.fees?.priceChange24h.toFixed(5) : '-', 
            showPriceChange: true,
            isFine: stats.data.fees?.priceChange24h > 0,
        },
        // { 
        //     name: "Transactions (24h)",
        //     value: stats.data.transaction?.value, 
        //     unit: stats.data.transaction?.priceChange24h ? stats.data.transaction?.priceChange24h.toFixed(2) : '-', 
        //     showPriceChange: true,
        //     isFine: stats.data.transaction?.priceChange24h > 0,
        // },
        // { 
        //     name: "Price",
        //     value: Number(stats.data.price?.value).toFixed(6), 
        //     unit: stats.data.price?.priceChange24h,
        //     showPriceChange: true,
        //     isFine: stats.data.price?.priceChange24h > 0,
        // },
        // { 
        //     name: "APR",
        //     value: Number(stats.data.apr?.value).toFixed(6), 
        //     unit: stats.data.apr?.priceChange24h ? stats.data.apr?.priceChange24h.toFixed(6) : '-', 
        //     showPriceChange: true,
        //     showPercentageSign: true,
        //     isFine: stats.data.apr?.priceChange24h > 0,
        // },
    ]
    
    return (
        <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-3 mb-5">
            {summary.map((item, index) => (
                    <Link to="#" className="summary-widget">
                        {pool.statsLoader ? (
                            <Loader />
                        ) : (
                            <>
                                <p className='key'>{item.name}</p>
                                <h4 className="value">
                                    {item.value} { item.showPercentageSign ? '%' : '' }
                                    { item.custom && item.custom() }
                                </h4>
                            </>
                        )}
                    </Link>            
            ))}
        </div>
    )
}