import { createContext } from "react";
import { authInitialState } from "../store/slices/authSlice";
import { useSelector } from "react-redux";

export const ConnectContext = createContext(authInitialState);

export const ConnectProvider = (props) => {
    const { children } = props;
    const auth = useSelector((x) => x.auth);
    return (
      <ConnectContext.Provider value={{ ...auth }}>
        { children }
      </ConnectContext.Provider>
    );
};