import axios from "axios";
import { Config } from "../config";
import { setupCache } from "axios-cache-interceptor";
import { updateAuth } from "../store/slices/authSlice";
const axiosRetry = require('axios-retry');



axiosRetry(axios, {
    retries: 2,
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
        return error.response.status === 401;
    },
});
 

const getWalletState = () => {
    const wallet = localStorage.getItem("wallet-state");
    try {
      if (wallet) {
        const parsed = JSON.parse(wallet);
        return parsed;
      }
    } catch (error) {
      return false;
    }
    return false;
};

const getRefreshToken = () => {
    const wallet = localStorage.getItem("wallet-state");
    try {
      if (wallet) {
        const parsed = JSON.parse(wallet);
        return parsed.refreshToken;
      }
    } catch (error) {
      return "";
    }
    return "";
};

const getAccessToken = () => {
  const wallet = localStorage.getItem("wallet-state");
  try {
    if (wallet) {
      const parsed = JSON.parse(wallet);
      return parsed.accessToken;
    }
  } catch (error) {
    return "";
  }
  return "";
};


let instance = axios.create({ baseURL: Config.apiURL });

if (Config.useProxy === true) {
  instance = setupCache(axios.create({ baseURL: Config.apiURL }));
} else {
  instance = axios.create({ baseURL: Config.apiURL });
}

const authHttp = axios.create({ baseURL: Config.authApi });

const swapHttp = setupCache(
  axios.create({
    baseURL: Config.swapApi,
    headers: {
      token: getAccessToken(),
    },
  })
);


const authInterceptor = [
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        // const originalRequest = error.config;
        // if (!originalRequest._retry) {
        //     authHttp.post('auth/refreshtoken', { refreshToken: getRefreshToken() }).then((res) => {
        //         const { accessToken, refreshToken } = res.data;
        //         const state = getWalletState();
        //         originalRequest._retry = true;
        //         if (state) {
        //             state.accessToken = accessToken;
        //             state.refreshToken = refreshToken;
        //             localStorage.setItem('wallet-state', JSON.stringify(state));
        //             const dispatch = window.velarStore?.dispatch;
        //             console.log(state);
        //             if (dispatch) {
        //                 dispatch(updateAuth({ isLoggedIn: true, authData: state }));
        //             }
        //             swapHttp.defaults.headers.common['token'] = state.accessToken;
        //             return swapHttp(originalRequest);
        //         }
        //     }).catch((error) => {
        //         console.log(error);
        //         localStorage.clear();
        //         window.location = '/';
        //     })
        // } else {
        //   return Promise.reject(error);
        // }
        
        localStorage.clear();
        window.location = '/';
        return Promise.reject(error);
      } else {
          return Promise.reject(error);
      }
    },
];

swapHttp.interceptors.response.use(authInterceptor[0], authInterceptor[1]);

const poolHttp = setupCache(axios.create({ baseURL: Config.blockWatcher }));
const stakeHttp = setupCache(axios.create({ baseURL: Config.stackApi }));
const farmHttp = setupCache(axios.create({ baseURL: Config.farmApi }));
const faucetHttp = setupCache(axios.create({ baseURL: Config.faucetApi }));
const blockWatcher = setupCache(axios.create({ baseURL: Config.blockWatcher }));

swapHttp.defaults.cache.ttl = 5000;
poolHttp.defaults.cache.ttl = 5000;
stakeHttp.defaults.cache.ttl = 5000;
farmHttp.defaults.cache.ttl = 5000;
faucetHttp.defaults.cache.ttl = 5000;
blockWatcher.defaults.cache.ttl = 100;
if (Config.useProxy === true) {
  instance.defaults.cache.ttl = 5000;
}

poolHttp.defaults.headers.post["Content-Type"] = "application/json";
poolHttp.defaults.headers.get["Content-Type"] = "application/json";
swapHttp.defaults.headers.post["Content-Type"] = "application/json";
swapHttp.defaults.headers.get["Content-Type"] = "application/json";
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.get["Content-Type"] = "application/json";

export {
  blockWatcher,
  authHttp,
  swapHttp,
  poolHttp,
  farmHttp,
  stakeHttp,
  faucetHttp,
};
export default instance;
