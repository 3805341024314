import React, { useState, useEffect, useContext } from 'react';
import { WalletMobile } from './WalletMobile';
import { WalletDesktop } from './WalletDesktop';
import { AppConstants } from '../../constants';
import { useConnect } from '../../hooks/useConnect';
import axiosProvider from '../../utils/axiosProvider';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContext } from '../../contexts/LayoutContext';
import { getAccountTokens, setTokenLoader } from '../../store/slices/authSlice';
import { getPools, setPoolLoader } from '../../store/slices/poolSlice';
import { getTransactions, setTransactionLoader } from '../../store/slices/chainSlice';
import { getTokens } from '../../store/slices/swapSlice';

export const WalletState = {
    Token: 'token',
    Activity: 'activity',
    Pool: 'pool'
}

export const Wallet = (props) => {
    const connect = useConnect();
    const dispatch = useDispatch();
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const [state, setState] = useState({ tab: WalletState.Token })
    const isMobile = window.innerWidth < 550;
    const auth = useSelector((x) => x.auth);
    const chain = useSelector((x) => x.chain);
    const balance = connect.useBalance();
    const walletInstall = connect.useWalletExtension();

    const transactions = chain.transaction.data.result;
    const transactionLoader = chain.transaction.loader;

    useEffect(() => {
        if (auth.authData?.stxAddress && layoutState.showSideBar) {
            dispatch(setTokenLoader(true));
            dispatch(getAccountTokens());
            dispatch(getTokens());

            dispatch(setPoolLoader(true));
            dispatch(getPools());
            dispatch(setTransactionLoader(true));
            dispatch(getTransactions());
        }
    }, [layoutState.showSideBar, auth.authData?.stxAddress]);

    const onConnect = async (walletId = AppConstants.Wallets.XVerse) => {
        try {
            connect.login(walletId, {});
        } catch (error) {
            alert(JSON.stringify(error));
            // if (error) {
            //     if (walletId === AppConstants.Wallets.XVerse) {
            //         window.open('https://www.xverse.app', '_blank')
            //     } else if (walletId === AppConstants.Wallets.Hiro) {
            //         window.open('https://wallet.hiro.so', '_blank')
            //     }
            // }
        }
    }

    const logout = async () => {
        connect.logout();
    }

    return (
        <React.Fragment>
            {isMobile ? (
                <WalletMobile  {...props} onConnect={onConnect} logout={logout} state={{ ...state, isMobile, transactionLoader, ...auth, balance, isLoggedIn: connect.isLoggedIn, walletInstall: walletInstall, transactions: transactions }} setState={setState} />
            ) : (
                <WalletDesktop  {...props} onConnect={onConnect} logout={logout} state={{ ...state, isMobile, transactionLoader, ...auth, balance, isLoggedIn: connect.isLoggedIn, walletInstall: walletInstall, transactions: transactions }} setState={setState} />
            )}
        </React.Fragment>
    )
}
